import { isEmpty } from 'lodash';
import { Dispatch } from 'redux';
import {
  accessCall,
  getCombo,
  getDataCall,
  getWebeatPlatformSelector,
  setAxiosBaseUrl,
} from '../api';
import apiPaths from '../apiPaths';
import { ComboData } from '../combos/ComboInterfaces';
import configDev from '../configDev';
import mockData from '../mockData';
import { ReducersState } from '../reducers';
import {
  formattedMenu,
  getPlatformBaseUrl,
  PLATFORM_CODE_KEY,
  PLATFORM_KEY,
  PLATFORM_TIMEZONE,
  saveItem,
} from '../utils';
import { feedback } from '../utils/feedback';
import {
  IPlatform,
  IRecord,
  IUserPermission,
  IUserPlatform,
  MenuItem,
} from './AppInterfaces';
import { appTypes } from './appTypes';

const INITIAL_PLATFORM = configDev.WEBEAT_PLATFORM || '1';

interface Access {
  type: appTypes.APP_ACCESS_DATA;
  payload: {
    appParams: IRecord | null;
    menu: MenuItem[] | null;
    permissions: string[] | null;
    platforms: ComboData[] | null;
    user: IRecord | null;
    userPermissions: IUserPermission[];
    userPlatforms: IUserPlatform[];
  };
}

interface ChangeLocation {
  type: appTypes.ROUTER_LOCATION_CHANGE;
  payload: { location: any };
}

interface RemoveStore {
  type: appTypes.APP_REMOVE_STORE;
}

export interface SetCurrentPlatform {
  type: appTypes.APP_SET_CURRENT_PLATFORM;
  payload: { currentPlatform: string; timezoneName: string };
}

interface SetLanguage {
  type: appTypes.APP_SET_LANGUAGE;
  payload: string;
}

interface SetMenuState {
  type: appTypes.APP_MENU_STATE;
  payload: { menuState: number };
}

export type AppActionTypes =
  | Access
  | ChangeLocation
  | RemoveStore
  | SetCurrentPlatform
  | SetLanguage
  | SetMenuState;

export const access =
  () =>
  async (
    dispatch: Dispatch<Access | SetCurrentPlatform>,
    getState: () => ReducersState,
  ) => {
    try {
      const authPlatforms = configDev.LOAD_MOCK_AUTH_PLATFORMS
        ? []
        : await getAuthPlatforms();

      const currentPlatform = authPlatforms.find(
        (platform) => platform.apiUrl === getState().app.currentPlatform,
      );

      const isWebeatPlatform =
        Boolean(configDev.WEBEAT_PLATFORM) ||
        Boolean(currentPlatform?.platformCode?.includes('webeat'));

      await loadPlatform(authPlatforms, dispatch, getState, isWebeatPlatform);

      const status = { action: 'fetch', status: 200 };

      return status;
    } catch (err) {
      if (!err?.response) return { action: 'fetch', status: {} };
      const status = { action: 'fetch', status: err?.response.status };
      return status;
    }
  };

const loadPlatform = async (
  authPlatforms: IPlatform[],
  dispatch: Dispatch<Access | SetCurrentPlatform>,
  getState: () => ReducersState,
  isWebeatPlatform: boolean,
) => {
  let menu: MenuItem[] = [];

  const [userPlatforms, user, appParams, userPermissions] = await Promise.all([
    getPlatforms(getState, isWebeatPlatform),
    getUser(getState),
    getAppParams(getState),
    getPermissions(getState),
  ]);

  const permissions = userPermissions.map(({ name }) => name);

  const platforms: ComboData[] = userPlatforms.filter(({ idPermission }) =>
    userPermissions
      .map(({ idPermissions }) => idPermissions)
      .includes(idPermission),
  );

  const authorizedPlatforms = authPlatforms.filter((authPlatform) =>
    platforms.find(
      (platform) =>
        platform.idPermission === authPlatform.permission.idPermissions,
    ),
  );

  menu = await getMenu(
    getState,
    platforms,
    permissions,
    authPlatforms.length === 1,
  );

  if (menu.length === 0) {
    const mainPlatform = authorizedPlatforms.pop();

    if (!mainPlatform) throw new Error('SIN PLATAFORMAS');

    const url = mainPlatform.apiUrl;
    const timezoneName = mainPlatform.timezoneName;
    const platformCode = mainPlatform.platformCode;

    saveItem(PLATFORM_KEY, url);
    saveItem(PLATFORM_TIMEZONE, timezoneName);
    saveItem(PLATFORM_CODE_KEY, platformCode);
    setAxiosBaseUrl(url);

    dispatch({
      type: appTypes.APP_SET_CURRENT_PLATFORM,
      payload: { currentPlatform: url, timezoneName },
    });

    await loadPlatform(
      authorizedPlatforms,
      dispatch,
      getState,
      isWebeatPlatform,
    );
    return;
  }

  dispatch({
    type: appTypes.APP_ACCESS_DATA,
    payload: {
      appParams,
      menu,
      permissions,
      platforms,
      user,
      userPermissions,
      userPlatforms,
    },
  });
};

export const setLanguage =
  (lang: string) => (dispatch: Dispatch<SetLanguage>) => {
    switch (lang) {
      case 'ca':
      case 'ca-ES':
        lang = 'ca-ES';
        break;
      case 'en':
      case 'en-US':
      default:
        lang = 'en-US';
        break;
      case 'es':
      case 'es-ES':
        lang = 'es-ES';
        break;
      case 'es-AR':
        lang = 'es-AR';
        break;
      case 'es-CL':
        lang = 'es-CL';
        break;
      case 'es-CO':
        lang = 'es-CO';
        break;
      case 'es-MX':
        lang = 'es-MX';
        break;
    }

    const locale = lang;
    dispatch({ type: appTypes.APP_SET_LANGUAGE, payload: locale });
  };

export const setMenuState =
  ({ menuState }: { menuState: number }) =>
  async (dispatch: Dispatch<SetMenuState>) => {
    dispatch({
      type: appTypes.APP_MENU_STATE,
      payload: { menuState },
    });
  };

export const setCurrentPlatform =
  (url: string, timezoneName: string, platform_code: string) =>
  (dispatch: Dispatch<SetCurrentPlatform>) => {
    saveItem(PLATFORM_KEY, url);
    saveItem(PLATFORM_TIMEZONE, timezoneName);
    saveItem(PLATFORM_CODE_KEY, platform_code);
    setAxiosBaseUrl(url);

    dispatch({
      type: appTypes.APP_SET_CURRENT_PLATFORM,
      payload: { currentPlatform: url, timezoneName },
    });
  };

// UTILS
const getPlatforms = async (
  getState: () => ReducersState,
  isWebeatPlatform: boolean,
) => {
  const platforms = getState().app.userPlatforms;
  let platformsItems: IUserPlatform[] = [];

  if (platforms !== null && platforms?.length > 0) return platforms;

  if (configDev.LOAD_MOCK_PLATFORMS) {
    const platforms = mockData.PLATFORMS;
    if (platforms[0].value) {
      saveItem(PLATFORM_KEY, platforms[0].value);
    }
    if (platforms[0].timezonename) {
      saveItem(PLATFORM_TIMEZONE, platforms[0].timezonename);
    }
    if (platforms[0].platform_code) {
      saveItem(PLATFORM_CODE_KEY, platforms[0].platform_code);
    }
    return platforms;
  }

  try {
    let response;

    if (isWebeatPlatform) {
      response = await getWebeatPlatformSelector();
    } else {
      response = await getCombo({
        id: apiPaths.COMBODATA.PLATFORMS,
      });
    }

    if (response?.status === 200 && response.data) {
      platformsItems = response.data;

      const savedPlatform = getPlatformBaseUrl();

      platformsItems.forEach((platform) => {
        if (platform.value !== savedPlatform) return;

        saveItem(PLATFORM_CODE_KEY, platform.platform_code!);
        saveItem(PLATFORM_TIMEZONE, platform.timezonename!);
      });
    }
  } catch (err) {
    console.group(err, 'Could not load platforms');
  }

  return platformsItems;
};

const getUser = async (getState: () => ReducersState) => {
  const user = getState().app.user;

  if (!isEmpty(user)) return user;

  if (configDev.LOAD_MOCK_USER) {
    return mockData.USER;
  }

  const response = await getDataCall({
    dataPath: apiPaths.AUTH.USER,
    callConfig: {},
  });

  return response.data;
};

const getAppParams = async (
  getState: () => ReducersState,
): Promise<IRecord> => {
  const appParams = getState().app.appParams;
  if (appParams !== null) return appParams;

  if (configDev.LOAD_MOCK_PARAMS) {
    return mockData.PARAMS;
  }

  const response: any = await getDataCall({
    callConfig: { params: { size: 500 } },
    dataPath: apiPaths.AUTH.PARAMS,
  });

  return response.data.content;
};

const getMenu = async (
  getState: () => ReducersState,
  platformList: ComboData[],
  permissions: string[],
  shouldShowMessage: boolean = true,
) => {
  const menu = getState().app.menu;
  let menuItems: MenuItem[] = [];

  if (menu !== null) return menu;

  if (configDev.LOAD_MOCK_MENU) {
    return mockData.MENU;
  }

  const savedPlatform = getPlatformBaseUrl();

  let idPlatform = INITIAL_PLATFORM;

  if (platformList?.length) {
    platformList.forEach((platform) => {
      if (platform.value === savedPlatform) idPlatform = platform.idPlatform!;
    });
  }

  const response = await accessCall({
    dataPath: apiPaths.ACCESS,
    callConfig: { params: { idPlatform } },
  });

  menuItems = formattedMenu(response.data, permissions);

  if (shouldShowMessage && !menuItems?.length) {
    feedback({
      type: 'notification',
      method: 'warning',
      message:
        'No tienes permisos para acceder a ninguna pantalla. Contacta con el Administrador.',
      duration: 10,
    });
  }
  return menuItems;
};

const getPermissions = async (
  getState: () => ReducersState,
): Promise<IUserPermission[]> => {
  const permissions = getState().app.userPermissions;
  let userPermissions: IUserPermission[];

  if (permissions.length > 0) return permissions;

  if (configDev.LOAD_MOCK_PERMISSIONS) {
    return mockData.PERMISSIONS;
  }
  const response = await getDataCall({
    dataPath: apiPaths.AUTH.PERMISSIONS,
    callConfig: {},
  });

  if (response?.status !== 200 || !response?.data) return [];

  userPermissions = response.data;

  return userPermissions;
};

const getAuthPlatforms = async (): Promise<IPlatform[]> => {
  const [platformsResponse] = await Promise.all([
    getDataCall({
      dataPath: apiPaths.AUTH.PLATFORMS,
      callConfig: {},
    }),
  ]);

  if (platformsResponse?.status !== 200 || !platformsResponse?.data) return [];

  const platforms: IPlatform[] = platformsResponse.data.content;

  return platforms.sort((a, b) => {
    if (a.platformType === 'con') {
      return -1;
    }

    return 0;
  });
};
