import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { Modal, Button } from 'antd';
import { InjectedIntlProps } from 'react-intl';
import { History } from 'history';

import { ReducersState } from '../reducers';
import { setM2MModalVisibility, resetM2M } from './m2mActions';
import { getTableData } from '../tables/tableActions';

import M2MDashboard from './M2MDashboard';

import { IFormComponent } from '../forms/formInterfaces';
import { ITableComponent } from '../tables/tableInterfaces';
import { IRow } from '../app/AppInterfaces';

interface IM2MProps {
  customDashboard: string;
  components: ITableComponent[] | IFormComponent[];
  dashboardId: string;
}

interface IOwnProps {
  parentSelectedRow: IRow;
  history: History;
  parentComponentId: string;
  navigationId: string;
  disabled: boolean;
  dataPath: string;
  foreignKey: string;
  joinForeignKey: string;
  mainKeyValue: string;
  componentId: string;
  path?: string;
  primaryKey?: string;
  joinKey?: string;
  intl: typeof InjectedIntlProps;
}

export type TM2MEditButtonListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IOwnProps &
  IM2MProps;

class M2MEditButton extends React.PureComponent<TM2MEditButtonListProps, {}> {
  componentDidUpdate(prevProps: TM2MEditButtonListProps) {
    const { resetM2M, isM2MModalVisible } = this.props;
    if (prevProps.isM2MModalVisible !== isM2MModalVisible && !isM2MModalVisible)
      resetM2M();
  }

  handleChangeM2MModalVisibility = (isVisible: boolean) => {
    const {
      getTableData,
      setM2MModalVisibility,
      parentComponentId,
      dataPath
    } = this.props;
    setM2MModalVisibility(isVisible);
    getTableData({ dataPath, componentId: parentComponentId, queryParams: {} });
  };

  render() {
    const {
      isM2MModalVisible,
      selectedM2MDashboard,
      dashboardId,
      intl
    } = this.props;

    const m2mModal = (
      <Modal
        visible={isM2MModalVisible && selectedM2MDashboard === dashboardId}
        className="m2mModal"
        onCancel={() => this.handleChangeM2MModalVisibility(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={1000}>
        <M2MDashboard {...this.props} isM2M={true} />
      </Modal>
    );

    return (
      <React.Fragment>
        <Button
          icon="folder-add"
          type="dashed"
          style={{ marginLeft: '10px' }}
          onClick={() => this.handleChangeM2MModalVisibility(true)}
          disabled={this.props.disabled}>
          {intl.formatMessage({ id: 'table.m2m.asociate' })}
        </Button>
        {m2mModal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReducersState) => {
  return {
    isM2MModalVisible: state.m2m.visible,
    selectedM2MDashboard: state.m2m.activeDashboard
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { setM2MModalVisibility, resetM2M, getTableData },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(M2MEditButton);
