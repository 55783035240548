import { Col, Input, Row } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ReducersState } from '../../../reducers';
import {
  editChildResourceCode,
  editRecursiveResource,
  handleRemoveChildResource,
} from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import { ResourceTypeDetailEnum } from '../../Enums';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';

interface IChallengeMaterialsGroup {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IChallengeMaterialsGroup;

const Material: FC<IGroupProps> = ({
  _resource,
  accessToken,
  combos,
  editChildResourceCode,
  editRecursiveResource,
  editResource,
  form,
  groupIndex,
  handleDeleteImages,
  mainIdResource,
  values,
}) => {
  const { resourceDetailList, code } = _resource;
  const groupDetails: IndexedDetail[] = [];
  const { formatMessage } = useIntl();
  const materialTypes =
    combos.challengechallengeEdit?.type?.materialType?.data || [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) groupDetails.push({ index, detail });
  });

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12}>
          <Form.Item
            className="Quiz__tree-input"
            label={formatMessage({ id: 'resource.i-zoom-code' })}>
            {form.getFieldDecorator(`${mainIdResource}code${groupIndex}`, {
              initialValue: code,
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'update.required' }),
                },
              ],
            })(
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  editChildResourceCode(_resource.idResource, e.target.value)
                }
                disabled={!editResource}
              />,
            )}
          </Form.Item>
        </Col>
        {groupDetails
          ?.sort(
            (
              {
                detail: {
                  idResourceTypeD: { idResourceTypeD: typeEnumA },
                },
              },
              {
                detail: {
                  idResourceTypeD: { idResourceTypeD: typeEnumB },
                },
              },
            ) => {
              if (typeEnumA === ResourceTypeDetailEnum.MATERIAL_ORDER)
                return -1;

              if (typeEnumB === ResourceTypeDetailEnum.MATERIAL_ORDER) return 1;

              if (typeEnumA === ResourceTypeDetailEnum.MATERIAL_DESCRIPTION)
                return 1;

              if (typeEnumB === ResourceTypeDetailEnum.MATERIAL_DESCRIPTION)
                return -1;

              if (typeEnumA === ResourceTypeDetailEnum.MATERIAL_CONTENT)
                return -1;

              if (typeEnumB === ResourceTypeDetailEnum.MATERIAL_CONTENT)
                return 1;

              return 0;
            },
          )
          .map(({ detail, index }) =>
            RenderDetail({
              accessToken: accessToken,
              combo:
                detail.idResourceTypeD.type !== 'number'
                  ? materialTypes
                  : undefined,
              detail,
              detailArrayPosition: index,
              editRecursiveResource,
              editResource,
              form,
              groupDetails,
              handleDeleteImages,
              mainIdResource,
              resource: _resource,
              parentArrayPosition: groupIndex,
              values,
            }),
          )}
      </Row>
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos.combos,
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { editChildResourceCode, editRecursiveResource, handleRemoveChildResource },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Material);
