import { List, notification } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { IRow } from '../app/AppInterfaces';
import { isConPlatform } from '../utils';
import { IResourceDetail } from './ChallengeInterfaces';
import { ResourceTypeDetailEnum } from './Enums';

export const renderErrors = (
  errorsArray: { email: string; message: string }[],
  intl: any,
) => {
  const dataError = errorsArray.map(
    (_error: { email: string; message: string }) => (
      <div>
        {_error.email} - Reason: {_error.message}
      </div>
    ),
  );
  notification.warn({
    message: intl.formatMessage({
      id: 'challenge.visibility.wrongEmail',
    }),
    duration: 0,
    description: (
      <List
        size="small"
        renderItem={(item: JSX.Element) => <List.Item>{item}</List.Item>}
        dataSource={dataError}
      />
    ),
  });
};

// FIXME: BORRAR: categories AHORA ES filter
export const getCategoriesValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.categories || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idCategory - b.idCategory)
    .map((value: IRow) => value.idCategory.toString());
};

export const getFiltersValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.filters || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idFilter - b.idFilter)
    .map((value: IRow) => value.idFilter.toString());
};

export const getRoleTypeValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.roles || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort(
      (a: IRow, b: IRow) => a.idRoleType - b.idRoleType,
    )
    .map((value: IRow) => value.idRoleType.toString());
};

export const getSpecialtiesValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.specialties || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idSpecialty - b.idSpecialty)
    .map((value: IRow) => value.idSpecialty.toString());
};

export const getTagValues = (values: IRow) => {
  if (!values) return [];
  const selectValues = values?.tags || {};
  if (!selectValues || isEmpty(selectValues)) return [];

  return selectValues
    .sort((a: IRow, b: IRow) => a.idTag - b.idTag)
    .map((value: IRow) => value.idTag.toString());
};

export const getDetail = (
  resourceDetailList: IResourceDetail[],
  idResourceType: ResourceTypeDetailEnum,
): [IResourceDetail | null, number | null] => {
  let detailIndex: number | null = null;
  const detail =
    resourceDetailList.find(
      ({ idResourceTypeD: { idResourceTypeD } }, index) => {
        if (idResourceTypeD === idResourceType) {
          detailIndex = index;
          return true;
        }
        return false;
      },
    ) || null;

  return [detail, detailIndex];
};

export const isConExperience = (idChallengeType: number) => {
  return isConPlatform();

  // return idChallengeType in ChallengeTypeEnum;
};

// FIXME: BORRAR: categories AHORA ES filter
export const showCategories = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showFilters = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showBrands = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showRoleType = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};

export const showSpecialties = (idChallengeType: number) => {
  if (isConExperience(idChallengeType)) return false;
  return true;
};
