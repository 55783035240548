import * as api from '../api';
import config from '../config';
import configDev from '../configDev';
import { tableTypes } from './tableTypes';
import { filterComposer } from '../utils/apiUtils';
import { saveConfig, loadConfig, loadForeignFK } from '../utils/localStorage';
import { isEmpty } from 'lodash';
import { Column, QueryParams, TableData } from './tableInterfaces';
import { Dispatch } from 'redux';
import { ReducersState } from '../reducers';
import { IRow } from '../app/AppInterfaces';

interface SetInitialSate {
  type: tableTypes.TABLE_INITIAL_STATE;
  payload: {
    componentId: string;
    targetsId: target[];
    columnsConfig: any[];
    queryParams: QueryParams;
    filters: string;
    prettierKey?: string;
    data: TableData;
    selectedRow: IRow;
    selectedRowKeys: number[];
    rowKey: string;
  };
}

export const setInitialState =
  ({
    componentId,
    columns,
    data,
    filters,
    prettierKey,
    queryParams,
    rowKey,
    selectedRow,
    selectedRowKeys,
    targetsId,
  }: {
    columns: Column[];
    componentId: string;
    data: TableData;
    filters: string;
    prettierKey?: string;
    queryParams: QueryParams;
    rowKey: string;
    selectedRow: IRow;
    selectedRowKeys: number[];
    targetsId: target[];
  }) =>
  (dispatch: Dispatch<SetInitialSate>) => {
    const filters = filterComposer(columns);
    const initialColumns = columns
      .filter((column: Column) => {
        return column.mustRender;
      })
      .sort((a, b) => {
        return a.position - b.position;
      })
      .map((c, i) => {
        return (c = {
          ...c,
          position: i,
          width: c.width ? c.width : 200,
        });
      });
    if (configDev.SAVE_CONFIG) {
      const userConfig = loadConfig();
      const newConfig = {
        ...userConfig,
        [config.USER.USERID]: {
          ...userConfig[config.USER.USERID],
          [componentId]: {
            ...userConfig[config.USER.USERID][componentId],
            columnsConfig: initialColumns,
          },
        },
      };
      saveConfig(newConfig);
    }

    let tableParams = { ...queryParams };

    dispatch({
      type: tableTypes.TABLE_INITIAL_STATE,
      payload: {
        componentId,
        targetsId,
        columnsConfig: initialColumns,
        queryParams: tableParams,
        filters,
        prettierKey,
        data,
        selectedRow,
        selectedRowKeys,
        rowKey,
      },
    });
  };

interface SetTableInitialState {
  type: tableTypes.SET_INITIAL_TABLE_STATE;
  payload: { componentId: string };
}

export const setTableInitialState =
  ({
    componentId,
    challengeType,
  }: {
    componentId: string;
    challengeType?: number;
  }) =>
  (dispatch: Dispatch<SetTableInitialState>) => {
    dispatch({
      type: tableTypes.SET_INITIAL_TABLE_STATE,
      payload: { componentId, challengeType },
    });
  };

interface ResetTableContent {
  type: tableTypes.TABLE_RESET_CONTENT;
  payload: { componentId: string };
}

export const resetTableContent =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<ResetTableContent>) => {
    dispatch({
      type: tableTypes.TABLE_RESET_CONTENT,
      payload: { componentId },
    });
  };

interface SetChildSelectedRow {
  type: tableTypes.TABLE_SET_CHILD_ROW;
  payload: {
    componentId: string;
    parentSelectedRow: IRow;
    parentPrettierKey: string;
    parentRowKey: string;
  };
}

export interface target {
  id: string;
  path: string;
}

export const setChildSelectedRow =
  ({
    targetsId,
    record,
    prettierKey,
    rowKey,
  }: {
    targetsId: target[];
    record: IRow;
    prettierKey?: string;
    rowKey: string;
  }) =>
  (dispatch: Dispatch<SetChildSelectedRow>) => {
    targetsId.forEach((target: target) => {
      dispatch({
        type: tableTypes.TABLE_SET_CHILD_ROW,
        payload: {
          componentId: target.id,
          parentSelectedRow: record,
          parentPrettierKey: prettierKey!,
          parentRowKey: rowKey,
        },
      });
    });
  };

interface ResetTableComponent {
  type: tableTypes.TABLE_RESET_COMPONENT;
  payload: { componentId: string };
}

export const resetTableComponent =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<ResetTableComponent>) => {
    dispatch({
      type: tableTypes.TABLE_RESET_COMPONENT,
      payload: { componentId },
    });
  };

interface SetTableLoadData {
  type: tableTypes.TABLE_LOAD_DATA_TRIGGER;
  payload: { componentId: string };
}
export const setTableLoadData =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<SetTableLoadData>) => {
    dispatch({
      type: tableTypes.TABLE_LOAD_DATA_TRIGGER,
      payload: { componentId },
    });
  };

interface SetLoadData {
  type: tableTypes.TABLE_SET_LOAD_DATA;
  payload: { componentId: string };
}
export const setLoadData =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<SetLoadData>) => {
    dispatch({
      type: tableTypes.TABLE_SET_LOAD_DATA,
      payload: { componentId },
    });
  };

interface SetColumnsConfig {
  type: tableTypes.TABLE_CONFIGURED_COLUMNS;
  payload: {
    componentId: string;
    columns: Column[];
  };
}

export const setColumnsConfig =
  ({ componentId, columns }: { componentId: string; columns: Column[] }) =>
  (dispatch: Dispatch<SetColumnsConfig>) => {
    dispatch({
      type: tableTypes.TABLE_CONFIGURED_COLUMNS,
      payload: { componentId, columns },
    });
    let userConfig = loadConfig();
    if (configDev.SAVE_CONFIG) {
      if (userConfig === undefined) {
        userConfig = {};
      }
      const newConfig = {
        ...userConfig,
        [componentId]: {
          ...userConfig[componentId],
          columnsConfig: columns,
        },
      };
      saveConfig(newConfig);
    }
  };

interface GettingTableData {
  type: tableTypes.TABLE_FETCHING_DATA;
  payload: {
    componentId: string;
    queryParams: QueryParams;
    isLoading: boolean;
  };
}

interface GotTableData {
  type: tableTypes.TABLE_FETCHED_DATA;
  payload: { componentId: string; data: TableData; isLoading: boolean };
}

interface SendingDataError {
  type: tableTypes.TABLE_SEARCH_SENDING_DATA_ERROR;
  payload: { componentId: string; isLoading: boolean };
}

export const getTableData =
  ({
    dataPath,
    componentId,
    queryParams = {},
    foreignFilters,
  }: {
    dataPath: string;
    componentId: string;
    queryParams?: QueryParams;
    foreignFilters?: string;
  }) =>
  async (
    dispatch: Dispatch<GettingTableData | GotTableData | SendingDataError>,
    getState: () => ReducersState,
  ) => {
    const rowKey = getState().tables[componentId].rowKey;
    const currentParams: QueryParams =
      getState().tables[componentId].queryParams;
    const filters = getState().tables[componentId].filters;

    let nextParams: QueryParams = {};
    if (queryParams.q) {
      queryParams.size
        ? (nextParams = queryParams)
        : (nextParams = {
            ...queryParams, //ATENCIÓN A ESTO!!
            // q: queryParams.q,
            size: currentParams.size,
          });
      if (!(queryParams.field && queryParams.sort))
        nextParams = {
          ...nextParams,
          sort: currentParams.sort,
          field: currentParams.field,
        };
    } else {
      nextParams = {
        ...currentParams,
        ...queryParams,
      };
    }

    dispatch({
      type: tableTypes.TABLE_FETCHING_DATA,
      payload: { componentId, queryParams: nextParams, isLoading: true },
    });

    let callParams = { ...nextParams };

    callParams.q =
      foreignFilters !== undefined
        ? foreignFilters + config.QUERY.AND + callParams.q
        : isEmpty(filters)
        ? callParams.q
        : isEmpty(callParams.q)
        ? filters
        : filters + config.QUERY.AND + callParams.q;

    try {
      let callConfig = {
        params: callParams,
      };

      //Load new tab params
      const foreignPK = loadForeignFK();
      if (foreignPK === undefined || foreignPK === '')
        if (callParams.q) {
          const params = callParams.q.split(config.QUERY.AND);
          params.forEach((param, i) => {
            if (param.split(config.QUERY.ID_OPERATOR)[0] === rowKey) {
              params.splice(i, 1);
            }
          });
          callParams.q = params.join(config.QUERY.AND);
        }

      const response = await api.getDataCall({
        dataPath,
        callConfig,
      });

      if (response.data)
        dispatch({
          type: tableTypes.TABLE_FETCHED_DATA,
          payload: { componentId, data: response.data, isLoading: false },
        });
      return {
        action: 'fetch',
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      dispatch({
        type: tableTypes.TABLE_SEARCH_SENDING_DATA_ERROR,
        payload: { componentId, isLoading: false },
      });
      if (!err.response) return { action: 'fetch', status: {} };
      const status = {
        action: 'fetch',
        status: err.response.status,
        message: err.response.data.message,
      };
      return status;
    }
  };

interface DeletingTableData {
  type: tableTypes.TABLE_DELETING_DATA;
  payload: { componentId: string; isLoading: boolean };
}

interface DeletedTableData {
  type: tableTypes.TABLE_DELETED_DATA;
  payload: {
    componentId: string;
    selectedRowKeys: number[];
    isLoading: boolean;
  };
}

export const deleteTableData =
  ({
    dataPath,
    componentId,
    selectedRowKeys,
    primaryKey,
    primaryKeyValue,
    deleteDataPath,
  }: {
    dataPath: string;
    componentId: string;
    selectedRowKeys: number[];
    primaryKey: string;
    primaryKeyValue: string | number;
    deleteDataPath?: string | null;
  }) =>
  async (
    dispatch: Dispatch<
      | DeletingTableData
      | DeletedTableData
      | GettingTableData
      | GotTableData
      | SendingDataError
    >,
    getState: () => ReducersState,
  ) => {
    const totalElements = getState().tables[componentId].data.totalElements;
    const totalPages = getState().tables[componentId].data.totalPages;
    const currentPage = getState().tables[componentId].queryParams.page;
    const navigationParams = getState().router.location.search;

    const numberOfElementsToErase = selectedRowKeys.length;
    const numberOfElementsInLastPage = totalElements % (totalPages - 1);

    dispatch({
      type: tableTypes.TABLE_DELETING_DATA,
      payload: { componentId, isLoading: true },
    });
    try {
      const response = await api.deleteDataCallById({
        dataPath: deleteDataPath ? deleteDataPath : dataPath,
        registerId: primaryKeyValue,
      });

      dispatch({
        type: tableTypes.TABLE_DELETED_DATA,
        payload: { componentId, selectedRowKeys: [], isLoading: false },
      });

      setSelectedRow({
        componentId,
        selectedRow: {},
      });

      let getData = true;
      if (!isEmpty(navigationParams) && navigationParams.indexOf('?q=') >= 0) {
        let params = navigationParams.split(config.QUERY.ID_OPERATOR);
        if (params[0].split('?q=')[1].toString() === primaryKey.toString())
          getData = false;
      }

      if (
        numberOfElementsInLastPage === numberOfElementsToErase &&
        totalPages > 1
      ) {
        const queryParams: QueryParams = {
          page: currentPage === totalPages - 1 ? totalPages - 2 : currentPage,
        };
        await getTableData({ dataPath, componentId, queryParams })(
          dispatch,
          () => getState(),
        );
      } else {
        if (getData) {
          await getTableData({ dataPath, componentId })(dispatch, () =>
            getState(),
          );
        }
      }

      //TO DO: Multiple delete when multiple delete call turns available
      const status = { action: 'delete', status: response.status };
      return status;
    } catch (err) {
      dispatch({
        type: tableTypes.TABLE_DELETING_DATA,
        payload: { componentId, isLoading: false },
      });
      if (!err.response) return { action: 'delete', status: {} };
      const status = {
        action: 'delete',
        status: err.response.status,
        message: err.response.data.message,
      };
      return status;
    }
  };

interface SetInitialTableParams {
  type: tableTypes.TABLE_SET_INITIAL_PARAMS;
  payload: {
    componentId: string;
    sort: string;
    field: string;
  };
}

export const setInitialTableParams =
  ({
    sort,
    field,
    componentId,
  }: {
    sort: string | undefined;
    field: string | undefined;
    componentId: string;
  }) =>
  (dispatch: Dispatch<SetInitialTableParams>) => {
    dispatch({
      type: tableTypes.TABLE_SET_INITIAL_PARAMS,
      payload: {
        sort: sort ? sort : '',
        field: field ? field : '',
        componentId,
      },
    });
  };

interface SetSelectedRows {
  type: tableTypes.TABLE_SELECTED_ROWS;
  payload: {
    componentId: string;
    selectedRowKeys: number[] | string[];
  };
}

export const setSelectedRows =
  ({
    componentId,
    selectedRowKeys,
  }: {
    componentId: string;
    selectedRowKeys: number[] | string[];
  }) =>
  (dispatch: Dispatch<SetSelectedRows>) => {
    dispatch({
      type: tableTypes.TABLE_SELECTED_ROWS,
      payload: { componentId, selectedRowKeys },
    });
  };

interface SetSelectedRow {
  type: tableTypes.TABLE_SELECTED_ROW;
  payload: {
    componentId: string;
    selectedRow: IRow | {};
  };
}

export const setSelectedRow =
  ({
    componentId,
    selectedRow,
  }: {
    componentId: string;
    selectedRow: IRow | {};
  }) =>
  (dispatch: Dispatch<SetSelectedRow>) => {
    dispatch({
      type: tableTypes.TABLE_SELECTED_ROW,
      payload: { componentId, selectedRow },
    });
  };

interface SetFormStateFlag {
  type: tableTypes.TABLE_SET_FORM_STATE_FLAG;
  payload: {
    componentId: string;
    formHasChanged: boolean;
  };
}
export const setFormStateFlag =
  ({
    componentId,
    formHasChanged,
  }: {
    componentId: string;
    formHasChanged: boolean;
  }) =>
  (dispatch: Dispatch<SetFormStateFlag>) => {
    dispatch({
      type: tableTypes.TABLE_SET_FORM_STATE_FLAG,
      payload: { componentId, formHasChanged },
    });
  };

interface ResetTableData {
  type: tableTypes.RESET_TABLE_DATA;
  payload: {
    componentId: string;
    data: TableData;
    queryParams: QueryParams;
  };
}

export const resetTableData =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<ResetTableData>) => {
    dispatch({
      type: tableTypes.RESET_TABLE_DATA,
      payload: {
        componentId,
        data: {} as TableData,
        queryParams: {},
      },
    });
  };

interface LoadImageModal {
  type: tableTypes.TABLE_FETCHING_IMAGE_MODAL;
  payload: {
    visible: boolean;
    isLoading: boolean;
    componentId: string;
  };
}

export const loadImageModal =
  ({ componentId, imageUri }: { componentId: string; imageUri: string }) =>
  async (dispatch: Dispatch<LoadImageModal>) => {
    dispatch({
      type: tableTypes.TABLE_FETCHING_IMAGE_MODAL,
      payload: { componentId, visible: true, isLoading: true },
    });

    try {
      const response = await api.resourceCall({
        dataPath: imageUri,
      });

      const status = {
        action: 'fetch',
        status: response.status,
        image: response.data,
      };
      return status;
    } catch (err) {
      if (!err.response) return { action: 'image', status: {} };
      const status = { action: 'image', status: err.response.data.status };
      return status;
    }
  };

interface DisplayImageModal {
  type: tableTypes.TABLE_FETCHED_IMAGE_MODAL;
  payload: {
    componentId: string;
    visible: boolean;
    isLoading: boolean;
  };
}

export const displayImageModal =
  (componentId: string, visible: boolean, isLoading: boolean) =>
  (dispatch: Dispatch<DisplayImageModal>) => {
    dispatch({
      type: tableTypes.TABLE_FETCHED_IMAGE_MODAL,
      payload: { componentId, visible, isLoading },
    });
  };

//CUSTOM ISDIN ACTIONS
export interface UpdateSelectedRow {
  type: tableTypes.TABLE_UPDATE_SELECTED_ROW;
  payload: {
    selectedRow: IRow;
    data: TableData;
    componentId: string;
  };
}

export const updateSelectedRow =
  (newRow: IRow, primaryKey: string, componentId: string) =>
  (dispatch: Dispatch<UpdateSelectedRow>, getState: () => ReducersState) => {
    let data = { ...getState().tables[componentId].data };

    const newContent = data.content.map((_row) =>
      _row[primaryKey] === newRow[primaryKey] ? newRow : _row,
    );

    data.content = newContent;

    dispatch({
      type: tableTypes.TABLE_UPDATE_SELECTED_ROW,
      payload: { selectedRow: newRow, data, componentId },
    });
  };

export const fetchRow =
  ({
    dataPath,
    primaryKey,
    registerId,
    targetId,
  }: {
    dataPath: string;
    primaryKey: string;
    registerId: number;
    targetId: string;
  }) =>
  async (dispatch: any) => {
    const newChallenge = await api.getDataCallById({
      dataPath,
      registerId,
      callConfig: {},
    });

    if ('data' in newChallenge) {
      dispatch(updateSelectedRow(newChallenge.data, primaryKey, targetId));

      dispatch(
        setFormStateFlag({
          componentId: targetId,
          formHasChanged: false,
        }),
      );
    }
  };

export interface UpdateTableQueryParams {
  type: tableTypes.TABLE_UPDATE_QUERY_PARAMS;
  payload: {
    componentId: string;
    queryParams: QueryParams;
  };
}

export const updateTableQueryParams =
  ({
    componentId,
    newQueryParams,
  }: {
    componentId: string;
    newQueryParams: Partial<QueryParams>;
  }) =>
  (
    dispatch: Dispatch<UpdateTableQueryParams>,
    getState: () => ReducersState,
  ) => {
    const currentQueryParams = getState().tables[componentId].queryParams;

    const queryParamsToUpdate = {
      ...currentQueryParams,
      ...newQueryParams,
    };

    dispatch({
      type: tableTypes.TABLE_UPDATE_QUERY_PARAMS,
      payload: {
        componentId,
        queryParams: queryParamsToUpdate,
      },
    });
  };

export type TableActionTypes =
  | SetInitialSate
  | ResetTableContent
  | SetChildSelectedRow
  | ResetTableComponent
  | SetTableLoadData
  | SetLoadData
  | DisplayImageModal
  | SetSelectedRows
  | LoadImageModal
  | ResetTableData
  | SetFormStateFlag
  | SetSelectedRow
  | SetColumnsConfig
  | GettingTableData
  | GotTableData
  | SendingDataError
  | SetInitialTableParams
  | DeletingTableData
  | DeletedTableData
  | SetTableInitialState
  | UpdateSelectedRow
  | UpdateTableQueryParams;
