import defaultIcon from './img/menu/default';
import users from './img/menu/users';

import challenge from './img/menu/challenge.js';
import challengeType from './img/menu/challengeType.js';
import resourceType from './img/menu/resourceType.js';
import customer from './img/menu/customer';
import generalParameters from './img/menu/generalParameters';
import app from './img/menu/app';
import rol from './img/menu/rol';
import permissions from './img/menu/permissions';
import permission from './img/menu/permission';
import admin from './img/menu/family';
import specialty from './img/menu/specialty';
import country from './img/menu/country';
import language from './img/menu/language';
import liwwMenu from './img/menu/liwwMenu';
import staticPages from './img/menu/staticPages';
import list from './img/menu/list';
import modules from './img/menu/modules';
import center from './img/menu/center';

export default function getIcon({ id }: { id?: number | string }) {
  let iconId = id ?? 0;

  if (typeof iconId === 'string') iconId = parseInt(iconId);

  switch (iconId) {
    case 1: //admin FOLDER
      return admin();
    case 2: // application FOLDER
      return app();
    case 3: //configuration FOLDER
      return generalParameters();
    case 4: //module FOLDER
      return modules();
    case 5: //bo users
      return customer();
    case 6: //bo roles
    case 11:
      return rol();
    case 7: //bo pèrmissions
      return permission();
    case 8: //bo menu
    case 25:
      return liwwMenu();
    case 10: // users
      return users();
    case 13: // challenge type
      return challengeType();
    case 14: // resource type
      return resourceType();
    case 16:
      return center();
    case 17:
      return country();
    case 18:
      return language();
    case 21: // challenge template
      return permissions();
    case 24:
      return list();
    case 26:
      return staticPages();
    case 27:
      return specialty();
    case 28: //challenge FOLDER
      return challenge();
    default:
      return defaultIcon();
  }
}
