import React, { useState } from 'react';
import { Button, Modal, Row, Table } from 'antd';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { isEditProps } from '../../utils/propsUtils';
import * as api from '../../api';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { isConPlatform } from '../../utils';

interface IData {
  key: string;
  value: string;
  description: string;
}

const columns = [
  {
    title: 'Tag',
    dataIndex: 'value',
    key: 'tag',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

export default function TagsMailButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState<IData[]>([]);
  const intl = useIntl();

  const handleSetModalVisible = async (visible: boolean) => {
    try {
      const response = await api.getCombo({
        id: 'mailTags',
        param: isConPlatform() ? parentProps.props.selectedRow.type : parentProps.props.selectedRow.idMail,
      });

      if (response && response.status === 200) {
        let i = 0;

        const data = response.data.map((tag: IData) => {
          i++;

          return {
            key: i.toString(),
            value: tag.value,
            description: tag.description,
          };
        });

        setDataSource([...data]);
        setModalVisible(visible);
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isEditProps(parentProps)) {
    const editProps: EditFormRenderProps = parentProps;
    const { selectedRow } = editProps.props;

    const tagsModal = (
      <Modal
        visible={modalVisible}
        className="tagsModal"
        onCancel={() => handleSetModalVisible(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'50%'}>
        <Row className="isdin-custom-modal__container">
          <Row className="isdin-custom-modal__title">{selectedRow.name}</Row>
          <Table dataSource={dataSource} columns={columns} />
        </Row>
      </Modal>
    );

    return (
      <>
        {tagsModal}
        <Button
          style={{ marginLeft: '12px' }}
          onClick={() => handleSetModalVisible(true)}
          disabled={isEmpty(selectedRow)}
          type="primary">
          {intl.formatMessage({ id: 'mailing.button.tags' })}
        </Button>
      </>
    );
  }

  return <></>;
}
