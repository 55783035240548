import { Button, Checkbox, Col, Input, Modal, notification, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { appComponents } from '../../components';
import { ReducersState } from '../../reducers';
import { getPlatformBaseUrl } from '../../utils';
import {
  createResourceFromTemplate,
  editRecursiveResource,
  handleAddDetail,
  handleAddParentResource,
  handleDeleteDetail,
  handleRemoveParentResource,
  resetQuiz,
  setEditionChallenge,
  setEditionResource,
  setQuiz,
  setResourceHasChanged,
  setSelectedResource,
  updateResourceDetail,
} from '../challengeActions';
import { deleteResource, updateResource } from '../webeatChallengeActions';
import { IResource } from '../ChallengeInterfaces';
import { isConExperience } from '../ChallengeUtils';
import { ChallengeTypeEnum, ResourceTypeEnum } from '../Enums';
import AddAutomaticEmailModal from './AddAutomaticEmailModal';
import DependencyModal from './AddDependencyModal';
import BasicResource from './BasicResource';
import Collapsible from './Collapsible';
import Gallery from './Gallery';
import FaqsResources from './Quiz/FaqResource';
import './Resource.css';
import {
  validateEvent,
  validateEventNotification,
  validateSellIn,
  validateSellOutRanges,
  validateShoppingCart,
} from './Resource.utils';
import ResourceModal from './ResourceModal';

const {
  ACTIVATION_BUTTON,
  ATTEND,
  CHALLENGE_MATERIALS,
  CHALLENGE_VISIBILITY,
  CHECKOUT,
  CHECKOUT_BOOKING_TIMIFY,
  EMAIL_WITH_DATE,
  EMAIL_WITH_DELAY,
  EVENT,
  EXPERIENCE_ACTIVATION,
  EXPERIENCE_PROGRESS,
  FAQ,
  GALLERY,
  GENERIC_BUTTON,
  IMAGE,
  INCLUDED_ITEM,
  INCLUDED_ITEMS_LIST,
  INSCRIPTION,
  LINKED_CHALLENGES,
  MATERIAL,
  NOTIFICATION,
  PARTICIPATION,
  PEARL,
  QUIZ,
  QUIZ_TYPEFORM,
  QUIZ_DERMO,
  FORMATION_CERTIFICATE,
  REDEEM_PRODUCT,
  RESOURCE_GUIDE,
  SELL_IN_POINTS_RANGE,
  SELL_IN_PRODUCT,
  SELL_IN,
  SELL_OUT,
  SHOPPING_CART,
  SHOPPING_CART_GROUP,
  SHOPPING_CART_PRODUCT,
  SPEAKER,
  STEPS,
  SURVEY,
  ARN_SURVEY,
  ARN_SURVEY_TOKEN,
  TEXT_DURATION,
  TEXT_LINK_ITEM,
  TEXT_LINK,
  TEXT,
  VIDEO_SECTION,
  VIDEO,
  WYSIWYG,
  WYSWYG_PIN,
  CHECKOUT_LOTTERY,
  CHECKOUT_DONATION,
  BEAUTY_GUIDE,
  HIDDEN,
  LEGAL_CHECK,
} = ResourceTypeEnum;

export const SURVEY_BUTTON_LIMIT = 14;
export const ACTIVATION_BUTTON_LIMIT = 50;
export const QUIZ_TYPEFORM_TITLE_LIMIT = 40;
export const QUIZ_TYPEFORM_DESCRIPTION_LIMIT = 60;

const currentPlatform = getPlatformBaseUrl();

interface IResourceProps {
  challengeResources: IResource[];
  collapse?: boolean;
  form: WrappedFormUtils<any>;
  index: number;
  refreshChallengeData: () => Promise<void>;
  resource: IResource;
}

type IResourceListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IResourceProps;

function WebeatResource(props: IResourceListProps) {
  const [editResource, setEditResource] = useState(false);
  const [dependencyVisibility, setDependencyVisibility] = useState(false);
  const [automaticEmailVisibility, setAutomaticEmailVisibility] =
    useState(false);
  const {
    challengeResources,
    collapse,
    createResourceFromTemplate,
    deleteResource,
    editing,
    editRecursiveResource,
    form,
    formHasChanged,
    handleAddDetail,
    handleAddParentResource,
    handleDeleteDetail,
    handleRemoveParentResource,
    index,
    refreshChallengeData,
    resource,
    resourceHasChanged,
    selectedRow,
    setEditionResource,
    setResourceHasChanged,
    setSelectedResource,
    sortingResources,
    updateResource,
    updateResourceDetail,
    values,
  } = props;
  const intl = useIntl();
  const {
    activate,
    code,
    idResource,
    idResourceType: { idResourceType, name: resourceTypeName },
    indResource,
    name,
  } = resource;
  const [deleteImages, setDeleteImages] = useState<string[]>([]);
  const [resourceTitle, setResourceTitle] = useState(name);
  const [resourceCode, setResourceCode] = useState(code);
  const [resourceActivate, setResourceActivate] = useState(activate);

  const lastPosition = code && code.split('_').length - 1;
  const codeSplit = code?.split('_');
  useEffect(() => {
    setResourceTitle(name);
    setResourceCode(code);
    setResourceActivate(activate);
  }, [activate, code, name, editResource]);

  useEffect(() => {
    setEditResource(false);
  }, [selectedRow]);

  const recursiveResource = editResource ? props.selectedResource : resource;
  const isCampaign =
    selectedRow?.idChallengeType.idChallengeType === ChallengeTypeEnum.CAMPAIGN;
  const isExperience = isConExperience(
    selectedRow?.idChallengeType?.idChallengeType,
  );

  //BLOCK RESOURCE FUNCTIONS
  const showResourceCode: boolean =
    indResource === null &&
    [
      ACTIVATION_BUTTON,
      CHECKOUT,
      EVENT,
      MATERIAL,
      QUIZ,
      QUIZ_TYPEFORM,
      QUIZ_DERMO,
      FORMATION_CERTIFICATE,
      REDEEM_PRODUCT,
      SHOPPING_CART,
      SURVEY,
      ARN_SURVEY,
      VIDEO,
      SELL_OUT,
      SELL_IN,
      CHALLENGE_VISIBILITY,
      CHECKOUT_LOTTERY,
      CHECKOUT_DONATION,
      BEAUTY_GUIDE,
      HIDDEN,
    ].includes(idResourceType);

  const showResourceActivation: boolean =
    isCampaign &&
    [
      ACTIVATION_BUTTON,
      CHALLENGE_MATERIALS,
      EVENT,
      QUIZ,
      SHOPPING_CART,
      SURVEY,
      ARN_SURVEY,
      VIDEO,
      SELL_OUT,
      SELL_IN,
    ].includes(idResourceType);

  const showExtraFields: boolean = showResourceCode || showResourceActivation;

  const handleResetResource = () => {
    Modal.confirm({
      title: intl.formatMessage({
        id: 'pop.title.changes-will-not-be-saved-are-you-sure',
      }),
      okText: intl.formatMessage({ id: 'pop.accept' }),
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk() {
        props.resetQuiz();
        form.resetFields();
        setEditResource(false);
      },
      onCancel() {},
    });
  };

  const handleEditingResource = (resource: IResource) => {
    setEditResource(true);
    setEditionResource(true);
    setSelectedResource({ ...resource });
  };

  const handleEditTitle = ({ value }: { value: string }) => {
    setResourceTitle(value);
    setResourceHasChanged(true);
  };

  const handleEditCode = (value: string) => {
    setResourceCode(value);
    setResourceHasChanged(true);
  };

  const handleEditActivate = (value: boolean) => {
    setResourceActivate(value);
    setResourceHasChanged(true);
  };

  const handleEditResourceDetail = (idResourceD: number, value: any) => {
    setEditResource(true);
    updateResourceDetail(idResourceD, value);
  };

  const handleSaveResource = async () => {
    const resourceInfo = {
      name: resourceTitle,
      code: resourceCode,
      activate: resourceActivate,
    };

    let data: IResource =
      recursiveResource !== null
        ? {
            ...recursiveResource,
            ...resourceInfo,
            idChallenge: selectedRow.idChallenge,
          }
        : { ...resource, ...resourceInfo };

    form.validateFields(async (err, formValues) => {
      const isCorrectForm = !err && annexFormError(err, resource.idResource);
      const shoppingCartValidations = validateShoppingCart(
        idResourceType,
        data.resourceList,
        data.resourceDetailList,
        (errorMessageKey) =>
          notification.error({
            message: intl.formatMessage({
              id: errorMessageKey,
            }),
            duration: 3,
          }),
      );

      const sellOutRangesValidations = validateSellOutRanges(
        idResourceType,
        data.resourceList,
        data.resourceDetailList,
      );

      const sellInValidations = validateSellIn(
        idResourceType,
        data.resourceList,
        data.resourceDetailList,
      );

      const eventValidations = validateEvent(
        idResourceType,
        data.resourceList,
        err,
      );

      const notificationEventValidations = validateEventNotification(
        idResourceType,
        data.resourceList,
        err,
      );

      if (!eventValidations) {
        return notification.error({
          message: intl.formatMessage({
            id: 'challenge.required',
          }),
          duration: 3,
        });
      }

      if (!notificationEventValidations) {
        return notification.error({
          message: intl.formatMessage({
            id: 'challenge.notification-required',
          }),
          duration: 3,
        });
      }

      if (!isCorrectForm) {
        return notification.error({
          message: intl.formatMessage({
            id: 'challenge.required',
          }),
          duration: 3,
        });
      }

      if (shoppingCartValidations.haveError) return;
      //   return notification.error({
      //     message: intl.formatMessage({
      //       id: shoppingCartValidations.errorMessageKey,
      //     }),
      //     duration: 3,
      //   });

      if (!sellOutRangesValidations) {
        return notification.error({
          message: intl.formatMessage({
            id: 'tree-resource.wrong-sell-out-ranges',
          }),
          description: intl.formatMessage({
            id: 'tree-resource.wrong-sell-out-ranges-subtitle',
          }),
          duration: 3,
        });
      }

      if (!sellInValidations) {
        return notification.error({
          message: intl.formatMessage({
            id: 'tree-resource.wrong-sell-in-ranges',
          }),
          duration: 3,
        });
      }
      if (!resourceHasChanged) {
        setEditionResource(false);
        setEditResource(false);
        form.resetFields();
        return;
      }

      const response: any = await updateResource(data.idChallenge, data);

      if (response?.status === 200 && response.data) {
        if (deleteImages.length)
          await api
            .deleteCall({
              dataPath: `${currentPlatform}${apiPaths.UPLOAD_MEDIA}`,
              callConfig: { data: { path: deleteImages } },
            })
            .catch((err) => {
              console.error(err.message);
            });

        refreshChallengeData();
        setEditionResource(false);
        setEditResource(false);
      }

      form.resetFields();
    });
  };

  const annexFormError = (error: any, idResource: number) => {
    let isAnnexError = true;
    if (!error) return isAnnexError;
    for (let key in error) {
      if (
        key.includes(`code${idResource}`) ||
        key.includes(idResource.toString())
      )
        isAnnexError = false;
    }
    return isAnnexError;
  };

  const handleDeleteResource = async (index: number) => {
    Modal.confirm({
      title: 'Component cannot be restored. Are you sure?',
      okText: intl.formatMessage({ id: 'pop.accept' }),
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      async onOk() {
        const _resource: any = await deleteResource(
          selectedRow.idChallenge,
          index,
        );

        if (_resource?.status === 200) {
          refreshChallengeData();
          setEditionResource(false);
          setEditResource(false);
        }
      },
      onCancel() {},
    });
    return true;
  };

  const isActivationDisabled = (): boolean => {
    let isDisabled = false;

    switch (idResourceType) {
      case ResourceTypeEnum.CHALLENGE_MATERIALS:
        if (resourceActivate) break;

        const MAX_NUMBER_OF_MATERIAL = 2;
        const materials = recursiveResource.resourceList?.filter(
          ({ idResourceType: { idResourceType }, status }) =>
            status && idResourceType === ResourceTypeEnum.MATERIAL,
        );

        isDisabled = materials
          ? materials.length >= MAX_NUMBER_OF_MATERIAL
          : isDisabled;
        break;
    }

    return isDisabled;
  };

  const addNotification = async (notificationIndResource: number) => {
    const order =
      selectedRow.resourceList !== undefined
        ? selectedRow.resourceList.length
        : 0;

    const response: any = await createResourceFromTemplate(NOTIFICATION, {
      idChallenge: selectedRow.idChallenge,
      indResource: notificationIndResource,
      order,
    });
    if (response?.status === 200 && response.data) refreshChallengeData();
  };

  const addLegalCheck = async (legalCheckIndResource: number) => {
    const order =
      selectedRow.resourceList !== undefined
        ? selectedRow.resourceList.length
        : 0;

    const response: any = await createResourceFromTemplate(LEGAL_CHECK, {
      idChallenge: selectedRow.idChallenge,
      indResource: legalCheckIndResource,
      order,
    });

    if (response?.status === 200 && response.data) refreshChallengeData();
  };

  const addNotificationButton: JSX.Element = (
    <Fragment key="notification_button">
      {idResourceType === EVENT && (
        <Button
          key="notification_button"
          className="actionButton"
          title={intl.formatMessage({
            id: formHasChanged
              ? 'collapsible.addNotificationDisabled'
              : 'collapsible.addNotification',
          })}
          icon="bell"
          disabled={formHasChanged}
          onClick={async () => addNotification(idResource)}
        />
      )}
    </Fragment>
  );

  const addLegalCheckButton: JSX.Element = (
    <Fragment key="legal_check_button">
      {[SHOPPING_CART].includes(idResourceType) && (
        <Button
          key="legal_check_button"
          className="actionButton"
          title={intl.formatMessage({
            id: formHasChanged
              ? 'collapsible.addLegalCheckDisabled'
              : 'collapsible.addLegalCheck',
          })}
          icon="read"
          disabled={formHasChanged}
          onClick={async () => addLegalCheck(idResource)}
        />
      )}
    </Fragment>
  );

  const addDependenceButton: JSX.Element = (
    <Fragment key="dependence_button">
      {[
        ACTIVATION_BUTTON,
        CHALLENGE_MATERIALS,
        CHALLENGE_VISIBILITY,
        CHALLENGE_VISIBILITY,
        CHECKOUT,
        EVENT,
        EXPERIENCE_PROGRESS,
        GENERIC_BUTTON,
        IMAGE,
        LINKED_CHALLENGES,
        PEARL,
        QUIZ_TYPEFORM,
        QUIZ,
        QUIZ_DERMO,
        REDEEM_PRODUCT,
        SELL_IN,
        SELL_OUT,
        SHOPPING_CART,
        STEPS,
        SURVEY,
        ARN_SURVEY,
        // TEXT_DURATION,
        TEXT_LINK,
        TEXT_LINK_ITEM,
        VIDEO,
        VIDEO_SECTION,
        WYSIWYG,
        WYSWYG_PIN,
        CHECKOUT_LOTTERY,
        CHECKOUT_DONATION,
        BEAUTY_GUIDE,
        HIDDEN,
      ].includes(idResourceType) && (
        <>
          <DependencyModal
            resource={resource}
            challengeResources={challengeResources}
            dependencyModalVisibility={dependencyVisibility}
            refreshChallengeData={refreshChallengeData}
            setDependencyModalVisibility={setDependencyVisibility}
          />
          <Button
            className="actionButton"
            title={intl.formatMessage({
              id: formHasChanged
                ? 'collapsible.addDependenceDisabled'
                : 'collapsible.addDependence',
            })}
            icon="deployment-unit"
            disabled={formHasChanged}
            onClick={() => setDependencyVisibility(true)}
          />
        </>
      )}
    </Fragment>
  );

  const addAutomaticEmailButton: JSX.Element = (
    <Fragment key="automatic_email_button">
      {[
        CHECKOUT,
        CHECKOUT_LOTTERY,
        CHECKOUT_DONATION,
        QUIZ_TYPEFORM,
        REDEEM_PRODUCT,
        VIDEO,
      ].includes(idResourceType) &&
        isExperience && (
          <>
            <AddAutomaticEmailModal
              automaticEmailVisibility={automaticEmailVisibility}
              handleAddParentResource={handleAddParentResource}
              editRecursiveResource={editRecursiveResource}
              resetQuiz={resetQuiz}
              handleRemoveParentResource={handleRemoveParentResource}
              updateResource={updateResource}
              setAutomaticEmailVisibility={setAutomaticEmailVisibility}
              refreshChallengeData={refreshChallengeData}
              resourceHasChanged={resourceHasChanged}
              resource={props.selectedResource}
              combos={props.combo}
              selectedRow={selectedRow}
            />
            <Button
              className="actionButton"
              title={intl.formatMessage({
                id: formHasChanged
                  ? 'collapsible.addAutomaticEmailDisabled'
                  : 'collapsible.addAutomaticEmail',
              })}
              icon="mail"
              disabled={formHasChanged}
              onClick={() => {
                if (!editResource) {
                  setAutomaticEmailVisibility(true);
                  setSelectedResource({ ...resource });
                }
              }}
            />
          </>
        )}
    </Fragment>
  );

  //END BLOCK RESOURCE FUNCTIONS
  const preIZoomCode = `${
    values['code']
  }_${resource.idResourceType.name.toLowerCase()}`;

  const renderExtraFields = showExtraFields && (
    <>
      {showResourceCode && (
        <Col xs={24} md={12}>
          <Form.Item label={intl.formatMessage({ id: 'resource.i-zoom-code' })}>
            {form.getFieldDecorator(`code${idResource}`, {
              initialValue:
                codeSplit && lastPosition ? codeSplit[lastPosition] : '',
              // rules: [{ required: true, message: 'Required' }],
            })(
              <Input
                addonBefore={preIZoomCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEditCode(
                    `${preIZoomCode}_${e.target.value.replace('_', '-')}`,
                  )
                }
                disabled={!editResource}
              />,
            )}
          </Form.Item>
        </Col>
      )}
      {showResourceActivation && (
        <Col xs={24} md={12}>
          <Form.Item
            className="Quiz__tree-input"
            label={intl.formatMessage({ id: 'resource.activate' })}>
            {form.getFieldDecorator(`activate${idResource}`)(
              <Checkbox
                disabled={!editResource || isActivationDisabled()}
                onChange={(e: CheckboxChangeEvent) =>
                  handleEditActivate(e.target.checked)
                }
                checked={resourceActivate}
              />,
            )}
          </Form.Item>
        </Col>
      )}
    </>
  );

  const renderGroupItem = (resource: IResource, index: number) => (
    <Row>
      <Col span={1}>
        <div className="ResourcesRender__groupIndex">{index + 1}</div>
      </Col>
      <Col span={23}>{renderResource(resource)}</Col>
    </Row>
  );

  const renderResource = (resource: IResource) => {
    return (
      <Row className="ResourcesRender__resource--content" key={idResource}>
        {renderResourceContent(resource)}
      </Row>
    );
  };

  const handleDeleteImages = (image: string, idResourceD: number) => {
    setDeleteImages([...deleteImages, image]);
    updateResourceDetail(idResourceD, null);
  };

  const renderResourceContent = (resource: IResource) => {
    const { idResourceType } = resource.idResourceType;
    let component: null | (JSX.Element | null)[] | JSX.Element;
    switch (idResourceType) {
      case GALLERY:
        component = (
          <Gallery
            {...{
              editResource,
              form,
              handleDeleteImages,
              resourceList: recursiveResource.resourceList!,
            }}
          />
        );
        break;
      case FAQ:
        component = (
          <FaqsResources
            {...{
              editResource,
              form,
              handleEditResourceDetail,
              resourceList: recursiveResource.resourceList!,
            }}
          />
        );
        break;
      case LEGAL_CHECK:
      case ACTIVATION_BUTTON:
      case CHALLENGE_VISIBILITY:
      case EXPERIENCE_ACTIVATION:
      case EXPERIENCE_PROGRESS:
      case NOTIFICATION:
      case PEARL:
      case QUIZ_TYPEFORM:
      case FORMATION_CERTIFICATE:
      case REDEEM_PRODUCT:
      case RESOURCE_GUIDE:
      case SURVEY:
      case ARN_SURVEY:
      case TEXT:
      case VIDEO:
      case WYSIWYG:
      case CHECKOUT_LOTTERY:
        component = (
          <BasicResource
            {...{
              editResource,
              form,
              handleDeleteImages,
              handleEditResourceDetail,
              intl,
              renderExtraFields,
              resource: recursiveResource,
            }}
          />
        );
        break;
      case BEAUTY_GUIDE:
      case CHALLENGE_MATERIALS:
      case CHECKOUT:
      case EVENT:
      case GENERIC_BUTTON:
      case HIDDEN:
      case IMAGE:
      case INCLUDED_ITEMS_LIST:
      case LINKED_CHALLENGES:
      case QUIZ:
      case QUIZ_DERMO:
      case SELL_IN:
      case SELL_OUT:
      case SHOPPING_CART:
      case STEPS:
      case TEXT_DURATION:
      case TEXT_LINK:
      case CHECKOUT_DONATION:
      case CHECKOUT_BOOKING_TIMIFY:
      case VIDEO_SECTION:
      case WYSWYG_PIN:
        component = (
          <ResourceModal
            {...{
              editRecursiveResource,
              editResource,
              form,
              handleAddDetail,
              handleAddParentResource,
              handleDeleteDetail,
              handleDeleteImages,
              handleEditTitle,
              handleRemoveParentResource,
              handleResetResource,
              handleSaveResource,
              renderExtraFields,
              resource: recursiveResource,
              values,
            }}
          />
        );
        break;

      default:
        component = null;
    }

    return component!;
  };

  const renderCollapsibleResource = collapse ? (
    <div className="Resource__collapse">
      <Collapsible
        extraEditButtons={[
          addAutomaticEmailButton,
          addDependenceButton,
          addNotificationButton,
          addLegalCheckButton,
        ]}
        title={resourceTitle}
        titleLimit={
          idResourceType === ACTIVATION_BUTTON
            ? ACTIVATION_BUTTON_LIMIT
            : undefined
        }
        type={resourceTypeName}
        showAllContent={[
          CHALLENGE_MATERIALS,
          EVENT,
          HIDDEN,
          QUIZ,
          QUIZ_DERMO,
          SHOPPING_CART,
          CHECKOUT,
          CHECKOUT_DONATION,
          STEPS,
          TEXT_DURATION,
          CHECKOUT_BOOKING_TIMIFY,
          TEXT_LINK,
          VIDEO_SECTION,
        ].includes(idResourceType)}
        forceCollapse={sortingResources === true ?? undefined}
        forceShowContent={dependencyVisibility || automaticEmailVisibility}
        editable={{
          isEditVisible: true,
          disabled: formHasChanged || editing,
          canEdit: editResource,
          editableFunction: handleEditTitle,
          canDelete: true,
          deleteFunction: handleDeleteResource,
          deleteId: idResource,
          setEditing: () => handleEditingResource(resource),
          saveChanges: handleSaveResource,
          discardChanges: handleResetResource,
        }}>
        {resource.groupedResources && resource.groupedResources.length > 1 ? (
          <Row className="ResourcesRender__resource--content" key={index}>
            {resource.groupedResources.map((r: IResource, index: number) => (
              <WebeatResource
                key={index}
                {...{
                  ...props,
                  resource: r,
                  index,
                  collapse: false,
                  form,
                }}
              />
            ))}
          </Row>
        ) : (
          renderResource(resource)
        )}
      </Collapsible>
    </div>
  ) : (
    renderGroupItem(resource, index)
  );

  return (
    <Row>
      {![
        CHECKOUT,
        CHECKOUT_DONATION,
        FAQ,
        GALLERY,
        HIDDEN,
        QUIZ,
        QUIZ_DERMO,
        SELL_OUT,
        STEPS,
        TEXT_DURATION,
        CHECKOUT_BOOKING_TIMIFY,
        TEXT_LINK,
        VIDEO_SECTION,
      ].includes(idResourceType) && resource.resourceList!.length > 0 ? (
        <div className="Resource__wrapper">
          {renderCollapsibleResource}
          {resource.resourceList
            ?.filter(
              ({ status, idResourceType: { idResourceType } }) =>
                status &&
                ![
                  ATTEND,
                  EMAIL_WITH_DATE,
                  EMAIL_WITH_DELAY,
                  INCLUDED_ITEM,
                  INSCRIPTION,
                  MATERIAL,
                  PARTICIPATION,
                  SELL_IN_POINTS_RANGE,
                  SELL_IN_PRODUCT,
                  SPEAKER,
                  ARN_SURVEY_TOKEN,
                  SHOPPING_CART_GROUP,
                  SHOPPING_CART_PRODUCT,
                ].includes(idResourceType),
            )
            .map((_resource, i) => (
              <WebeatResource
                key={i}
                {...{
                  ...props,
                  resource: _resource,
                  index: i,
                }}
              />
            ))}
        </div>
      ) : (
        renderCollapsibleResource
      )}
    </Row>
  );
}

const mapStateToProps = (state: ReducersState) => {
  return {
    editing:
      state.challenge.editingChallenge || state.challenge.editingResource,
    formHasChanged:
      state.tables[appComponents['challengechallengeEdit'].targetId]
        .formHasChanged,
    resourceHasChanged: state.challenge.resourceHasChanged,
    selectedResource: state.challenge.selectedResource,
    selectedRow:
      state.tables[appComponents['challengechallengeEdit'].targetId]
        .selectedRow,
    sortingResources: state.challenge.sortingResources,
    combo: state.combos.combos,
    values: state.edits['challengechallengeEdit'].values,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createResourceFromTemplate,
      deleteResource,
      editRecursiveResource,
      handleAddDetail,
      handleAddParentResource,
      handleDeleteDetail,
      handleRemoveParentResource,
      resetQuiz,
      setEditionChallenge,
      setEditionResource,
      setQuiz,
      setResourceHasChanged,
      setSelectedResource,
      updateResource,
      updateResourceDetail,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(WebeatResource);
