import { IResource } from '../ChallengeInterfaces';

export enum ResourceTypeEnum {
  TEXT = 1,
  VIDEO = 2,
  IMAGE = 3,
  QUIZ = 4,
  QUESTION = 5,
  ANSWER = 6,
  EVENT = 7,
  NOTIFICATION = 8,
  SPEAKER = 9,
  INSCRIPTION = 10,
  ATTEND = 11,
  PARTICIPATION = 12,
  FAQ = 13,
  FAQ_QUESTION_1 = 14,
  FAQ_QUESTION_2 = 15,
  FAQ_QUESTION_3 = 16,
  FAQ_QUESTION_4 = 17,
  FAQ_QUESTION_5 = 18,
  GALLERY = 19,
  GALLERY_IMAGE = 20,
  GALLERY_VIDEO = 21,
  CONTACT_MAIL = 22,
  MATERIAL = 23,
  SURVEY = 24,
  ACTIVATION_BUTTON = 25,
  SHOPPING_CART = 26,
  SHOPPING_CART_GROUP = 27,
  SHOPPING_CART_PRODUCT = 28,
  RESOURCE_GUIDE = 29,
  LINKED_CHALLENGES = 30,
  CHALLENGE_MATERIALS = 31,
  EXPERIENCE_PROGRESS = 32,
  REDEEM_PRODUCT = 33,
  CHECKOUT = 34,
  CHECKOUT_PRODUCT = 35,
  QUIZ_TYPEFORM = 36,
  CHALLENGE_VISIBILITY = 37,
  EXPERIENCE_ACTIVATION = 38,
  SELL_OUT = 39,
  SELL_OUT_POINT_RANGE = 40,
  STEPS = 41,
  STEPS_CONTENT = 42,
  SELL_IN = 43,
  SELL_IN_PRODUCT = 44,
  SELL_IN_POINTS_RANGE = 45,
  VIDEO_SECTION = 48,
  WYSIWYG = 49,
  PEARL = 50,
  GRID = 51,
  TEXT_LINK = 52,
  TEXT_LINK_ITEM = 53,
  EMAIL_WITH_DELAY = 54,
  EMAIL_WITH_DATE = 55,
  GENERIC_BUTTON = 56,
  WYSIWYG_CHALLENGE = 57,
  WYSWYG_PIN = 57,
  TEXT_DURATION = 58,
  INCLUDED_ITEMS_LIST = 59,
  INCLUDED_ITEM = 60,
  CHECKOUT_LOTTERY = 61,
  DOCUMENT = 62,
  ARN_SURVEY = 63,
  ARN_SURVEY_TOKEN = 64,
  BEAUTY_GUIDE = 65,
  HIDDEN = 66,
  CHECKOUT_DONATION = 67,
  CHECKOUT_DONATION_POINTS = 68,
  CHECKOUT_SUPPLEMENTARY_PRODUCT = 69,
  QUIZ_DERMO = 72,
  QUIZ_DERMO_PARTICIPATION = 74,
  FORMATION_CERTIFICATE = 73,
  CHECKOUT_BOOKING_TIMIFY = 75,
  LEGAL_CHECK = 76,
  CERTIFIED_COURSE = 77,
  EXTERNAL_VALIDATOR = 78
}

export enum NotificationStatusEnum {
  PENDING = '0',
  SENT = '1',
  ERROR = '2',
}

export const NotificationStatusString = ['Pending', 'Sent', 'Error'];

export const isNotification = (resource: IResource) => {
  return (
    resource.idResourceType?.idResourceType === ResourceTypeEnum.NOTIFICATION
  );
};
