import { IComponentBehaviour, IBehaviours } from './formInterfaces';
import { IRow, IRecord } from '../app/AppInterfaces';

const checkOperands = (
  fieldValue: string | number,
  behaviourValue: string | number,
  operand: string,
) => {
  if (fieldValue === undefined || fieldValue === null) return false;
  switch (operand) {
    case 'like':
      if (fieldValue !== behaviourValue) return false;
      break;
    case 'greater':
      if (fieldValue <= behaviourValue) return false;
      break;
    case 'less':
      if (fieldValue >= behaviourValue) return false;
      break;
    case 'greater|equal':
      if (fieldValue > behaviourValue) return false;
      break;
    case 'less|equal':
      if (fieldValue < behaviourValue) return false;
      break;
    default:
      break;
  }
  return true;
};

export const checkBehaviour = (
  behaviour: IComponentBehaviour,
  values: IRecord,
) => {
  if (!values) return false;
  else if (!values.hasOwnProperty(`${behaviour.key}`)) {
    if (!behaviour.value && behaviour.value !== '') return true; //    if (!behaviour.value) return true;
    return false;
  } else {
    return checkOperands(
      values[behaviour.key]?.toString(),
      behaviour.value!,
      behaviour.operand!,
    );
  }
};

export const checkElementBehaviours = <T extends IBehaviours, U>(
  element: T,
  values: IRow,
): T => {
  let throwBehaviours: {
    visible?: boolean;
    disabled?: boolean;
    mandatory?: boolean;
  } = {};
  let isGroupVisible;
  let isGroupDisabled;
  let isGroupMandatory;
  if (element.hasOwnProperty('behaviours')) {
    element.behaviours!.forEach((behaviourGroups) => {
      let behavioursComponent = {
        visible: true,
        disabled: true,
        mandatory: true,
      };
      behaviourGroups.forEach((behaviour) => {
        if (behaviour.type === 'visible') {
          if (!checkBehaviour(behaviour, values))
            behavioursComponent.visible = false;
        }
        if (behaviour.type === 'disabled') {
          if (!checkBehaviour(behaviour, values))
            behavioursComponent.disabled = false;
        }
        if (behaviour.type === 'mandatory') {
          if (!checkBehaviour(behaviour, values))
            behavioursComponent.mandatory = false;
        }
      });
      if (behavioursComponent['visible']) isGroupVisible = true;
      if (behavioursComponent['disabled']) isGroupDisabled = true;
      if (behavioursComponent['mandatory']) isGroupDisabled = true;
    });
    throwBehaviours.visible = isGroupVisible ? true : false;
    throwBehaviours.disabled = isGroupDisabled ? true : false;
    throwBehaviours.mandatory = isGroupMandatory ? true : false;
  }
  for (let key in throwBehaviours) {
    if (key === 'visible' && element.hasOwnProperty('initialVisibility')) {
      if (throwBehaviours[key]) element.visible = !element.initialVisibility;
      else element.visible = element.initialVisibility;
    } else if (
      key === 'disabled' &&
      element.hasOwnProperty('initialDisabled')
    ) {
      if (throwBehaviours[key]) element.disabled = !element.initialDisabled;
      else element.disabled = element.initialDisabled;
    }
  }
  return element;
};

interface IElementBehaviour {}

export const checkElementsBehaviours = <T>(
  elements: T[],
  values: IRow,
): T[] => {
  return elements.map((element) => checkElementBehaviours(element, values));
};

/**
 *
 *
 * fieldsConfig: [ {
 *  index:
 *  title
 *  subgrRR: [ 0 ]
 *
 * }]
 */
