export enum ChallengeTypeEnum {
  FORMATION = 1,
  TALK = 2,
  CAMPAIGN = 3,
  ANNUAL_CAMPAIGN = 4,
  EXPERIENCE = 5,
  PEARLS = 6,
  CONTEST = 7,
  DONATION = 8,
  CONGRESS = 9,
  FORMATION_CON = 10,
  CERTIFIED_COURSE = 11
}

export enum ChallengeRoleTypeEnum {
  FRIEND = 1,
  LOVER = 2,
  DERMO = 3,
}

export enum WebeatChallengeRoleTypeEnum{
  CONSUMER = 1,
  DERMO = 2,
}

export enum ResourceBehavior {
  DISABLED = 1,
  VISIBLE = 2,
}

export enum FrontOfficeChallengePageEnum {
  FORMATION = 'training',
  TALK = 'talk',
  CAMPAIGN = 'campaign',
  ANNUAL_CAMPAIGN = 'campaign/annual',
  PEARLS = 'pearl',
  CONGRESS = 'congress'
}
