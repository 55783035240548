import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import * as api from '../api';
import apiPaths from '../apiPaths';
import { getFrontUrl } from '../utils';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { IRecord } from '../app/AppInterfaces';
import {
  ComponentModal,
  CustomDashboard,
  getUserSpecialty,
  getUserType,
  UsersWithoutCenterModal,
} from '../isdinCustomDashboards';
import { ReducersState } from '../reducers';
import { isConPlatform } from '../utils';

interface IExtraFields extends ColumnProps<IRecord> {
  type?: string;
}

const IsdinUsersDashboard = (props: any) => {
  const { formatMessage } = useIntl();

  function ImpersonateButton(selectedRow: IRecord, loading: boolean) {
    const FRONT_URL = getFrontUrl(props.currentPlatform, props.platforms);
    const handleImpersonate = async (selectedRow: IRecord) => {
      if (selectedRow?.userID) {
        const userToken = await api.postDataCallById({
          dataPath: apiPaths.CALL.IMPERSONATE,
          id: selectedRow.userID,
          data: {},
          callConfig: {},
        });
        if (
          'status' in userToken &&
          userToken.status === 200 &&
          userToken.data &&
          userToken.data.accessToken
        ) {
          const win = window.open(
            `${FRONT_URL}impersonate?token=${userToken.data.accessToken}`,
            '_blank',
          );
          if (win) win.focus();
        }
      }
    };

    return (
      <Button
        key="impersonate_button"
        style={{ marginRight: '20px' }}
        onClick={() => handleImpersonate(selectedRow)}
        disabled={isEmpty(selectedRow)}
        loading={loading}
        type="primary">
        {formatMessage({ id: 'custom-buttons.impersonate' })}
      </Button>
    );
  }

  function DisplayUserInfo(selectedRow: IRecord, loading: boolean) {
    const [modalVisible, setModalVisible] = useState(false);

    const handleShowUserInfo = async () => {
      setModalVisible(true);
    };

    const extraFields: IExtraFields[] = [
      {
        title: 'Magento ID',
        dataIndex: 'customerId',
      },
      {
        title: 'User Center',
        dataIndex: 'idCurrentCenter',
        type: 'select',
      },
    ];

    return (
      <Fragment key="user-info_button">
        <Button
          loading={loading}
          style={{ marginRight: '20px' }}
          onClick={() => handleShowUserInfo()}
          disabled={isEmpty(selectedRow)}
          type="primary">
          {formatMessage({ id: 'custom-buttons.info-user' })}
        </Button>
        <ComponentModal
          {...{
            columns: isConPlatform() ? conColumns : pinColumns,
            extraFields,
            modalVisible,
            selectedRow,
            setModalVisible,
            title: 'User info',
            reloadData: async () => {},
          }}
        />
      </Fragment>
    );
  }

  function DisplayUsersWithoutCenter(selectedRow: IRecord, loading: boolean) {
    const [modalVisible, setModalVisible] = useState(false);

    const handleShowUsers = async () => {
      setModalVisible(true);
    };

    return (
      <Fragment key="no-center_button">
        <div className="no-center-button">
          <Button
            loading={loading}
            onClick={() => handleShowUsers()}
            type="primary">
            {formatMessage({ id: 'custom-buttons.no-center' })}
          </Button>
        </div>
        <UsersWithoutCenterModal
          {...{ modalVisible, selectedRow, setModalVisible }}
        />
      </Fragment>
    );
  }

  const pinColumns: ColumnProps<IRecord>[] = [
    {
      title: 'Name',
      dataIndex: 'userName',
      defaultSortOrder: 'descend',
      sorter: false,
    },
    {
      title: 'Mail',
      dataIndex: 'userMail',
      sorter: false,
    },
    {
      title: 'Specialty',
      dataIndex: 'userSpecialty',
      key: 'specialty',
      render: (userSpecialty: string) =>
        getUserSpecialty(userSpecialty, formatMessage),
    },
    {
      title: 'User type',
      dataIndex: 'userSpecialty',
      key: 'userType',
      render: (userSpecialty: string) => getUserType(userSpecialty),
    },
    //TODO ADD PHONE...
    {
      title: 'NPI',
      dataIndex: 'NPI',
      sorter: false,
    },
    {
      title: 'ID Saleforce',
      dataIndex: 'saleforceID',
    },
    {
      title: 'ID Love ISDIN',
      dataIndex: 'userID',
    },
  ];

  const conColumns: ColumnProps<IRecord>[] = [
    {
      title: 'Name',
      dataIndex: 'userName',
      defaultSortOrder: 'descend',
      sorter: false,
    },
    {
      title: 'Mail',
      dataIndex: 'userMail',
      sorter: false,
    },
    {
      title: 'Birth date',
      dataIndex: 'userBirthdate',
    },
    {
      title: 'Phone',
      dataIndex: 'userPhone',
    },
    {
      title: 'ID Pharmacy',
      dataIndex: 'sapCustomerId',
    },
    {
      title: 'ID Saleforce',
      dataIndex: 'saleforceID',
    },
    {
      title: 'ID Love ISDIN',
      dataIndex: 'userID',
    },
  ];

  return (
    <CustomDashboard
      searchPlaceHolder={
        isConPlatform()
          ? 'Introduce user mail...'
          : 'Select filter to search...'
      }
      entityKey={isConPlatform() ? 'consumers' : 'customers'}
      intl={props.intl}
      path={apiPaths.CALL.USERS_BULK}
      columns={isConPlatform() ? conColumns : pinColumns}
      rowKey="saleforceID"
      searchField="userEmail"
      customButtons={[
        ImpersonateButton,
        DisplayUserInfo,
        DisplayUsersWithoutCenter,
      ]}
      isPinEmbraceUsers={!isConPlatform()}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platforms: state.app.platforms,
  };
};
export default connect(mapStateToProps, null)(IsdinUsersDashboard);
