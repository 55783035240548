import { IResource, IResourceDetail } from '../ChallengeInterfaces';
import { ResourceTypeEnum } from '../Enums';
import { DetailsTypesEnum, ResourceTypeDetailEnum } from '../Enums';

export const blankShoppingGroup = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //TITLE SHOPPING GROUP
    idResource: -1,
    name: 'Shopping cart group title to be changed...',
    idResourceType: {
      idResourceType: 27,
      name: 'SHOPPING CART GROUP',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // GROUP MIN
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: 70,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SHOPPING CART GROUP MIN ITEMS',
        },
      },
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: 69,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SHOPPING CART GROUP MAX ITEMS',
        },
      },
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: 68,
          mandatory: true,
          type: DetailsTypesEnum.TEXT_AREA,
          value: 'SHOPPING CART GROUP DESCRIPTION',
        },
      },
    ],
    resourceList: [],
  };
};

export const blankQuestion = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //TITLE QUESTION
    idResource: -1,
    name: 'Question...',
    idResourceType: {
      idResourceType: ResourceTypeEnum.QUESTION,
      name: 'QUESTION',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      {
        idResourceD: -1,
        value: null,
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.QUESTION_TYPE,
          mandatory: true,
          type: DetailsTypesEnum.QUESTION_TYPE,
          value: 'question_type',
        },
      },
      {
        idResourceD: -1,
        value: null,
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.QUESTION_DESCRIPTION,
          mandatory: false,
          type: DetailsTypesEnum.WYSWYG,
          value: 'question_description',
        },
      },
      {
        idResourceD: -1,
        value: 'false',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.QUESTION_SHOW_STATISTICS,
          mandatory: false,
          type: DetailsTypesEnum.BOOLEAN,
          value: 'question_show_statistics',
        },
      },
      //VIDEO QUESTION
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.QUESTION_VIDEO_URL,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'VIDEO URL',
        },
      },
      //IMAGE QUESTION
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 3,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.QUESTION_IMAGE_URL,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'IMAGE URL',
        },
      },
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 4,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.QUESTION_JUSTIFICATION,
          mandatory: false,
          type: DetailsTypesEnum.WYSWYG,
          value: '',
        },
      },
    ],
    resourceList: [
      {
        //TITLE ANSWER
        idResource: -1,
        name: 'Answer...',
        idResourceType: {
          idResourceType: 6,
          name: 'ANSWER',
        },
        status: true,
        order: 0,
        activate: false,
        resourceDetailList: [
          //BOOLEAN
          {
            idResourceD: -1,
            value: 'false',
            status: true,
            order: 0,
            idResourceTypeD: {
              idResourceTypeD: 13,
              mandatory: true,
              type: DetailsTypesEnum.BOOLEAN,
              value: 'CORRECT',
            },
          },
        ],
        resourceList: [],
      },
    ],
  };
};

export const blankAnswer = (
  _quiz: IResource,
  questionArrayPosition: number,
): IResource => {
  return {
    //TITLE ANSWER
    idResource: -1,
    name: 'Answer...',
    idResourceType: {
      idResourceType: 6,
      name: 'ANSWER',
    },
    status: true,
    order: _quiz.resourceList![questionArrayPosition].resourceList!.length,
    activate: false,
    resourceDetailList: [
      //BOOLEAN
      {
        idResourceD: -1,
        value: 'false',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: 13,
          mandatory: true,
          type: DetailsTypesEnum.BOOLEAN,
          value: 'CORRECT',
        },
      },
    ],
    resourceList: [],
  };
};

// TODO FIX

export const blankProduct = (
  cart: IResource,
  groupIndex: number,
): IResource => {
  return {
    //TITLE ANSWER
    idResource: -1,
    name: '',
    idResourceType: {
      idResourceType: 28,
      name: 'SHOPPING CART PRODUCT',
    },
    status: true,
    order: cart.resourceList![groupIndex].resourceList!.length,
    activate: false,
    resourceDetailList: [
      //PRODUCT ID
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SHOPPING_CART_PRODUCT_ID,
          mandatory: true,
          type: DetailsTypesEnum.PRODUCT,
          value: 'SHOPPING CART PRODUCT ID',
        },
      },
      //PRODUCT MAX
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.SHOPPING_CART_PRODUCT_MAX_ITEMS,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SHOPPING CART PRODUCT MAX ITEMS',
        },
      },
      //PRODUCT MIN
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.SHOPPING_CART_PRODUCT_MIN_ITEMS,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SHOPPING CART PRODUCT MIN ITEMS',
        },
      },
      //PRODUCT DEFAULT
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 3,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.SHOPPING_CART_PRODUCT_DEFAULT_ITEMS,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SHOPPING CART PRODUCT DEFAULT ITEMS',
        },
      },
    ],
    resourceList: [],
  };
};

export const blankVideo = (resource: IResource): IResource => {
  return {
    //TITLE QUESTION
    idResource: -1,
    name: 'Video name',
    idResourceType: {
      idResourceType: 21,
      name: 'GALLERY_VIDEO',
    },
    indResource: resource.idResource,
    status: true,
    order: resource.resourceList!.length,
    activate: false,
    resourceDetailList: [
      //IMAGE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: 35,
          mandatory: true,
          type: DetailsTypesEnum.URL,
          value: 'VIDEO URL',
        },
      },
    ],
  };
};

export const blankImage = (_quiz: IResource): IResource => {
  return {
    //TITLE QUESTION
    idResource: -1,
    name: 'Image name',
    idResourceType: {
      idResourceType: 20,
      name: 'GALLERY_IMAGE',
    },
    indResource: _quiz.idResource,
    status: true,
    order: _quiz.resourceList!.length,
    activate: false,
    resourceDetailList: [
      //IMAGE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: 34,
          mandatory: true,
          type: DetailsTypesEnum.UPLOAD,
          value: 'IMAGE URL',
        },
      },
    ],
  };
};

// Type. Selectable "materialType" of list of values that will be the same as marketing materials. In marketing materials the selectable are the fields that are load:
export const blankMaterial = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //TITLE MATERIAL
    idResource: -1,
    name: 'Material Name',
    idResourceType: {
      idResourceType: 23,
      name: 'MATERIAL',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // ORDER
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: 53,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'MATERIAL_ORDER',
        },
      },
      // DESCRIPTION
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: 54,
          mandatory: false,
          type: DetailsTypesEnum.TEXT_AREA,
          value: 'MATERIAL_DESCRIPTION',
        },
      },
      // CONTENT
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: 55,
          mandatory: true,
          type: DetailsTypesEnum.UPLOAD,
          value: 'MATERIAL_CONTENT',
        },
      },
      // IMAGE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: 56,
          mandatory: false,
          type: DetailsTypesEnum.UPLOAD,
          value: 'MATERIAL_IMAGE',
        },
      },
      // MATERIAL TYPE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: 79,
          mandatory: true,
          type: DetailsTypesEnum.MATERIAL_TYPE,
          value: 'MATERIAL_TYPE',
        },
      },
    ],
    resourceList: [],
  };
};

export const blankSpeaker = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //SPEAKER NAME
    idResource: -1,
    name: 'Speaker Name',
    idResourceType: {
      idResourceType: 9,
      name: 'SPEAKER',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // JOB
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 16,
        idResourceTypeD: {
          idResourceTypeD: 28,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'SPEAKER_JOB',
        },
      },
      // PHONE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 17,
        idResourceTypeD: {
          idResourceTypeD: 48,
          mandatory: false,
          type: DetailsTypesEnum.TEXT,
          value: 'SPEAKER_PHONE',
        },
      },
      // EMAIL
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 18,
        idResourceTypeD: {
          idResourceTypeD: 47,
          mandatory: false,
          type: DetailsTypesEnum.TEXT,
          value: 'SPEAKER_EMAIL',
        },
      },
      // IMAGE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 19,
        idResourceTypeD: {
          idResourceTypeD: 46,
          mandatory: true,
          type: DetailsTypesEnum.IMAGE,
          value: 'SPEAKER_IMAGE',
        },
      },
    ],
    resourceList: [],
  };
};

export const blankLinkedChallenge = (idResource: number): IResourceDetail => {
  return {
    // CHALLENGE
    idResourceD: -1,
    value: '',
    status: true,
    order: 0,
    idResourceTypeD: {
      idResourceTypeD: ResourceTypeDetailEnum.LINKED_CHALLENGE_ID,
      mandatory: true,
      type: DetailsTypesEnum.CHALLENGE,
      value: 'LINKED_CHALLENGES_ID',
    },
  };
};

export const blankSellOutPoitnsRange = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //TITLE QUESTION
    idResource: -1,
    name: 'Points ranges of sellOut',
    idResourceType: {
      idResourceType: ResourceTypeEnum.SELL_OUT_POINT_RANGE,
      name: 'SELL_OUT_POINT_RANGE',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SELL_OUT_POINTS_RANGE_MAX,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'SELL_OUT_POINTS_RANGE_MAX',
        },
      },
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SELL_OUT_POINTS_RANGE_MIN,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'SELL_OUT_POINTS_RANGE_MIN',
        },
      },
    ],
  };
};

export const blankSellInImagesRange = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //TITLE
    idResource: -1,
    name: 'An image to determine a range of points',
    idResourceType: {
      idResourceType: ResourceTypeEnum.SELL_IN_POINTS_RANGE,
      name: 'SELL_IN_POINTS_RANGE',
    },

    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.SELL_IN_IMAGE_RANGES_MIN_QUANTITY,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'SELL_IN_IMAGE_RANGES_MIN_QUANTITY',
        },
      },
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SELL_IN_IMAGE_RANGES,
          mandatory: false,
          type: DetailsTypesEnum.IMAGE,
          value: 'SELL_IN_IMAGE_RANGES',
        },
      },
    ],
  };
};

export const blankSellInProduct = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //TITLE ANSWER
    idResource: -1,
    name: '',
    idResourceType: {
      idResourceType: ResourceTypeEnum.SELL_IN_PRODUCT,
      name: 'SELL_IN_PRODUCT',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      //PRODUCT ID
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SELL_IN_PRODUCT_ID,
          mandatory: true,
          type: DetailsTypesEnum.PRODUCT,
          value: 'SELL_IN_PRODUCT_ID',
        },
      },
      //PRODUCT MAX
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SELL_IN_PRODUCT_MAX_ITEMS,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SELL_IN_PRODUCT_MAX_ITEMS',
        },
      },
      //PRODUCT MIN
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SELL_IN_PRODUCT_MIN_ITEMS,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SELL_IN_PRODUCT_MIN_ITEMS',
        },
      },
      //PRODUCT DEFAULT
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 3,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.SELL_IN_PRODUCT_DEFAULT_ITEMS,
          mandatory: false,
          type: DetailsTypesEnum.NUMBER,
          value: 'SELL_IN_PRODUCT_DEFAULT_ITEMS',
        },
      },
    ],
  };
};

export const blankImageDetail = (): IResourceDetail => {
  return {
    // IMAGE
    idResourceD: -1,
    value: '',
    status: true,
    order: 0,
    idResourceTypeD: {
      idResourceTypeD: ResourceTypeDetailEnum.QUESTION_IMAGE_URL,
      mandatory: true,
      type: DetailsTypesEnum.IMAGE,
      value: 'IMAGE URL',
    },
  };
};

export const blankCheckoutProduct = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //PRODUCT NAME
    idResource: -1,
    name: 'Product name to be changed..',
    idResourceType: {
      idResourceType: ResourceTypeEnum.CHECKOUT_PRODUCT,
      name: 'CHECKOUT_PRODUCT',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // EAN
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.CHECKOUT_PRODUCT_EAN,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'CHECKOUT_PRODUCT_EAN',
        },
      },
      // IMAGE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.CHECKOUT_PRODUCT_IMAGE,
          mandatory: true,
          type: DetailsTypesEnum.IMAGE,
          value: 'CHECKOUT_PRODUCT_IMAGE',
        },
      },
      // ORIGINAL STOCK
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.CHECKOUT_PRODUCT_ORIGINAL_STOCK,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'CHECKOUT_PRODUCT_ORIGINAL_STOCK',
        },
      },
      // CURRENT STOCK
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 3,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.CHECKOUT_PRODUCT_CURRENT_STOCK,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'CHECKOUT_PRODUCT_CURRENT_STOCK',
        },
      },
      // TYPE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 4,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.CHECKOUT_PRODUCT_TYPE,
          mandatory: true,
          type: DetailsTypesEnum.CHECKOUT_PRODUCT_TYPE,
          value: 'CHECKOUT_PRODUCT_TYPE',
        },
      },
      // DESCRIPTION
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 4,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.CHECKOUT_PRODUCT_ITEM_DESCRIPTION,
          mandatory: true,
          type: DetailsTypesEnum.TEXT_AREA,
          value: 'CHECKOUT_PRODUCT_ITEM_DESCRIPTION',
        },
      },
    ],
    resourceList: [],
  };
};

export const blankCheckoutSupplementaryProduct = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //SUPPLEMENTARY PRODUCT NAME
    idResource: -1,
    name: 'Supplementary Product name to be changed..',
    idResourceType: {
      idResourceType: ResourceTypeEnum.CHECKOUT_SUPPLEMENTARY_PRODUCT,
      name: 'CHECKOUT_SUPPLEMENTARY_PRODUCT',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // MATERIAL ID/EAN
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.CHECKOUT_PRODUCT_EAN,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'CHECKOUT_SUPPLEMENTARY_PRODUCT_MATERIAL_ID/EAN',
        },
      },
      // UNITY NUMBER
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD:
            ResourceTypeDetailEnum.CHECKOUT_PRODUCT_ORIGINAL_STOCK,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'CHECKOUT_SUPPLEMENTARY_PRODUCT_UNITY_NUMBER',
        },
      }
    ],
    resourceList: [],
  }
}

export const blankStepsDetails = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //STEPS NAME
    idResource: -1,
    name: 'Step card item',
    idResourceType: {
      idResourceType: ResourceTypeEnum.STEPS_CONTENT,
      name: 'STEPS_CONTENT',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // STEPS_CARD_NAME
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.STEPS_CARD_NAME,
          mandatory: false,
          type: DetailsTypesEnum.TEXT,
          value: 'STEPS_CARD_NAME',
        },
      },
      // STEPS_CARD_IMAGE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.STEPS_CARD_IMAGE,
          mandatory: true,
          type: DetailsTypesEnum.IMAGE,
          value: 'STEPS_CARD_IMAGE',
        },
      },
      // STEPS_CARD_IMAGE_DESKTOP
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 3,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.STEPS_CARD_IMAGE_DESKTOP,
          mandatory: true,
          type: DetailsTypesEnum.IMAGE,
          value: 'STEPS_CARD_IMAGE_DESKTOP',
        },
      },
      // STEPS_CARD_CONTENT
      {
        idResourceD: -1,
        value: null,
        status: true,
        order: 4,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.STEPS_CARD_CONTENT,
          mandatory: true,
          type: DetailsTypesEnum.WYSWYG,
          value: 'STEPS_CARD_CONTENT',
        },
      },
      // STEPS_CARD_SHOW_NUMBERS
      {
        idResourceD: -1,
        value: 'true',
        status: true,
        order: 5,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.STEPS_CARD_SHOW_NUMBERS,
          mandatory: true,
          type: DetailsTypesEnum.BOOLEAN,
          value: 'STEPS_CARD_SHOW_NUMBERS',
        },
      },
      // STEPS_CARD_LINK
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 6,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.STEPS_CARD_LINK,
          mandatory: false,
          type: DetailsTypesEnum.TEXT,
          value: 'STEPS_CARD_LINK',
        },
      },
      // STEPS_CARD_TEXT_LINK
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 7,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.STEPS_CARD_TEXT_LINK,
          mandatory: false,
          type: DetailsTypesEnum.TEXT,
          value: 'STEPS_CARD_TEXT_LINK',
        },
      },
    ],
    resourceList: [],
  };
};
// TEXT_LINK
export const blankTextLinkDetails = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //STEPS NAME
    idResource: -1,
    name: 'TextLink card item',
    idResourceType: {
      idResourceType: ResourceTypeEnum.TEXT_LINK_ITEM,
      name: 'TEXT_LINK_ITEM',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // TEXTLINK_LINK
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.TEXT_LINK_LINK,
          mandatory: true,
          type: DetailsTypesEnum.UPLOAD,
          value: 'TEXT_LINK_LINK',
        },
      },
      // TEXTLINK_CARD_CONTENT
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.TEXT_LINK_TEXT,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'TEXT_LINK_TEXT',
        },
      },
    ],
    resourceList: [],
  };
};
// HIDDEN
export const blankHiddenDetails = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //HIDDEN NAME
    idResource: -1,
    name: 'Hidden card item',
    idResourceType: {
      idResourceType: ResourceTypeEnum.HIDDEN,
      name: 'HIDDEN',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // HIDDEN_ICON
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.HIDDEN_ICON,
          mandatory: true,
          type: DetailsTypesEnum.ICON_TYPE,
          value: 'HIDDEN_ICON',
        },
      },

      // HIDDEN_TITLE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.HIDDEN_TITLE,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'HIDDEN_TITLE',
        },
      },

      // HIDDEN_TEXT
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 3,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.HIDDEN_TEXT,
          mandatory: true,
          type: DetailsTypesEnum.TEXT_AREA,
          value: 'HIDDEN_TEXT',
        },
      },

      // HIDDEN_CTA_TEXT
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 6,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.HIDDEN_CTA_TEXT,
          mandatory: true,
          type: DetailsTypesEnum.TEXT,
          value: 'HIDDEN_CTA_TEXT',
        },
      },
      // HIDDEN_CTA_LINK
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 7,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.HIDDEN_CTA_LINK,
          mandatory: true,
          type: DetailsTypesEnum.URL,
          value: 'HIDDEN_CTA_LINK',
        },
      },
    ],
    resourceList: [],
  };
};

export const blankIncludedItem = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //INCLUDED ITEM
    idResource: -1,
    name: 'Included item',
    idResourceType: {
      idResourceType: ResourceTypeEnum.INCLUDED_ITEM,
      name: 'INCLUDED_ITEM',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
      // ITEM_ICON
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.INCLUDED_ITEM_ICON,
          mandatory: true,
          type: DetailsTypesEnum.ICON_TYPE,
          value: 'INCLUDED_ITEM_ICON',
        },
      },
    ],
    resourceList: [],
  };
};

export const blankEmailWithDelay = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    // TITLE
    idResource: -1,
    name: 'Automatic email with delay',
    idResourceType: {
      idResourceType: 54,
      name: 'EMAIL_WITH_DELAY',
    },
    indResource: idResource,
    status: true,
    order: resourceList!.length || 0,
    activate: false,
    resourceDetailList: [
      // ID_MAIL
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.EMAIL_WITH_DELAY_ID_MAIL,
          mandatory: true,
          type: DetailsTypesEnum.MAIL,
          value: 'EMAIL_WITH_DELAY_ID_MAIL',
        },
      },
      // DELAY HOUR
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.EMAIL_WITH_DELAY_HOUR,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'EMAIL_WITH_DELAY_HOUR',
        },
      },
      // DELAY DAY
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 3,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.EMAIL_WITH_DELAY_DAY,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'EMAIL_WITH_DELAY_DAY',
        },
      },
    ],
  };
};

export const blankEmailWithDate = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    // TITLE
    idResource: -1,
    name: 'Automatic email with date',
    idResourceType: {
      idResourceType: 55,
      name: 'EMAIL_WITH_DATE',
    },
    indResource: idResource,
    status: true,
    order: resourceList!.length || 0,
    activate: false,
    resourceDetailList: [
      // ID_MAIL
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 1,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.EMAIL_WITH_DATE_ID_MAIL,
          mandatory: true,
          type: DetailsTypesEnum.MAIL,
          value: 'EMAIL_WITH_DATE_ID_MAIL',
        },
      },
      // DATE
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 2,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.EMAIL_WITH_DATE_DATE,
          mandatory: true,
          type: DetailsTypesEnum.DATE,
          value: 'EMAIL_WITH_DATE_DATE',
        },
      },
    ],
  };
};

export const blankCheckoutDonation = (
  idResource: number,
  resourceList?: IResource[],
): IResource => {
  return {
    //CHECKOUT_DONATION_POINTS
    idResource: -1,
    name: '',
    idResourceType: {
      idResourceType: ResourceTypeEnum.CHECKOUT_DONATION_POINTS,
      name: 'CHECKOUT_DONATION_POINTS',
    },
    indResource: idResource,
    status: true,
    order: resourceList?.length || 0,
    activate: false,
    resourceDetailList: [
    
      {
        idResourceD: -1,
        value: '',
        status: true,
        order: 0,
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.CHECKOUT_DONATION_PRODUCT,
          mandatory: true,
          type: DetailsTypesEnum.NUMBER,
          value: 'CHECKOUT_DONATION_PRODUCT',
        },
      },
    ],
    resourceList: [],
  };
};