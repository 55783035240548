import { searchTypes } from './searchTypes';
import { Dispatch } from 'redux';
import { GlobalSearch } from './searchReducer';
import { IGroupField } from '../formInterfaces';
import { IRow, IRecord } from '../../app/AppInterfaces';

export interface SetSearchInitialState {
  type: searchTypes.SET_INITIAL_SEARCH_STATE;
  payload: { componentId: string };
}

export const setSearchInitialState = ({
  componentId
}: {
  componentId: string;
}) => (dispatch: Dispatch<SetSearchInitialState>) => {
  dispatch({
    type: searchTypes.SET_INITIAL_SEARCH_STATE,
    payload: { componentId }
  });
};

interface SetFieldsConfig {
  type: searchTypes.SEARCH_SET_FIELDS_CONFIG;
  payload: {
    componentId: string;
    newFieldsConfig: IGroupField[];
    resetFormTrigger: boolean;
  };
}

export const setFieldsConfig = (
  componentId: string,
  newFieldsConfig: IGroupField[]
) => (dispatch: Dispatch<SetFieldsConfig>) => {
  dispatch({
    type: searchTypes.SEARCH_SET_FIELDS_CONFIG,
    payload: { componentId, newFieldsConfig, resetFormTrigger: true }
  });
};

interface SetInitialState {
  type: searchTypes.SEARCH_INITIAL_STATE;
  payload: {
    componentId: string;
    targetId: string;
    fieldsConfig: IGroupField[];
    globalSearchField: GlobalSearch;
    values: IRecord;
    resetFormTrigger: boolean;
    formHasChanged: boolean;
  };
}

export const setInitialState = ({
  componentId,
  targetId,
  fieldsConfig,
  globalSearchField,
  values,
  resetFormTrigger,
  formHasChanged
}: {
  componentId: string;
  targetId: string;
  fieldsConfig: IGroupField[];
  globalSearchField: GlobalSearch;
  values: IRow;
  resetFormTrigger: boolean;
  formHasChanged: boolean;
}) => (dispatch: Dispatch<SetInitialState>) => {
  dispatch({
    type: searchTypes.SEARCH_INITIAL_STATE,
    payload: {
      componentId,
      targetId,
      fieldsConfig,
      globalSearchField,
      values,
      resetFormTrigger,
      formHasChanged
    }
  });
};

interface setFormData {
  type: searchTypes.SEARCH_SET_VALUES;
  payload: { componentId: string; values: IRow };
}

export const setFormData = ({
  componentId,
  values
}: {
  componentId: string;
  values: IRow;
}) => (dispatch: Dispatch<setFormData>) => {
  dispatch({
    type: searchTypes.SEARCH_SET_VALUES,
    payload: { componentId, values }
  });
};

interface ResetSearchForm {
  type: searchTypes.SEARCH_RESET_FORM;
  payload: {
    componentId: string;
    values: IRow;
    fieldsConfig: IGroupField[];
    resetFormTrigger: boolean;
  };
}

export const resetSearchForm = ({
  componentId,
  fieldsConfig,
  values
}: {
  componentId: string;
  fieldsConfig: IGroupField[];
  values: IRow;
}) => (dispatch: Dispatch<ResetSearchForm>) => {
  dispatch({
    type: searchTypes.SEARCH_RESET_FORM,
    payload: { componentId, values, fieldsConfig, resetFormTrigger: true }
  });
};

interface SetResetFormTrigger {
  type: searchTypes.SEARCH_CLEAN_RESET_COMPONENT;
  payload: { componentId: string; resetFormTrigger: boolean };
}

export const setResetFormTrigger = ({
  componentId
}: {
  componentId: string;
}) => (dispatch: Dispatch<SetResetFormTrigger>) => {
  dispatch({
    type: searchTypes.SEARCH_CLEAN_RESET_COMPONENT,
    payload: { componentId, resetFormTrigger: false }
  });
};

interface ResetSearchComponent {
  type: searchTypes.SEARCH_RESET_COMPONENT;
  payload: { componentId: string };
}

export const resetSearchComponent = ({
  componentId
}: {
  componentId: string;
}) => (dispatch: Dispatch<ResetSearchComponent>) => {
  dispatch({
    type: searchTypes.SEARCH_RESET_COMPONENT,
    payload: { componentId }
  });
};

interface SetTriggerGetTableData {
  type: searchTypes.SEARCH_SET_INITIAL_DATA;
  payload: { componentId: string; value: boolean };
}

export const setTriggerGetTableData = ({
  componentId,
  value
}: {
  componentId: string;
  value: boolean;
}) => (dispatch: Dispatch<SetTriggerGetTableData>) => {
  dispatch({
    type: searchTypes.SEARCH_SET_INITIAL_DATA,
    payload: { componentId, value }
  });
};

interface SetFormHasChanged {
  type: searchTypes.SEARCH_SET_FORM_HAS_CHANGED;
  payload: { componentId: string; formHasChanged: boolean };
}
export const setFormHasChanged = ({
  componentId,
  formHasChanged
}: {
  componentId: string;
  formHasChanged: boolean;
}) => (dispatch: Dispatch<SetFormHasChanged>) => {
  dispatch({
    type: searchTypes.SEARCH_SET_FORM_HAS_CHANGED,
    payload: { componentId, formHasChanged }
  });
};

interface SetSelectedTabSearch {
  type: searchTypes.SEARCH_CHANGE_TAB;
  payload: { componentId: string; selectedTab: string };
}
export const setSelectedTabSearch = ({
  componentId,
  selectedTab
}: {
  componentId: string;
  selectedTab: string;
}) => (dispatch: Dispatch<SetSelectedTabSearch>) => {
  dispatch({
    type: searchTypes.SEARCH_CHANGE_TAB,
    payload: { componentId, selectedTab }
  });
};

interface SetFilterVisibility {
  type: searchTypes.SEARCH_FILTER_VISIBLE;
  payload: { componentId: string; filterVisible: boolean };
}

export const setFilterVisibility = ({
  componentId,
  filterVisible
}: {
  componentId: string;
  filterVisible: boolean;
}) => (dispatch: Dispatch<SetFilterVisibility>) => {
  dispatch({
    type: searchTypes.SEARCH_FILTER_VISIBLE,
    payload: { componentId, filterVisible }
  });
};

export type SearchActionTypes =
  | ResetSearchForm
  | SetResetFormTrigger
  | ResetSearchComponent
  | SetTriggerGetTableData
  | SetSelectedTabSearch
  | SetFilterVisibility
  | SetFormHasChanged
  | setFormData
  | SetFieldsConfig
  | SetSearchInitialState
  | SetInitialState;
