import React, { useState, FC } from 'react';
import { Form, Button, Modal, Alert, Input, Divider, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { isEmpty } from 'lodash';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { isTableProps } from '../../utils/propsUtils';


export default function PreviewMailButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps
>(parentProps: T) {
  const [modalVisible, setModalVisible] = useState(false);
  const [htmlTemplate, setHtmlTemplate] = useState('');

  const handleSetModalVisible = async (visible: boolean) => {
    const response = await api.getDataCallById({
      dataPath: apiPaths.CALL.MAIL_PREVIEW,
      registerId: parentProps.props.selectedRow.idMail,
      callConfig: {}
    });

    if ((response) && (response.status === 200)) {
      setHtmlTemplate(response.data);

      setModalVisible(visible);
    }
  };

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow } = tableProps.props;

    const FormCustom: FC<FormComponentProps> = ({ form }) => {
      const { getFieldDecorator, validateFields } = form;
    
      const handleSubmit = () => {
        validateFields(async (err: any, values: any) => {
          if (!err) {
            const emailData = {
              subject: `Test: ${selectedRow.name}`,
              toAddresses: [values.email],
              content: htmlTemplate,
            }
        
            const response = await api.postDataCall({
              dataPath: apiPaths.CALL.MAIL_SEND_PREVIEW,
              data: emailData,
              callConfig: {}
            });

            if ((response) && (response.status === 200)) {
              setModalVisible(false);
            }
          }
        });
      };
    
      return (
        <Form className="custom-form">
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
            })(<Input.Search
              placeholder = "email"
              enterButton="Send Email"
              allowClear
              onSearch={handleSubmit}
            />)}
          </Form.Item>
        </Form>
      );
    }
  
    const FormWithProps = Form.create<FormComponentProps>()(FormCustom);

    const previewModal = (
      <Modal
        visible={modalVisible}
        className="previewModal"
        onCancel={() => handleSetModalVisible(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={'75%'}>
        <Row className="isdin-custom-modal__container">
          <Row className="isdin-custom-modal__title">{selectedRow.name}</Row>
          <Row>
            <Alert
              style={{ marginBottom: 16 }}
              message="Warning"
              description="The appearance of the template may change depending on the email client used. Test it by sending an email to a specific client."
              type="warning"
              showIcon
              closable
            />
          </Row>
          <FormWithProps />
          <Divider></Divider>
          <Row>
            <div dangerouslySetInnerHTML={{ __html: htmlTemplate}}/>
          </Row>
        </Row> 
      </Modal>
    );

    return (
      <>
        {previewModal}
        <Button
          onClick={() => handleSetModalVisible(true)}
          disabled={
            isEmpty(selectedRow)
          }
          type="primary">
          Preview
        </Button>
      </>
    );
  }

  return <></>;
};


