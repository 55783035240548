import React, { FC } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import '../Challenge.css';
import { Select, Row, Button, Table } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props extends FormComponentProps {
  isLoading: boolean;
  users: null | string[];
  setUsers: React.Dispatch<React.SetStateAction<null | string[]>>;
  validateUsers: (form: WrappedFormUtils<any>) => Promise<void>;
  actionType: boolean;
  setActionType: React.Dispatch<React.SetStateAction<boolean>>;
  restrictionsHaveChanged: boolean;
  setRestrictionsHaveChanged: React.Dispatch<React.SetStateAction<boolean>>;
  newUsers: string[];
  setNewUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

export const checkEmailValue = (originalValue: string): string | null => {
  const _value = originalValue.trim();
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isCorrect = re.test(String(_value).toLowerCase());
  return isCorrect ? _value : null;
};

export const addEmailToArray = (array: string[], value: string) => {
  const newArray = [...array];
  if (checkEmailValue(value) && !newArray.includes(checkEmailValue(value)!))
    newArray.push(checkEmailValue(value)!);

  return newArray;
};

const AdminVisibilityForm: FC<Props> = ({
  actionType,
  isLoading,
  form,
  restrictionsHaveChanged,
  users,
  newUsers,
  setActionType,
  setRestrictionsHaveChanged,
  setUsers,
  validateUsers,
  setNewUsers
}) => {
  const handleChange = (values: string[]) => {
    // let selectUsers: string[] = newUsers.slice();
    let selectUsers: string[] = [];
    values.forEach(_value => {
      if (_value.indexOf(',') >= 0) {
        const newUsers = _value.split(',');
        newUsers.forEach(_user => {
          selectUsers = [...addEmailToArray(selectUsers, _user)];
        });
      } else if (
        checkEmailValue(_value) &&
        !selectUsers.includes(checkEmailValue(_value)!)
      ) {
        selectUsers.push(checkEmailValue(_value)!);
      }
    });

    setNewUsers(selectUsers);

    form.setFieldsValue({ note: [...selectUsers] });
    form.resetFields();

    setRestrictionsHaveChanged(true);
  };

  const addNewValues = (value: string, options: any) => {
    let selectUsers: string[] = newUsers.slice();

    if (value) {
      if (value.indexOf(',') >= 0) {
        const newUsers = value.split(',');
        newUsers.forEach(_user => {
          if (
            checkEmailValue(_user) &&
            !selectUsers.includes(checkEmailValue(_user)!)
          )
            selectUsers.push(checkEmailValue(_user)!);
        });
      } else if (
        checkEmailValue(value) &&
        !selectUsers.includes(value.trim())
      ) {
        selectUsers.push(checkEmailValue(value)!);
      }

      setNewUsers(selectUsers);

      form.setFieldsValue({ note: [...selectUsers] });

      setRestrictionsHaveChanged(true);
    }
  };

  const handleRemoveUser = (record: any) => {
    const newUsers = [...users!];
    newUsers.forEach((user, i) => {
      if (user === record.email) newUsers.splice(i, 1);
    });
    const selectUsers: string[] = form.getFieldValue('note');
    selectUsers.forEach((user: string, i: number) => {
      if (user === record.email) selectUsers.splice(i, 1);
    });
    form.setFieldsValue({ note: selectUsers });
    setRestrictionsHaveChanged(true);
    setUsers(newUsers);
  };

  const handleChangeAction = (value: boolean) => {
    setActionType(value);
    setRestrictionsHaveChanged(true);
  };

  const getDataSource = (users: null | string[]) => {
    if (users === null) return [];
    return users.map((_user: string, i: number) => {
      return { key: i, email: _user };
    });
  };

  const getColumns = [
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: ' ',
      key: 'action',
      render: (text: string, record: any) => (
        <Row type="flex" justify="end">
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => handleRemoveUser(record)}>
            Remove
          </Button>
        </Row>
      )
    }
  ];

  const renderOptions =
    newUsers &&
    newUsers.map(_user => <Select.Option key={_user}>{_user}</Select.Option>);

  return (
    <Form className="visibilityForm">
      <Row className="visibilityForm__select">
        {form.getFieldDecorator('note', { initialValue: newUsers })(
          <Select
            mode="tags"
            style={{ width: '100%' }}
            onChange={handleChange}
            onSelect={(value: string, options: any) =>
              addNewValues(value, options)
            }>
            {renderOptions}
          </Select>
        )}
      </Row>
      <Row
        className="visibilityForm__title"
        type="flex"
        justify="space-between">
        <div>Users List</div>
        {form.getFieldDecorator('actionType', {
          initialValue: actionType ? actionType.toString() : 'false'
        })(
          <Select
            style={{ maxWidth: '300px' }}
            onChange={(value: string, options: any) =>
              handleChangeAction(value === 'true' ? true : false)
            }>
            <Select.Option key={'true'} value={'true'}>
              White list: Only show on listed users
            </Select.Option>
            <Select.Option key={'false'} value={'false'}>
              Black list: Don't show on listed users
            </Select.Option>
          </Select>
        )}
      </Row>
      <Row type="flex" justify="center">
        <Table
          className="visibilityForm__table"
          dataSource={getDataSource(users)}
          columns={getColumns}
          size="small"
        />
      </Row>
      <Row
        type="flex"
        justify="start"
        className={
          users && users.length
            ? 'visibilityForm__button'
            : 'visibilityForm__footer'
        }>
        <Button
          disabled={!restrictionsHaveChanged}
          type="primary"
          loading={isLoading}
          onClick={() => validateUsers(form)}>
          Save
        </Button>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({ name: 'challenge_visibility_form' })(
  AdminVisibilityForm
);
