import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import config from '../../config';
import { IPageDetail, PageComponentEnum, PageDetailEnum } from '../shared';
import { Col } from 'antd';
import { inRange } from 'lodash';

const IMAGE_POSITION_FULL = '0';
const { BANNER, CAROUSEL, CHALLENGE_TYPE, LINKED_IMAGE } = PageComponentEnum;
const { IMAGE } = PageDetailEnum;
const {
  BANNER_HEIGHT,
  BANNER_WIDTH,
  BANNER_HEIGHT_MOBILE,
  BANNER_WIDTH_MOBILE,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  IMAGE_HEIGHT_MOBILE,
  IMAGE_WIDTH_MOBILE,
  BACKGROUND_IMAGE_HEIGHT,
  BACKGROUND_IMAGE_WIDTH,
  BACKGROUND_IMAGE_HEIGHT_MOBILE,
  BACKGROUND_IMAGE_WIDTH_MOBILE,
  PRODUCT_HEIGHT,
  PRODUCT_WIDTH,
} = config.STATIC_PAGES;

export const setFileDimensions = ({
  componentType,
  desktop,
  position,
  type,
}: {
  componentType: number;
  desktop: boolean;
  position: string;
  type: number;
}) => {
  const filterNumber = CHALLENGE_TYPE * 10;

  if (inRange(componentType, filterNumber, (filterNumber + 10)))
      componentType = CHALLENGE_TYPE;

  switch (true) {
    case [BANNER, CAROUSEL].includes(componentType) && type === IMAGE:
      return desktop
        ? { width: BANNER_WIDTH, height: BANNER_HEIGHT }
        : { width: BANNER_WIDTH_MOBILE, height: BANNER_HEIGHT_MOBILE };

    case componentType === LINKED_IMAGE && type === IMAGE && desktop:
      return position.toString() === IMAGE_POSITION_FULL
        ? { width: BACKGROUND_IMAGE_WIDTH, height: BACKGROUND_IMAGE_HEIGHT }
        : { width: IMAGE_WIDTH, height: IMAGE_HEIGHT };
    case componentType === LINKED_IMAGE && type === IMAGE && !desktop:
      return position.toString() === IMAGE_POSITION_FULL
        ? {
            width: BACKGROUND_IMAGE_WIDTH_MOBILE,
            height: BACKGROUND_IMAGE_HEIGHT_MOBILE,
          }
        : { width: IMAGE_WIDTH_MOBILE, height: IMAGE_HEIGHT_MOBILE };
    case componentType === CHALLENGE_TYPE:
      return { width: PRODUCT_WIDTH, height: PRODUCT_HEIGHT };
  }
  return undefined;
};

export const checkDuplicatedOrders = ({
  form,
  index,
  value,
}: {
  form: WrappedFormUtils<any>;
  index: number;
  value: number;
}) => {
  const formValues = form.getFieldsValue();
  let isValidNumber = true;
  for (let key in formValues) {
    if (key.includes('detail-order')) {
      const fieldIndex = key.slice(-1);
      if (
        fieldIndex !== index.toString() &&
        formValues[key].toString() === value.toString()
      )
        isValidNumber = false;
    }
  }
  return isValidNumber;
};

export const linkedImageValidations = ({
  componentType,
  details,
}: {
  componentType: number;
  details: IPageDetail[];
}) => {
  if (componentType !== LINKED_IMAGE || !details?.length) return null;

  let imageDetail: undefined | IPageDetail;
  details.forEach((detail) => {
    if (detail.type === PageDetailEnum.IMAGE) imageDetail = detail;
  });

  if (!imageDetail?.value)
    return (
      <>
        <Col span={2}></Col>
        <Col xs={22} className="Component__uploadRow--linked-validation">
          &#9432; Note that if you don't upload the image, the content will not
          be displayed in the front office
        </Col>
      </>
    );
};
