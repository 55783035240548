export enum PIN_PlatformID_Enum {
  PIN_ARGENTINA = 'pin-ar',
  PIN_USA = 'pin-us',
  PIN_BRAZIL = 'pin-br',
  PIN_CHILE = 'pin-cl',
  PIN_SPAIN = 'pin-es',
  PIN_ITALY = 'pin-it',
  PIN_PERU = 'pin-pe',
  PIN_PORTUGAL = 'pin-pt',
  PIN_COLOMBIA = 'pin-co',
  PIN_MEXICO = 'pin-mx',
  PIN_GERMANY = 'pin-de',
  PIN_GUATEMALA = 'pin-gt'
}

export enum CON_PlatformID_Enum {
  CON_ARGENTINA = 'con-ar',
  CON_CHILE = 'con-cl',
  CON_COLOMBIA = 'con-co',
  CON_MEXICO = 'con-mx',
  CON_PERU = 'con-pe',
  CON_SPAIN = 'con-es',
  WEBEAT_ES = 'webeat-es',
}
