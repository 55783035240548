import axios from 'axios';
import config from './config';
import { loadItem, loadState, saveState } from './utils/localStorage';
import apiPaths from './apiPaths';
import { feedback } from './utils/feedback';
import store from './store';
import { authTypes } from './auth/authTypes';
import { appTypes } from './app/appTypes';
import { PLATFORM_CODE_KEY } from './utils';

axios.defaults.baseURL = store.getState().app.currentPlatform;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.backoffice = true;

const baseAxios = axios.create(axios.defaults);
const secureAxios = axios.create(axios.defaults);

export const setAxiosBaseUrl = (url: string) => {
  secureAxios.defaults.baseURL = url;
  baseAxios.defaults.baseURL = url;
};
export const forceLogout = () => {
  store.dispatch({ type: authTypes.REMOVE_TOKEN });
  store.dispatch({ type: appTypes.APP_REMOVE_STORE });
  saveState({
    auth: {
      isAuthorized: false,
      accessToken: '',
      refreshToken: '',
    },
  });
};

const getRequestHeaders = (accessToken: any) =>
  config.SECURITY !== 2 || !accessToken
    ? {
        common: {
          'platform-code': config.APP.PLATFORM_CODE,
        },
      }
    : {
        common: {
          Authorization:
            accessToken.indexOf('Bearer') < 0
              ? `Bearer ${accessToken}`
              : accessToken,
          'Content-Type': 'application/json',
          platform: config.APP.PLATFORM,
          'platform-code': loadItem(PLATFORM_CODE_KEY),
        },
      };

secureAxios.interceptors.request.use((req: any) => {
  const requestType = req.method;
  if (requestType !== 'get')
    feedback(
      (req.feedback && req.feedback.loading) || {
        type: 'message',
        method: 'loading',
        message: 'generic.loading',
      },
    );
  const accessToken =
    config.SECURITY !== 2 ? undefined : loadState().auth.accessToken;

  return {
    ...req,
    headers: getRequestHeaders(accessToken),
  };
});

interface iRefreshTokenPool {
  requestCount: number;
  promise?: any;
}
let refreshTokenPool: iRefreshTokenPool = {
  requestCount: 0,
};

secureAxios.interceptors.response.use(
  (response: any) => {
    const { config } = response;
    const requestType = config.method;
    if (requestType !== 'get')
      feedback(
        // (config.feedback && config.feedback.success) || { //TODO api
        {
          type: 'message',
          method: 'success',
          message: 'generic.success',
          duration: 0.5,
        },
      );
    return response;
  },
  async (error: any) => {
    const status = error.response ? error.response.status : null;
    const data = error.response ? error.response.data : null;
    const responseURL = error.response.request.responseURL;
    const comboName = /resource_type_byChallenge*/;
    const checkComboName = responseURL.match(comboName);

    if (status === 400) {
      if (
        error.response.data.error &&
        error.response.data.error !== 'invalid_grant'
      )
        feedback({
          type: 'notification',
          method: 'warning',
          title: 'error.title',
          message: !data
            ? ''
            : data.message
            ? data.message
            : data.description
            ? data.description
            : data.error
            ? data.error
            : '',
          duration: 10,
        });
      throw error;
    } else if (status === 401) {
    } else if (status !== 401 || error.config.retry) {
      if (checkComboName) return null;

      feedback({
        type: 'notification',
        method: data?.message ? 'error' : 'warning',
        title: data?.message ?? 'error.title',
        message: data?.description || data?.message || 'Error',
        duration: 10,
      });
      throw error;
    }
    try {
      if (!refreshTokenPool.promise) {
        refreshTokenPool.promise = refreshCall().then((response: any) => {
          const { accessToken, refreshToken } = response.data;
          const auth = {
            accessToken: `Bearer ${accessToken}`,
            refreshToken: refreshToken,
            isAuthorized: true,
          };
          saveState({ auth });
          return response;
        });
        refreshTokenPool.requestCount = 1;
      } else {
        refreshTokenPool.requestCount++;
      }
      const response: any = await refreshTokenPool.promise;
      if (response === null) return;
      const { accessToken, refreshToken } = response.data;
      refreshTokenPool.requestCount--;
      if (refreshTokenPool.requestCount === 0) {
        refreshTokenPool.promise = null;
        store.dispatch({
          type: authTypes.SET_TOKENS,
          payload: { accessToken, refreshToken },
        });
      }
      return secureAxios({
        ...error.response.config,
        retry: true,
        headers: getRequestHeaders(accessToken),
      });
    } catch (e) {
      if (
        e.response.status &&
        e.response.status === 400 &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error === 'invalid_grant'
      ) {
        feedback({
          type: 'notification',
          method: 'error',
          title: 'error.title',
          message: 'server.session.expired',
          duration: 10,
        });
        forceLogout(); // sustituir autologout por popup de infromación al usuario + botón de logout
        refreshTokenPool.requestCount = 0;
        refreshTokenPool.promise = null;
      }
      throw e;
    }
  },
);

export const accessCall = ({ dataPath = apiPaths.ACCESS, callConfig = {} }) =>
  secureAxios.get(`${dataPath}`, callConfig);

export const loginCall = ({
  dataPath = apiPaths.AUTH.LOGIN,
  data,
}: {
  dataPath: string;
  data: any;
}) =>
  baseAxios.post(dataPath, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      platform: config.APP.PLATFORM,
    },
  });

export const refreshCall = ({ dataPath = apiPaths.AUTH.REFRESH } = {}) => {
  let { accessToken, refreshToken } = loadState().auth;
  const data = {
    accessToken: accessToken.split(' ')[1],
    refreshToken: refreshToken,
  };
  const callConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      platform: config.APP.PLATFORM,
    },
  };
  return secureAxios.post(dataPath, data, callConfig);
};

export const recoveryPasswordCall = (dataPath: string, email: string) =>
  baseAxios.get(`${dataPath}?email=${email}`);

export const updatePasswordCall = (
  dataPath: string,
  data: { token: string | number; newPassword: string },
) => baseAxios.post(dataPath, data);

export const logoutCall = ({
  dataPath,
  callConfig,
}: {
  dataPath: string;
  callConfig: {};
}) => secureAxios.get(dataPath, callConfig);

export const getDataCall = ({
  dataPath,
  callConfig,
}: {
  dataPath: string;
  callConfig: {};
}) => secureAxios.get(dataPath, callConfig);

export const getDataCallById = ({
  dataPath,
  registerId,
  callConfig,
}: {
  dataPath: string;
  registerId: number | string;
  callConfig: {};
}) => secureAxios.get(`${dataPath}/${registerId}`, callConfig);

export const getCombo = (params: { id: string; param?: string | number }) => {
  return secureAxios.get(apiPaths.COMBO, { params });
};

export const deleteDataCallById = ({
  dataPath,
  registerId,
  callConfig = {},
}: {
  dataPath: string;
  registerId: number | string;
  callConfig?: {};
}) => secureAxios.delete(`${dataPath}/${registerId}`, callConfig);

export const deleteCall = ({
  dataPath,
  callConfig = {},
}: {
  dataPath: string;
  callConfig?: {};
}) => secureAxios.delete(dataPath, callConfig);

export const putDataCall = ({
  dataPath,
  data,
  callConfig = {},
}: {
  dataPath: string;
  data: any;
  callConfig: {};
}) => secureAxios.put(dataPath, data, callConfig);

export const putDataCallById = ({
  dataPath,
  id,
  data,
  callConfig = {},
}: {
  dataPath: string;
  id: number | string;
  data: any;
  callConfig: {};
}) => secureAxios.put(`${dataPath}/${id}`, data, callConfig);

export const postDataCall = ({
  dataPath,
  data,
  callConfig = {},
}: {
  dataPath: string;
  data: any;
  callConfig?: {};
}) => secureAxios.post(dataPath, data, callConfig);

export const postDataCallById = ({
  dataPath,
  id,
  data,
  callConfig = {},
}: {
  dataPath: string;
  id: number | string;
  data?: any;
  callConfig?: {};
}) => secureAxios.post(`${dataPath}/${id}`, data, callConfig);

export const restorePasswordCall = ({
  dataPath,
  data,
  callConfig = {},
}: {
  dataPath: string;
  data: any;
  callConfig: {};
}) => secureAxios.post(dataPath, data, callConfig);

export const resourceCall = ({
  dataPath,
  callConfig = {},
}: {
  dataPath: string;
  callConfig?: {};
}) => secureAxios.get(dataPath, callConfig);

export const openFileCall = async (fileName: string) => {
  const { STATIC_URL } = config.API;

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  anchor.target = '_blank';
  anchor.href = `${STATIC_URL}/uploads/${fileName}`;

  anchor.click();

  anchor.remove();
};

export const getWebeatPlatformSelector = async () => {
  return secureAxios.get(apiPaths.WEBEAT.PLATFORMS, {});
};
