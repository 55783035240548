import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatMenuChildDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatMenuChildSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'parentId',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],

      fields: [
        {
          title: 'Parent Id',
          key: 'parentId',
          value: 'parentId',
          type: 'text',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: true,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatMenuChildViewList',
        rowKey: 'idMenu',
        panelHeader: formatMessage({ id: 'menu.list' }),
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'userMenu.id' }),
          key: 'idMenu',
          position: 0,
          mustRender: false,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'userMenu.route' }),
          key: 'route',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.order' }),
          key: 'order',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'userMenu.type' }),
          key: 'type',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'selector',
          comboId: 'menuTypes',
          selectorPath: '/menu/types',
          width: MID,
        },
        {
          title: formatMessage({ id: 'userMenu.parentId' }),
          key: 'parentId',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'selector',
          comboId: 'menuParents',
          selectorPath: '/menu/parents',
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatMenuChildEdit',
        panelHeader: formatMessage({ id: 'menu.edit' }),
        primaryKey: 'idMenu',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'parentId',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 23,
                },
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 11,
                },

                {
                  key: 'type',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'platform',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'order-optional',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 3,
                },
                {
                  key: 'absolute',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'webeat-route',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'visible',
                  yPosition: 2,
                  xPosition: 3,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 4,
                  mustRender: false,
                  width: 4,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: '',
          key: 'webeatMenuPages',
          type: 'selector',
          comboId: 'webeatMenuPages',
          selectorPath: '/menu/pages',
        },
        {
          title: formatMessage({ id: 'userMenu.parentId' }),
          key: 'parentId',
          value: 'parentId',
          type: 'text',
          unique: true,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'userMenu.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.type' }),
          key: 'type',
          type: 'selector',
          mandatory: true,
          disabled: false,
          comboId: 'type-child',
          selectorPath: '/menu/type-childs',
          behaviours: [
            [
              {
                key: 'parentId',
                type: 'combo',
                params: true,
              },
            ],
          ],
        },
        {
          title: 'Platform',
          key: 'platform',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          initialValue: props.currentPlatform,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'userMenu.order' }),
          key: 'order-optional',
          type: 'customField',
          tooltip: formatMessage({ id: 'userMenu.order.tooltip' }),
        },
        {
          title: 'Absolute route',
          key: 'absolute',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          unique: false,
          min: 0,
          length: 0,
          hideClear: true,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Status',
          key: 'status',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 100,
          hideClear: true,
          default: true,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.route' }),
          key: 'webeat-route',
          type: 'customField',
          mandatory: false,
        },
        {
          title: formatMessage({ id: 'userMenu.disabled' }),
          key: 'disabled',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'false',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'userMenu.visible' }),
          key: 'visible',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatMenuChildDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatMenuChildDashboard);
