import { isEmpty } from 'lodash';

import {
  IComponentGroup,
  IFormComponent,
  IGroupField,
  IFieldSubgroups,
  ICompleteField,
  IComponentSubgroup,
  IComponentField,
  IBehaviours
} from './formInterfaces';
import { ISearchField, IEditField } from '../fields/FieldsInterfaces';
import { IPermission } from '../dashboards/DashboardInterfaces';
import { IRecord } from '../app/AppInterfaces';

/**
 *
 * @param {Array} groups fields config
 * @param {Array} fieldsArray Array of fields
 * @return {Array} New array that composes, filters and sorts both arrays in a new one, which will be used to render the Edit Form
 */
export const formConstructor = (
  groups: IComponentGroup[],
  fields: (IEditField | ISearchField)[],
  userPermissions: string[],
  params: IFormComponent['params']
) => {
  const sortedConstructor: IGroupField[] = [];

  if (!isEmpty(fields)) {
    let sortedGroups: IComponentGroup[] = groups
      .slice()
      .filter(elem => {
        return elem.mustRender;
      })
      .sort((prev, next) => {
        return prev.index - next.index;
      });

    const fieldsArray = checkArrayPermissions(fields, userPermissions);

    sortedGroups = checkElementPermissions(
      sortedGroups,
      userPermissions,
      params
    );
    //sorted = basic(IcomponentGroup)
    sortedGroups.forEach(element => {
      let { subgroups, ...deconstructedGroup } = element;
      subgroups = checkElementPermissions(subgroups, userPermissions, element);
      let subgroupRows: IFieldSubgroups[][] = new Array(getRowsCount(subgroups))
        .fill(undefined)
        .map((row: IFieldSubgroups[], i: number) => {
          const subgroupsPerRow: IComponentSubgroup[] = getSortedRowElements(
            subgroups,
            i
          );
          return subgroupsPerRow.map((subgroup: IComponentSubgroup) => {
            const {
              fields,
              xPosition,
              yPosition,
              mustRender,
              ...finalSubgroup
            } = subgroup;

            const fieldRow = new Array(getRowsCount(fields))
              .fill(undefined)
              .map((row, i: number) => {
                let fieldsPerRow: IComponentField[] = getSortedRowElements(
                  fields,
                  i
                );
                fieldsPerRow = checkFieldsPermissions(
                  fieldsPerRow,
                  finalSubgroup
                );
                return fieldsPerRow.map((fields: IComponentField) => {
                  const {
                    xPosition,
                    yPosition,
                    mustRender,
                    ...finalField
                  } = fields;
                  /*Returns field without useless objects*/
                  const returnField: ICompleteField = {
                    ...finalField,
                    ...fillField(finalField, fieldsArray)
                  };
                  return returnField;
                });
              });

            return { ...finalSubgroup, fieldRow };
          });
        });
      sortedConstructor.push({ ...deconstructedGroup, subgroupRows });
    });
  }
  return sortedConstructor;
};

/**
 *
 * @param {Array} arrayOfElements
 * @returns {number} Number of rows that will contain the element, based on Max(yPosition)
 */
const getRowsCount = (
  arrayOfElements: (IComponentSubgroup | IComponentField)[]
) => {
  return (
    Math.max(
      ...arrayOfElements
        .filter(function (element) {
          return element.mustRender;
        })
        .map(element => element.yPosition)
    ) + 1
  );
};

/**
 *
 * @param {Array} arrayOfElements
 * @param {number} rowIndex
 * @returns {Array}Array elements which cointains yPosition === rowIndex
 */
const getSortedRowElements = <T extends IComponentSubgroup | IComponentField>(
  arrayOfElements: T[],
  rowIndex: number
): Array<T> => {
  return arrayOfElements
    .filter(element => {
      return (
        element.yPosition === rowIndex &&
        (element.mustRender === undefined || element.mustRender)
      );
    })
    .sort((prevRowElement, nextRowElement) => {
      return prevRowElement.xPosition - nextRowElement.xPosition;
    });
};

/**
 *
 * @param {Object} field Matching object
 * @param {Array} arrayFields Array of objects(fields) to compare with a concret object
 * @return {Object} returns a composed object (field + arrayField) which has the same key
 */
const fillField = <
  T extends Partial<IComponentField>,
  U extends IEditField | ISearchField
>(
  field: T,
  arrayFields: U[]
) => {
  const match: U = arrayFields.filter(
    arrayField => arrayField.key === field.key
  )[0];
  const { key, ...composedField } = match;
  return checkBehaviours(composedField);
};

const checkElementPermissions = <
  T extends IBehaviours,
  U extends string[],
  V extends IFormComponent['params'] | IComponentGroup
>(
  elements: T[],
  userPermissions: U,
  parentElement: V
) => {
  let groupPermissions = {
    disabled:
      'forceDisabled' in parentElement
        ? parentElement.forceDisabled
        : undefined,
    visible:
      'forceVisible' in parentElement ? parentElement.forceVisible : undefined
  };
  let elemenstArray = [...elements];

  elemenstArray.forEach(element => {
    const { permissions } = element;
    if (permissions) {
      permissions.forEach((permission: IPermission) => {
        if (userPermissions.includes(permission.name)) {
          for (let key in permission) {
            switch (key) {
              case 'visible':
                if (groupPermissions.visible !== undefined)
                  element.forceVisible = groupPermissions.visible;
                break;
              case 'disabled':
                if (groupPermissions.disabled !== undefined)
                  element.forceDisabled = groupPermissions.disabled;
                break;
              default:
                break;
            }
          }
        }
      });
    }

    if (groupPermissions.visible !== undefined)
      element.forceVisible = groupPermissions.visible;
    if (groupPermissions.disabled !== undefined)
      element.forceDisabled = groupPermissions.disabled;
  });
  return elemenstArray;
};

const checkFieldsPermissions = <
  T extends IComponentField,
  U extends Partial<IComponentSubgroup>
>(
  elements: T[],
  parentElement: U
) => {
  let groupPermissions = {
    disabled:
      'forceDisabled' in parentElement
        ? parentElement.forceDisabled
        : undefined,
    visible:
      'forceVisible' in parentElement ? parentElement.forceVisible : undefined
  };
  let elemenstArray = elements.slice();

  elemenstArray.forEach(element => {
    for (let key in groupPermissions) {
      switch (key) {
        case 'disabled':
          element.forceDisabled = groupPermissions.disabled;
          break;
        case 'visible':
          element.forceVisible = groupPermissions.visible;
          break;
      }
    }
  });
  return elemenstArray;
};

/**
 * This function checks if the field has behaviour props and sets them to their initial behaviour prop
 * @param {Object} field
 */
function checkBehaviours<T extends Partial<ISearchField | IEditField>>(
  field: T
) {
  for (let key in field) {
    switch (key) {
      case 'initialVisibility':
        field.visible = field.initialVisibility;
        break;
      case 'initialDisabled':
        field.disabled = field.initialDisabled;
        break;
      case 'initialMandatory':
        field.mandatory = field.initialMandatory;
        break;
      default:
        break;
    }
  }
  return field;
}

/**
 * this function evaluates Form fields permissions and given behaviours
 * @param fields Array of Search or Edit Fields to evaluate
 * @param userPermissions - Array of Strings - current user permissions
 */
function checkArrayPermissions<
  T extends Array<ISearchField | IEditField>,
  U extends string[]
>(fields: T, userPermissions: U) {
  let fieldsArray = [...fields];
  fieldsArray.forEach(field => {
    const { permissions } = field;
    if (permissions) {
      permissions.forEach((permission: IPermission) => {
        if (userPermissions!.includes(permission.name)) {
          for (let key in permission) {
            switch (key) {
              case 'disabled':
                field.forceDisabled = permission['disabled'];
                break;
              case 'visible':
                field.forceVisible = permission['visible'];
                break;
              case 'mandatory':
                field.forceMandatory = permission['mandatory'];
                break;
            }
          }
        }
      });
    }
  });
  return fieldsArray;
}

/**
 * This function returns global search field porps
 * @param {Array} fields Array of fields
 * @return {Object} New global search field with targets based on globalSearch bool option
 */
export const makeGlobalSearchField = (fields: ISearchField[]) => {
  const globalSearch = {
    title: 'Find',
    key: 'textSearch',
    type: 'text',
    fieldTargets: [] as string[]
  };
  fields.forEach(field => {
    if (field.type === 'text' && field.globalSearch)
      globalSearch.fieldTargets.push(field.key);
  });
  return globalSearch;
};

/**
 * This function evaluate how many search fields have been modified
 * @param {Array} fields Array of fields
 * @param {Object} values Object of values
 * @return {Int} Search fields moodified number
 */
export const getModifiedFieldsNumber = (
  fields: ISearchField[],
  values: IRecord
) => {
  let count = 0;
  fields.forEach(field => {
    for (let keyOfValue in values) {
      if (
        field.key === keyOfValue &&
        (!('initialValue' in field) ||
          ('initialValue' in field && field.initialValue !== values[field.key]))
      )
        count += 1;
    }
  });
  return count;
};
