import { Button, Col, Form, Icon, Input, Row, Select, Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { IRow } from '../app/AppInterfaces';
import UploadComponent from '../challenge/Components/UploadComponent';
import config from '../config';
import { EditFormRenderProps } from '../forms/edit/EditFormRender';
import {
  ICompleteField,
  IEditParams,
  ISearchParams,
} from '../forms/formInterfaces';
import { SearchFormRenderProps } from '../forms/search/SearchFormRender';
import { MaterialsEnum } from '../marketingMaterials/marketingMaterials.enum';
import {
  IFileDimensions,
  IUploadActions,
  UPLOAD_ACTION_VIDEO,
  MenuEnum,
} from '../shared';
import { comboRender } from '../contentPage/shared';
import { WebeatImageSelector } from './WebeatImageSelector';

const {
  PRODUCT_IMAGE,
  VIDEO_YOUTUBE,
  VIDEO_UPLOAD,
  PRODUCT_INFORMATION,
  OFFICE_BRANDING,
  PATIENT_OUTREACH,
} = MaterialsEnum;

interface Props {
  field: ICompleteField;
  parentProps: EditFormRenderProps | SearchFormRenderProps;
  values: IRow;
}

export default function CustomField({ field, parentProps, values }: Props) {
  const { key } = field;
  const { handleChangeField, props } = parentProps;
  const { accessToken, form } = props;
  const { getFieldDecorator } = form;
  const { formatMessage } = useIntl();
  const {
    PRIVATE_MENU,
    PUBLIC_FOOTER_PARENT,
    PUBLIC_FOOTER_CHILD,
    PRIVATE_FOOTER_PARENT,
    PRIVATE_FOOTER_CHILD,
  } = MenuEnum;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.type, values?.absolute]);

  const UploadFile = ({
    key,
    format,
    title,
    fileDimensions,
    uploadActions,
    notRemovable,
  }: {
    key: string;
    format: 'video' | 'image' | 'pdf';
    title: string;
    fileDimensions?: IFileDimensions;
    uploadActions?: IUploadActions[];
    notRemovable?: boolean;
  }) => {
    const setUploadValue = (key: string, value?: string | null) => {
      if (value) handleChangeField({ id: key, type: 'text', value });
    };

    const isEditParentProps = (
      props: IEditParams | ISearchParams,
    ): props is IEditParams => {
      return (props as IEditParams).primaryKey !== undefined;
    };

    return (
      <Col xs={24} md={12}>
        <UploadComponent
          {...{
            format,
            setValue: (value) => setUploadValue(key, value),
            token: accessToken,
            value: values[key],
            fileDimensions,
            uploadActions,
            handleChangeField: notRemovable ? null : handleChangeField,
            fieldName: key,
            primaryEntityId: isEditParentProps(parentProps.props.params)
              ? values[parentProps.props.params.primaryKey]
              : '',
            module: field.module || '',
          }}>
          <Button style={{ width: '100%' }} icon="upload">
            {window.innerWidth < config.BREAKPOINTS.MD ||
              (window.innerWidth > config.BREAKPOINTS.LG && title)}
          </Button>
        </UploadComponent>
      </Col>
    );
  };

  const SurveyImageUploader = (field: ICompleteField) => {
    const { key, title } = field;
    return (
      <Row className="isdin-custom-upload">
        <div className="isdin-custom-upload__title" title={field.title}>
          {field.title}
        </div>
        {UploadFile({
          key,
          format: 'image',
          title,
          fileDimensions: {
            width: config.CHALLENGE.COVER_IMAGE_WIDTH,
            height: config.CHALLENGE.COVER_IMAGE_HEIGHT,
          },
        })}
      </Row>
    );
  };

  const MarketingMaterialFields = (field: ICompleteField, values: IRow) => {
    const { type } = values;

    if (!type) return;
    switch (parseInt(type)) {
      case PATIENT_OUTREACH:
        return (
          <Row gutter={[24, 0]}>
            {UploadFile({
              key: 'image',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-image' }),
            })}
            {UploadFile({
              key: 'content',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-file' }),
            })}
          </Row>
        );
      case PRODUCT_INFORMATION:
      case OFFICE_BRANDING:
        return (
          <Row gutter={[24, 0]}>
            {UploadFile({
              key: 'image',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-image' }),
            })}
            {UploadFile({
              key: 'content',
              format: 'pdf',
              title: formatMessage({ id: 'custom-field.upload-file' }),
            })}
          </Row>
        );
      case PRODUCT_IMAGE:
        return UploadFile({
          key: 'content',
          format: 'image',
          title: formatMessage({ id: 'custom-field.upload-image' }),
          notRemovable: true,
        });
      case VIDEO_YOUTUBE:
        return (
          <Form.Item
            label={formatMessage({
              id: 'custom-field.youtube-video-id',
            })}>
            <Row gutter={[24, 0]}>
              {UploadFile({
                key: 'image',
                format: 'image',
                title: formatMessage({
                  id: 'custom-field.upload-image',
                }),
              })}
              <Col xs={24} md={12}>
                {getFieldDecorator('content', {
                  initialValue: values.content,
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'update.required' }),
                    },
                  ],
                })(
                  <Input
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeField({
                        type: 'text',
                        id: 'content',
                        value: e.target.value,
                      })
                    }
                  />,
                )}
              </Col>
            </Row>
          </Form.Item>
        );
      case VIDEO_UPLOAD:
        return (
          <Row gutter={[24, 0]}>
            {UploadFile({
              key: 'image',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-image' }),
            })}
            {UploadFile({
              key: 'content',
              format: 'video',
              title: formatMessage({ id: 'custom-field.upload-video' }),
              uploadActions: [UPLOAD_ACTION_VIDEO],
            })}
          </Row>
        );
      default:
        return;
    }
  };

  const ScientificMaterialFields = (fields: ICompleteField, value: IRow) => {
    return (
      <>
        <Row gutter={[24, 0]}>
          {UploadFile({
            key: 'imageUrl',
            format: 'image',
            title: formatMessage({ id: 'custom-field.upload-image' }),
          })}
          {UploadFile({
            key: 'videoUrl',
            format: 'video',
            title: formatMessage({ id: 'custom-field.upload-video' }),
            uploadActions: [UPLOAD_ACTION_VIDEO],
          })}
        </Row>
        <Row className="isdin-custom-upload">
          <div className="isdin-custom-upload__title" title={field.title}>
            {field.title}
          </div>
          {UploadFile({
            key: 'summaryUrl',
            format: 'pdf',
            title: formatMessage({ id: 'custom-field.upload-file' }),
          })}
        </Row>
      </>
    );
  };

  const MenuCombo = (comboProps: any) => {
    const initialValue = values && values[key] ? values[key].toString() : '';

    return (
      <Col xs={comboProps?.fieldSizes?.col?.width || 24}>
        <Form.Item
          label={formatMessage({
            id: 'custom-field.' + key,
          })}>
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              // allowClear={!field.hasOwnProperty('initialValue')}
              dropdownMatchSelectWidth={false}
              allowClear
              size="default"
              placeholder={formatMessage({
                id: `userMenu.${key}.placeholder`,
              })}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => {
                handleChangeField({
                  type: 'combo',
                  id: key,
                  value:
                    value >= 0 || typeof value === 'string' ? data.key : null,
                });
              }}>
              {comboRender(
                comboProps?.comboData,
                props?.combos,
                'menumenuEdit',
              )}
            </Select>,
          )}
        </Form.Item>
      </Col>
    );
  };

  const MenuRoute = (field: ICompleteField, values: IRow) => {
    if (
      ![PRIVATE_MENU, PUBLIC_FOOTER_CHILD, PRIVATE_FOOTER_CHILD].includes(
        values?.type?.toString(),
      )
    )
      return <></>;

    let initialValue = values.route;
    const comboData = { comboId: 'menuPages', key: 'menuPages' };

    return values?.absolute?.toString() === 'true' ? (
      <Form.Item
        label={formatMessage({
          id: 'custom-field.route',
        })}>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            {getFieldDecorator('route', {
              initialValue: initialValue,
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'update.required' }),
                },
              ],
            })(
              <Input
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeField({
                    type: 'text',
                    id: 'route',
                    value: e.target.value,
                  });
                }}
              />,
            )}
          </Col>
        </Row>
      </Form.Item>
    ) : (
      <Row gutter={[24, 0]}>
        <Col xs={12}>
          <Form.Item
            label={formatMessage({
              id: 'custom-field.' + key,
            })}>
            <Input value={values?.route} disabled={true} />
          </Form.Item>
        </Col>
        <MenuCombo comboData={comboData} fieldSizes={{ col: { width: 12 } }} />
      </Row>
    );
  };

  const MenuOrder = (field: ICompleteField, values: IRow) => {
    let initialValue = values.order;
    if (!values?.type) return <></>;
    const comboDataPrivate = {
      comboId: 'privateFooterOrder',
      key: 'privateFooterOrder',
    };
    const comboDataPublic = {
      comboId: 'publicFooterOrder',
      key: 'publicFooterOrder',
    };

    switch (values?.type?.toString()) {
      case PUBLIC_FOOTER_PARENT:
        return (
          <Row gutter={[24, 0]}>
            <MenuCombo comboData={comboDataPublic} />
          </Row>
        );
      case PRIVATE_FOOTER_PARENT:
        return (
          <Row gutter={[24, 0]}>
            <MenuCombo comboData={comboDataPrivate} />
          </Row>
        );
      default:
        return (
          <Form.Item
            label={formatMessage({
              id: 'custom-field.order',
            })}>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                {getFieldDecorator('order', {
                  initialValue: initialValue,
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'update.required' }),
                    },
                  ],
                })(
                  <Input
                    type="number"
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeField({
                        type: 'number',
                        id: 'order',
                        value: e.target.value,
                      });
                    }}
                  />,
                )}
              </Col>
            </Row>
          </Form.Item>
        );
    }
  };

  const WebeatMenuOrder = (field: ICompleteField, values: IRow) => {
    let initialValue = values.order;
    return (
      <Form.Item
        label={
          field.tooltip ? (
            <span>
              {formatMessage({
                id: 'custom-field.order',
              })}
              <Tooltip className="fieldTooltip" title={field.tooltip}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          ) : (
            formatMessage({
              id: 'custom-field.order',
            })
          )
        }>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            {getFieldDecorator('order', {
              initialValue: initialValue,
              rules: [
                {
                  required: false,
                  message: formatMessage({ id: 'update.required' }),
                },
                {
                  validator: (rule, value, callback) => {
                    if (!!value && value < 0) {
                      callback(
                        formatMessage({
                          id: 'custom-field.order-optional.validator',
                        }),
                      );
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <Input
                type="number"
                disabled={!values?.type}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeField({
                    type: 'number',
                    id: 'order',
                    value: e.target.value,
                  });
                }}
              />,
            )}
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const MenuWebeatCombo = (comboProps: any) => {
    const initialValue =
      values && values['route'] ? values['route'].toString() : '';

    return (
      <Col xs={comboProps?.fieldSizes?.col?.width || 24}>
        <Form.Item
          label={formatMessage({
            id: 'custom-field.' + key,
          })}>
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [
              {
                required: false,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              // allowClear={!field.hasOwnProperty('initialValue')}
              dropdownMatchSelectWidth={false}
              allowClear
              size="default"
              placeholder={formatMessage({
                id: `userMenu.${key}.placeholder`,
              })}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => {
                handleChangeField({
                  type: 'combo',
                  id: 'route',
                  value:
                    value >= 0 || typeof value === 'string' ? data.key : null,
                });
              }}>
              {comboRender(
                comboProps?.comboData,
                props?.combos,
                'webeatMenuEdit',
              )}
            </Select>,
          )}
        </Form.Item>
      </Col>
    );
  };

  const MenuWebeatRoute = (field: ICompleteField, values: IRow) => {
    let initialValue = values.route;
    const comboData = { comboId: 'webeatMenuPages', key: 'webeatMenuPages' };

    return values?.absolute?.toString() === 'true' ? (
      <Form.Item
        label={formatMessage({
          id: 'custom-field.route',
        })}>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            {getFieldDecorator('route', {
              initialValue: initialValue,
              rules: [
                {
                  required: false,
                  message: formatMessage({ id: 'update.required' }),
                },
              ],
            })(
              <Input
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeField({
                    type: 'text',
                    id: 'route',
                    value: e.target.value,
                  });
                }}
              />,
            )}
          </Col>
        </Row>
      </Form.Item>
    ) : (
      <Row gutter={[24, 0]}>
        <Col xs={12}>
          <Form.Item
            label={formatMessage({
              id: 'custom-field.' + key,
            })}>
            <Input value={values?.route} disabled={true} />
          </Form.Item>
        </Col>
        <MenuWebeatCombo
          comboData={comboData}
          fieldSizes={{ col: { width: 12 } }}
        />
      </Row>
    );
  };

  switch (key) {
    case 'uploads':
      return MarketingMaterialFields(field, values);
    case 'scientificUploads':
      return ScientificMaterialFields(field, values);
    case 'image':
      return SurveyImageUploader(field);
    case 'route':
      return MenuRoute(field, values);
    case 'webeat-route':
      return MenuWebeatRoute(field, values);
    case 'order':
      return MenuOrder(field, values);
    case 'order-optional':
      return WebeatMenuOrder(field, values);
    case `banner${field.index}Type`:
      return WebeatImageSelector(field, parentProps);
    default:
      return;
  }
}
