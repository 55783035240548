import { Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { inRange } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { IPageComponent, IPageDetail } from '.';
import { ComboData } from '../../combos/ComboInterfaces';
import { ReducersState } from '../../reducers';
import { PageComponentEnum, VideoTypesEnum } from './ContentPage.enums';

export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const constructPage = (components: IPageComponent[]) => {
  let filteredComponents: IPageComponent[] = JSON.parse(
    JSON.stringify(components),
  );

  const sortedArr = filteredComponents
    .filter((component) => component.status)
    .sort(
      (a: IPageComponent, b: IPageComponent) =>
        a.idContentComponent - b.idContentComponent,
    )
    .sort((a: IPageComponent, b: IPageComponent) => a.order - b.order);

  sortedArr.forEach((component) => {
    const { componentDetails } = component;
    if (componentDetails.length) {
      component.componentDetails = componentDetails
        .filter((detail) => detail.status)
        .sort(
          (a: IPageDetail, b: IPageDetail) =>
            a.idContentPageDetail! - b.idContentPageDetail!,
        )
        .sort((a: IPageDetail, b: IPageDetail) => a.order - b.order);
    }
  });

  sortedArr.forEach((component: IPageComponent, i: number) => {
    component.order = i;
  });

  return sortedArr;
};

export const comboRender = (
  { comboId, key }: { comboId: string; key: string },
  combos: ReducersState['combos'],
  componentId: string,
) => {
  return combos.combos[componentId] &&
    combos.combos[componentId][key] &&
    combos.combos[componentId][key][comboId!] &&
    combos.combos[componentId][key][comboId!].data
    ? combos.combos[componentId][key][comboId!].data.map(
        (option: ComboData) => {
          return (
            <Select.Option
              key={option.value.toString()}
              value={option.value.toString()}>
              {option.description}
            </Select.Option>
          );
        },
      )
    : false;
};

export const renderSelector = ({
  combo,
  disabled,
  form,
  handleEdit,
  formatMessage,
  showValue = false,
  key,
  required,
  value,
}: {
  combo: ComboData[];
  disabled: boolean;
  form: WrappedFormUtils<any>;
  formatMessage: any;
  showValue?: boolean; //TODO Rename according showSearch
  handleEdit: (value: string) => void;
  key: string;
  required: boolean;
  value: string;
}) => {
  return form.getFieldDecorator(key, {
    initialValue: isNaN(parseInt(value)) ? value : value.toString(),
    rules: [
      {
        required,
        message: formatMessage({ id: 'update.required' }),
      },
    ],
  })(
    <Select
      className="Component__modalSelector"
      disabled={disabled}
      onSelect={(value: any, data: any) => {
        handleEdit(value);
      }}
      onBlur={(value: any) => {
        handleEdit(value);
      }}
      showSearch={showValue}
      filterOption={(input: any, option: any) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
      {combo !== null &&
        combo.map((_combo: ComboData) => {
          const selectDescription = showValue
            ? `SKU: ${_combo.value} - ${_combo.description}`
            : _combo.description;
          return (
            <Select.Option
              key={_combo.value.toString()}
              value={_combo.value.toString()}>
              {selectDescription}
            </Select.Option>
          );
        })}
    </Select>,
  );
};

export const isChallengeComponent = (type: PageComponentEnum) =>
  inRange(
    type,
    PageComponentEnum.CHALLENGE_TYPE * 10,
    PageComponentEnum.CHALLENGE_TYPE * 100 + 99,
  );

export const videoTypeCombo = [
  { value: VideoTypesEnum.NORMAL, description: 'Normal' },
  { value: VideoTypesEnum.AUTOPLAY, description: 'Autoplay' },
  {
    value: VideoTypesEnum.LIGHT,
    description: 'Light',
  },
];
