import React from 'react';
import apiPaths from '../apiPaths';
import { DetailsTypesEnum } from '../challenge/Enums';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isConPlatform } from '../utils';

const ChallengeDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  // TODO: extend this array with all platform combos
  const platformComboFields = isConPlatform()
    ? [
        {
          title: 'IconCombo',
          key: 'iconType',
          type: 'combo',
          comboId: 'iconType',
        },
        {
          title: 'Challenge mail type',
          key: 'challengeMails',
          type: 'combo',
          comboId: 'challengeMails',
        },
        {
          title: 'Checkout mail type',
          key: 'checkoutMails',
          type: 'combo',
          comboId: 'checkoutMails',
        },
        {
          title: 'Challenge category',
          key: 'category',
          type: 'combo',
          comboId: 'challengeCategories',
        },
      ]
    : [
        {
          title: 'Specialty',
          key: 'specialty',
          type: 'combo',
          comboId: 'specialty',
          mandatory: false,
        },
      ];

  const platformTableFields = isConPlatform()
    ? [
        {
          title: formatMessage({ id: 'challenge.role' }),
          key: 'challengeRoles',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'challenge.tag' }),
          key: 'tags[0].idTag',
          render: 'combo',
          comboId: 'contestTag',
          position: 7,
          mustRender: false,
          align: 'left',
          sorter: false,
          visible: false,
          width: LARGE,
          isArray: true,
        },
        {
          title: formatMessage({ id: 'challenge.tag' }),
          key: 'tags[0].idTag',
          render: 'combo',
          comboId: 'experienceTag',
          position: 8,
          mustRender: false,
          align: 'left',
          sorter: false,
          visible: false,
          width: LARGE,
          isArray: true,
        },
      ]
    : [];

  const platformButtons = isConPlatform()
    ? ['replicateChallengeButton']
    : [
        'challengeUsers',
        'trainingReportButton',
        'sellInReportButton',
        'transmuteToButton',
        'previewChallengeButton',
      ];

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'challengechallengeSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idChallengeType.idChallengeType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                // {
                //   key: 'idChallengeTemplate.idChallengeTemplate',
                //   yPosition: 0,
                //   xPosition: 1,
                //   mustRender: true,
                //   width: 12,
                // },
                {
                  key: 'featured',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'startDate',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'endDate',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },

                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'challenge.id' }),
          key: 'idChallenge',
          type: 'number',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.idCampaign' }),
          key: 'idCampaign',
          type: 'number',
          operand: 'equal',
          min: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType.idChallengeType',
          type: 'combo',
          operand: 'equal',
          comboId: 'challenge_type',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'challenge.start_date' }),
          key: 'startDate',
          type: 'date',
          operand: 'greater|equal',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.end_date' }),
          key: 'endDate',
          type: 'date',
          operand: 'less|equal',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.start_vis_date' }),
          key: 'startVisDate',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 6,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.end_vis_date' }),
          key: 'endVisDate',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 6,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challengeTemplate.id' }),
          key: 'idChallengeTemplate.idChallengeTemplate',
          type: 'combo',
          comboId: 'challenge_template',
          operand: 'equal',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.featured' }),
          key: 'featured',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'challengechallengeList',
        rowKey: 'idChallenge',
        panelHeader: 'Challenge List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
        customButtons: isConPlatform()
          ? [...platformButtons]
          : ['challengeUsersList', ...platformButtons],
      },
      fields: [
        ...platformTableFields,
        {
          title: formatMessage({ id: 'challenge.id' }),
          key: 'idChallenge',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'challenge.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'challenge.featured' }),
          key: 'featured',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'comboCustom',
          comboId: 'optional',
        },
        {
          title: formatMessage({ id: 'challenge.order' }),
          key: 'order',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'number',
          precision: 0,
        },
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType.name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'challenge.start_date' }),
          key: 'startDate',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.end_date' }),
          key: 'endDate',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.start_vis_date' }),
          key: 'startVisDate',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.end_vis_date' }),
          key: 'endVisDate',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.idCampaign' }),
          key: 'idCampaign',
          position: 10,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 11,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'challenge.challengeCategory' }),
          key: 'challengeCategory',
          render: 'combo',
          comboId: 'challengeCategories',
          position: 12,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'challengechallengeEdit',
        panelHeader: 'Challenge Edit',
        primaryKey: 'idChallenge',
        tabIndex: 0,
        isChallenge: true,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idChallengeType.idChallengeType',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idCampaign',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'name',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'startDate',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'endDate',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'startVisDate',
                  yPosition: 3,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'endVisDate',
                  yPosition: 3,
                  xPosition: 3,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'brand',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'mail',
                  yPosition: 4,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'category',
                  yPosition: 4,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        // NEEDED COMBOS
        ...platformComboFields,
        {
          title: 'Type',
          key: 'type',
          type: 'combo',
          comboId: 'materialType',
        },
        {
          title: 'QuestionType',
          key: 'type',
          type: 'combo',
          comboId: 'questionType',
        },
        {
          title: 'NotificationRecipient',
          key: 'notifications',
          type: 'combo',
          comboId: 'notificationRecipient',
        },
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType.idChallengeType',
          type: 'combo',
          unique: false,
          min: 0,
          comboId: 'challenge_type',
          path: 'challengetype',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Filters',
          key: 'filters',
          type: 'combo',
          comboId: 'boChallengeFilters',
          mandatory: false,
        },
        {
          title: 'FilterType',
          key: 'filterType',
          type: 'combo',
          comboId: 'filterType',
          mandatory: false,
        },
        {
          title: 'Brand',
          key: 'brand',
          type: 'combo',
          comboId: 'bobrands',
          mandatory: true,
        },
        {
          title: 'TargetAchivement',
          key: 'targetAchivement',
          type: 'number',
          disabled: false,
        },
        {
          title: 'TargetEnrollement',
          key: 'targetEnrollement',
          type: 'number',
          disabled: false,
        },
        {
          title: 'RoleType',
          key: 'roleType',
          type: 'combo',
          comboId: 'roleType',
          mandatory: false,
        },
        {
          title: 'Tag',
          key: 'contestTag',
          type: 'combo',
          comboId: 'contestTag',
          mandatory: false,
        },
        {
          title: 'Tag',
          key: 'experienceTag',
          type: 'combo',
          comboId: 'experienceTag',
          mandatory: false,
        },
        {
          title: 'mail',
          key: 'mail',
          type: 'combo',
          comboId: 'mail',
        },
        {
          title: 'products',
          key: 'products',
          type: 'combo',
          comboId: 'shoppingCartProducts',
        },
        {
          title: 'products',
          key: 'checkoutAddressType',
          type: 'combo',
          comboId: 'checkoutAddressType',
        },
        {
          title: 'products',
          key: 'checkoutProductType',
          type: 'combo',
          comboId: 'checkoutProductType',
        },
        {
          title: 'filters',
          key: 'challengeFilterType',
          type: 'combo',
          comboId: 'challengeFilterType',
        },
        {
          title: 'video types',
          key: 'videoSectionType',
          type: 'combo',
          comboId: 'videoSectionType',
        },
        {
          title: 'Course platforms',
          key: DetailsTypesEnum.EXTERNAL_PLATFORM,
          type: 'combo',
          comboId: DetailsTypesEnum.EXTERNAL_PLATFORM,
        },
        {
          title: 'Course platforms',
          key: DetailsTypesEnum.EXTERNAL_CERTIFIED_COURSES,
          type: 'combo',
          comboId: DetailsTypesEnum.EXTERNAL_CERTIFIED_COURSES,
        },
        {
          title: 'Static Pages',
          key: 'menuPages',
          type: 'combo',
          comboId: 'menuPages',
        },
        {
          title: 'User Upload Media File',
          key: 'uploadMediaFile',
          type: 'combo',
          comboId: 'uploadMediaFile',
        },
        {
          title: 'Shoping Cart Types',
          key: 'shoppingCartType',
          type: 'combo',
          comboId: 'shoppingCartType',
        },
        {
          title: 'surveys',
          key: 'surveys',
          type: 'combo',
          comboId: apiPaths.COMBODATA.SURVEYS,
        },
        {
          title: 'arn_surveys',
          key: 'arn_surveys',
          type: 'combo',
          comboId: apiPaths.COMBODATA.ARN_SURVEYS,
        },
        // END NEEDED COMBOS
        {
          title: formatMessage({ id: 'challenge.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.description' }),
          key: 'description',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.descriptionLong' }),
          key: 'descriptionLong',
          type: 'text',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.code' }),
          key: 'code',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.idCampaign' }),
          key: 'idCampaign',
          type: 'text',
          mandatory: false,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'challenge.start_date' }),
          key: 'startDate',
          validationType: 'text',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.end_date' }),
          key: 'endDate',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.start_vis_date' }),
          key: 'startVisDate',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.end_vis_date' }),
          key: 'endVisDate',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'active',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'showList',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'showPoints',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.challengeCategory' }),
          key: 'category',
          type: 'combo',
          comboId: 'challengeCategories',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'ChallengeDashboard';

  return (
    <DashboardInitializer
      customDashboard={componentId}
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ChallengeDashboard;
