import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatGridDashboard = (props: any) => {
  const { MID, SMALL } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatGridSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idGrid',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'name',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'url',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'isPublic',
                  yPosition: 1,
                  xPosition: 4,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'disabled',
                  yPosition: 1,
                  xPosition: 5,
                  mustRender: true,
                  width: 6,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Id Grid',
          key: 'idGrid',
          type: 'text',
          operand: 'equal',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Grid name',
          key: 'name',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          globalSearch: true,
        },
        {
          title: 'Grid url',
          key: 'url',
          type: 'text',
          operand: 'like',
          validationType: 'text',
          globalSearch: true,
        },
        {
          title: 'Is Public',
          key: 'isPublic',
          type: 'boolean',
          min: 0,
          mandatory: false,
          visible: false,
          initialValue: 'false',
        },
        {
          title: 'Disabled',
          key: 'disabled',
          type: 'boolean',
          min: 0,
          mandatory: false,
          visible: false,
          initialValue: 'false',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatGridList',
        rowKey: 'idGrid',
        panelHeader: 'Grid List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: 'Id',
          key: 'idGrid',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Name',
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Url',
          key: 'url',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: 'Is Public',
          key: 'isPublic',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: 'Disabled',
          key: 'disabled',
          position: 5,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatGridEdit',
        panelHeader: 'Grid Edit',
        primaryKey: 'idGrid',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'category',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'type',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'url',
                  yPosition: 0,
                  xPosition: 3,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'isPublic',
                  yPosition: 0,
                  xPosition: 4,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'disabled',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'platform',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 4,
                },
                {
                  key: 'seoTitle',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoSubtitle1',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoDescription1',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoSubtitle2',
                  yPosition: 4,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoDescription2',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoSubtitle3',
                  yPosition: 5,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoDescription3',
                  yPosition: 5,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoSubtitle4',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoDescription4',
                  yPosition: 6,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoSubtitle5',
                  yPosition: 7,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'seoDescription5',
                  yPosition: 7,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'banner1Type',
                  yPosition: 8,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner2Type',
                  yPosition: 9,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner3Type',
                  yPosition: 10,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner4Type',
                  yPosition: 11,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner5Type',
                  yPosition: 12,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner6Type',
                  yPosition: 13,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner7Type',
                  yPosition: 14,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner8Type',
                  yPosition: 15,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner9Type',
                  yPosition: 16,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
                {
                  key: 'banner10Type',
                  yPosition: 17,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Name',
          key: 'name',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: true,
          length: 100,
        },
        {
          title: 'Platform',
          key: 'platform',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          initialValue: props.currentPlatform,
          disabled: true,
        },
        {
          title: 'Category',
          key: 'category',
          type: 'selector',
          comboId: 'gridCategory',
          selectorPath: '/grid/categories',
          operand: 'equal',
          length: 50,
          mandatory: true,
          disabled: false,
          initialValue: '',
        },
        {
          title: 'Type',
          key: 'type',
          type: 'selector',
          comboId: 'gridTypes',
          selectorPath: '/grid/types',
          mandatory: true,
          disabled: false,
          initialValue: '0',
        },
        {
          title: 'Url',
          key: 'url',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: true,
          length: 100,
        },
        {
          title: 'Is Public',
          key: 'isPublic',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Disabled',
          key: 'disabled',
          type: 'comboCustom',
          comboId: 'optional',
          initialValue: 'true',
          unique: false,
          min: 0,
          length: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Image type 1',
          key: 'banner1Type',
          type: 'customField',
          module: 'grid',
          index: 1
        },
        {
          title: 'Image type 2',
          key: 'banner2Type',
          type: 'customField',
          module: 'grid',
          index: 2
        },
        {
          title: 'Image type 3',
          key: 'banner3Type',
          type: 'customField',
          module: 'grid',
          index: 3
        },
        {
          title: 'Image type 4',
          key: 'banner4Type',
          type: 'customField',
          module: 'grid',
          index: 4
        },
        {
          title: 'Image type 5',
          key: 'banner5Type',
          type: 'customField',
          module: 'grid',
          index: 5
        },
        {
          title: 'Image type 6',
          key: 'banner6Type',
          type: 'customField',
          module: 'grid',
          index: 6
        },
        {
          title: 'Image type 7',
          key: 'banner7Type',
          type: 'customField',
          module: 'grid',
          index: 7
        },
        {
          title: 'Image type 8',
          key: 'banner8Type',
          type: 'customField',
          module: 'grid',
          index: 8
        },
        {
          title: 'Image type 9',
          key: 'banner9Type',
          type: 'customField',
          module: 'grid',
          index: 9
        },
        {
          title: 'Image type 10',
          key: 'banner10Type',
          type: 'customField',
          module: 'grid',
          index: 10
        },
        {
          title: 'SEO Title ',
          key: 'seoTitle',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: false,
          length: 100,
        },
        {
          title: 'SEO Subtitle 1',
          key: 'seoSubtitle1',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: 'SEO Description 1',
          key: 'seoDescription1',
          type: 'wyswyg',
          validationType: 'text',
          length: 2000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'seoSubtitle1',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: 'SEO Subtitle 2',
          key: 'seoSubtitle2',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: 'SEO Description 2',
          key: 'seoDescription2',
          type: 'wyswyg',
          validationType: 'text',
          length: 2000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'seoSubtitle2',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: 'SEO Subtitle 3',
          key: 'seoSubtitle3',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: 'SEO Description 3',
          key: 'seoDescription3',
          type: 'wyswyg',
          validationType: 'text',
          length: 2000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'seoSubtitle3',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: 'SEO Subtitle 4',
          key: 'seoSubtitle4',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: 'SEO Description 4',
          key: 'seoDescription4',
          type: 'wyswyg',
          validationType: 'text',
          length: 2000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'seoSubtitle4',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
        {
          title: 'SEO Subtitle 5',
          key: 'seoSubtitle5',
          type: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
        },
        {
          title: 'SEO Description 5',
          key: 'seoDescription5',
          type: 'wyswyg',
          validationType: 'text',
          length: 2000,
          mandatory: false,
          disabled: false,
          initialDisabled: false,
          initialMandatory: false,
          initialValue: '',
          behaviours: [
            [
              {
                key: 'seoSubtitle5',
                operand: 'anyValue',
                type: 'mandatory',
              },
            ],
          ],
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatGridDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
  };
};

export default connect(mapStateToProps)(WebeatGridDashboard);
