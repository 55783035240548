import { Card, Col, Icon, Row, Select } from 'antd';
import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { SetCurrentPlatform, setCurrentPlatform } from '../app/appActions';
import { MenuItem } from '../app/AppInterfaces';
import { navigate } from '../app/queryActions';
import { logout } from '../auth/authActions';

import { ComboData } from '../combos/ComboInterfaces';
import config from '../config';
import IconMenu from '../iconMenu';
import { PIN_PlatformID_Enum } from '../shared';
import './Welcome.css';

export const renderPlatformSelector = (
  platforms: ComboData[] | null,
  currentPlatform: string,
  setCurrentPlatform: (
    url: string,
    timezoneName: string,
    platform_code: string,
  ) => (dispatch: Dispatch<SetCurrentPlatform>) => void,
  extraAction?: () => void,
) => {
  if (!platforms?.length) return;

  return (
    <Select
      onChange={(value: string) => {
        const platformSelected = platforms.find(
          (platform: any) => platform.value === value,
        );

        // Use TIMEZONE_NAME ('America/New_York') as 'backup' timezone name if not other found.
        const timezoneName =
          platformSelected?.timezonename || config.APP.TIMEZONE_NAME;
        const platform_code =
          platformSelected?.platform_code || PIN_PlatformID_Enum.PIN_USA;

        setCurrentPlatform(value, timezoneName, platform_code);
        if (extraAction) extraAction();
      }}
      value={currentPlatform}>
      {platforms.map(({ description, value }: ComboData) => (
        <Select.Option key={value} value={value}>
          {description}
        </Select.Option>
      ))}
    </Select>
  );
};

interface OwnProps {
  intl: any;
}

type TWelcomeListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class Welcome extends PureComponent<TWelcomeListProps & OwnProps, {}> {
  renderMenu = (menu: MenuItem[]) => {
    const menuItems: any[] = [];
    menu.forEach(
      ({
        id,
        route,
        nameOfMenu,
        icon,
        subMenu,
        welcome,
        disabled,
        mustRender,
      }) => {
        if (mustRender && !disabled && welcome)
          menuItems.push(this.renderCard(id, route, nameOfMenu, icon));
        if (!isEmpty(subMenu)) menuItems.push(this.renderMenu(subMenu));
      },
    );
    return menuItems;
  };

  renderCard = (
    id: number | string,
    route: string,
    nameOfMenu: string,
    icon?: number,
  ) => {
    return (
      <Col key={id}>
        <div
          onClick={() =>
            this.props.navigate({
              path: route,
              name: nameOfMenu,
              icon,
              absolute: true,
            })
          }>
          <Card key={id} className="menuWrapper__card">
            <div className="icon">{IconMenu({ id: icon })}</div>
          </Card>
        </div>

        <p className="menuWrapper__text">
          {nameOfMenu}
          {/* {this.props.intl.formatMessage({ id: 'welcome.menuName.' + id })} */}
        </p>
      </Col>
    );
  };

  render() {
    const {
      currentPlatform,
      setCurrentPlatform,
      logout,
      menu,
      intl,
      platforms,
    } = this.props;

    const extraAction: () => void = () => {
      window.location.reload();
    };

    return (
      <Row className="welcome">
        <Col md={8} xs={0} className="welcome__leftContent">
          <Row type="flex" justify="space-around" align="middle">
            <Col span={4}></Col>
            <Col span={16} className="welcome__leftContent">
              <Row type="flex" align="middle" style={{ height: '90vh' }}>
                <Row className="text--center">
                  <div className="logo" />
                  {intl.formatMessage({ id: 'home.welcome_msg' })}
                </Row>
                {renderPlatformSelector(
                  platforms,
                  currentPlatform,
                  setCurrentPlatform,
                  extraAction,
                )}
              </Row>
              <Row></Row>
              <Row className="logout" style={{ height: '10vh' }}>
                <Icon className="icon" onClick={logout} type="logout" />
                <label
                  id="logout--text"
                  onClick={logout}
                  className="logout--text">
                  {intl.formatMessage({ id: 'logout.title' })}
                </label>
              </Row>
            </Col>
            <Col span={4}></Col>
          </Row>
        </Col>
        <Col md={16} xs={24} className="welcome__rightContent">
          <Row type="flex" justify="space-around" className="menuWrapper">
            {!isEmpty(menu) ? this.renderMenu(menu) : false}
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ logout, navigate, setCurrentPlatform }, dispatch);

const mapStateToProps = (state: any) => ({
  currentPlatform: state.app.currentPlatform,
  menu: state.app.menu,
  platforms: state.app.platforms,
});

const IntejectedWelcome = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Welcome),
);

export default function () {
  const intl = useIntl();
  return <IntejectedWelcome intl={intl} />;
}
