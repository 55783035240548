import { m2mTypes } from './m2mTypes';
import { M2MActionTypes } from './m2mActions';
import { QueryParams, TableData } from '../tables/tableInterfaces';

export interface M2MState {
  visible: boolean;
  queryParams: QueryParams;
  data: TableData;
  selectedRecordsId: number[];
  isLoading: boolean;
  activeDashboard: string;
  getSelected: boolean;
}

const initialState: M2MState = {
  visible: false,
  queryParams: {},
  data: {} as TableData,
  selectedRecordsId: [],
  isLoading: false,
  activeDashboard: '',
  getSelected: false
};

export function m2mReducer(
  state = initialState,
  action: M2MActionTypes
): M2MState {
  switch (action.type) {
    // case m2mTypes.M2M_SET_INITIAL_STATE:
    //   return {
    //     ...state,
    //     searchFields: action.payload.searchFields,
    //     tableFields: action.payload.tableFields,
    //     queryParams: action.payload.queryParams,
    //     mainKey: action.payload.mainKey,
    //     mainKeyValue: action.payload.mainKeyValue
    //   };
    case m2mTypes.M2M_RESET:
      return {
        ...state,
        queryParams: {} as QueryParams,
        data: {} as TableData,
        selectedRecordsId: [],
        getSelected: false
      };
    case m2mTypes.M2M_SET_TABLE_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.payload
        }
      };
    case m2mTypes.M2M_SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        activeDashboard: action.payload.activeDashboard
      };
    case m2mTypes.M2M_GET_SELECTED_ROWS:
      return {
        ...state,
        getSelected: action.payload.getSelected
      };
    case m2mTypes.M2M_FETCHING_RECORDS:
      return {
        ...state,
        queryParams: action.payload.queryParams,
        isLoading: action.payload.isLoading
      };
    case m2mTypes.M2M_FETCHED_RECORDS:
      return {
        ...state,
        ...action.payload
      };
    case m2mTypes.M2M_ADDING_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case m2mTypes.M2M_ADDED_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case m2mTypes.M2M_REMOVING_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case m2mTypes.M2M_REMOVED_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    // case m2mTypes.M2M_CHANGE_SELECTED_RECORDS:
    //   return {
    //     ...state,
    //     selectedRecordsId: action.payload.selectedRowKeys
    //   };
    // case m2mTypes.M2M_SET_SEARCH_VALUES:
    //   return {
    //     ...state,
    //     values: action.payload.values
    //   };
    case m2mTypes.M2M_SET_MODAL_VISIBILITY:
      return {
        ...state,
        visible: action.payload.visible
      };
    default:
      return state;
  }
}
