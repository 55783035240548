import antEn from 'antd/lib/locale-provider/en_GB';
import antEs from 'antd/lib/locale-provider/es_ES';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import en_US from '../locale/static/en_US.json';
import es_AR from '../locale/static/es_AR.json';
import es_CL from '../locale/static/es_CL.json';
import es_CO from '../locale/static/es_CO.json';
import es_ES from '../locale/static/es_ES.json';
import es_MX from '../locale/static/es_MX.json';
import es_PE from '../locale/static/es_PE.json';
import var_en_US from '../locale/var/var_en_US.json';
import var_es_AR from '../locale/var/var_es_AR.json';
import var_es_CL from '../locale/var/var_es_CL.json';
import var_es_CO from '../locale/var/var_es_CO.json';
import var_es_ES from '../locale/var/var_es_ES.json';
import var_es_MX from '../locale/var/var_es_MX.json';
import var_es_PE from '../locale/var/var_es_PE.json';
import store from '../store';

interface OwnProps {
  id: any;
}

export default class IntlMessage extends React.PureComponent<OwnProps, {}> {
  render() {
    const messages: any = {
      antdMessages: {
        'es-AR': antEs,
        'es-CL': antEs,
        'es-CO': antEs,
        'es-ES': antEs,
        'es-MX': antEs,
        'en-US': antEn,
        // 'ca-ES': antCa
      },
      appMessages: {
        'es-AR': {
          ...es_AR,
          ...var_es_AR,
        },
        'es-CL': {
          ...es_CL,
          ...var_es_CL,
        },
        'es-CO': {
          ...es_CO,
          ...var_es_CO,
        },
        'es-ES': {
          ...es_ES,
          ...var_es_ES,
        },
        'es-MX': {
          ...es_MX,
          ...var_es_MX,
        },
        'es-PE': {
          ...es_PE,
          ...var_es_PE,
        },
        'en-US': {
          ...en_US,
          ...var_en_US,
        },
        // 'ca-ES': {
        //   ...ca_ES,
        //   ...var_ca_ES
        // }
      },
    };

    const { locale } = store.getState().app;

    const currentLocale = 'en-US';

    moment.locale(locale);
    return (
      <IntlProvider
        locale={locale}
        messages={messages.appMessages[currentLocale]}>
        <FormattedMessage id={this.props.id} />
      </IntlProvider>
    );
  }
}
