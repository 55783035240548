import { Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ReducersState } from '../../../reducers';
import { editRecursiveResource } from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import { DetailsTypesEnum } from '../../Enums';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';

const { NUMBER, TEXT, TEXT_AREA, WYSWYG } = DetailsTypesEnum;

interface ISpeaker {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ISpeaker;

const Speaker: FC<IGroupProps> = ({
  _resource,
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  groupIndex,
  mainIdResource,
  values,
}) => {
  const { resourceDetailList } = _resource;

  const speakerDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) speakerDetails.push({ index, detail });
  });

  if (!speakerDetails) return <Fragment />;

  return (
    <Row gutter={[24, 0]}>
      {speakerDetails
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .sort(
          (
            {
              detail: {
                idResourceTypeD: { type: typeA },
              },
            },
            {
              detail: {
                idResourceTypeD: { type: typeB },
              },
            },
          ) => {
            if (
              typeA === WYSWYG ||
              typeA === TEXT_AREA ||
              (typeB === TEXT && typeA === NUMBER)
            )
              return 1;

            if (
              typeB === WYSWYG ||
              typeB === TEXT_AREA ||
              (typeB === NUMBER && typeA === TEXT)
            )
              return -1;

            return 0;
          },
        )
        .map(({ detail, index }) =>
          RenderDetail({
            accessToken,
            detail,
            detailArrayPosition: index,
            editResource,
            editRecursiveResource,
            form,
            handleDeleteImages,
            mainIdResource,
            resource: _resource,
            parentArrayPosition: groupIndex,
            values,
          }),
        )}
    </Row>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ editRecursiveResource }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Speaker);
