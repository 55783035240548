import { IPageDetail } from '.';
import { ComboData } from '../../combos/ComboInterfaces';

export const _blankDetail = (
  idContentComponent: number,
  type: number,
): IPageDetail => {
  return {
    idContentComponent,
    order: 1,
    position: '0',
    slug: '',
    status: true,
    type,
    value: '',
  };
};

export const positionCombo: ComboData[] = [
  { value: '0', description: 'Full' },
  { value: '1', description: 'Left' },
  { value: '2', description: 'Right' },
];

export const iconCombo: ComboData[] = [
  { value: '0', description: 'None' },
  { value: '1', description: 'Learn' },
  { value: '2', description: 'Event' },
  { value: '3', description: 'Request order' },
  { value: '4', description: 'Document' },
  { value: '5', description: 'Gift' },
  { value: '6', description: 'Address' },
  { value: '7', description: 'Campaign' },
];
