import { Dispatch } from 'redux';
import { editTypes } from './editTypes';
import { ReducersState } from '../../reducers';
import * as api from '../../api';

import { IEditField } from '../../fields/FieldsInterfaces';
import { IGroupField } from '../formInterfaces';
import { IRow } from '../../app/AppInterfaces';
import { SetChallengeLoading } from '../../challenge/challengeActions';
import { challengeTypes } from '../../challenge/challengeTypes';
import { notification } from 'antd';

const flatten = require('flat').flatten;
const unflatten = require('flat').unflatten;

interface SetEditInitialState {
  type: editTypes.SET_INITIAL_EDIT_STATE;
  payload: { componentId: string };
}

export const setEditInitialState =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<SetEditInitialState>) => {
    dispatch({
      type: editTypes.SET_INITIAL_EDIT_STATE,
      payload: { componentId },
    });
  };

interface SetInitialStateAction {
  type: editTypes.EDIT_INITIAL_STATE;
  payload: {
    componentId: string;
    targetId: string;
    fieldsConfig: IGroupField[];
    values: object;
    selectedTab: string;
    resetFormTrigger: boolean;
    isLoading: boolean;
  };
}

export const setInitialState =
  ({
    componentId,
    targetId,
    fieldsConfig,
    values,
    selectedTab,
    resetFormTrigger,
  }: {
    componentId: string;
    targetId: string;
    fieldsConfig: IGroupField[];
    values: any;
    selectedTab: string;
    resetFormTrigger: boolean;
  }) =>
  async (dispatch: Dispatch<SetInitialStateAction>) => {
    dispatch({
      type: editTypes.EDIT_INITIAL_STATE,
      payload: {
        componentId,
        targetId,
        fieldsConfig,
        values,
        selectedTab,
        resetFormTrigger,
        isLoading: false,
      },
    });
  };

interface ResetEditComponentAction {
  type: editTypes.EDIT_RESET_COMPONENT;
  payload: { componentId: string };
}

export const resetEditComponent =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<ResetEditComponentAction>) => {
    dispatch({
      type: editTypes.EDIT_RESET_COMPONENT,
      payload: { componentId },
    });
  };

interface SetEditDataAction {
  type: editTypes.EDIT_SET_VALUES;
  payload: { componentId: string; values: IRow };
}

export const setFormData =
  ({ componentId, values }: { componentId: string; values: IRow }) =>
  async (dispatch: Dispatch<SetEditDataAction>) => {
    dispatch({
      type: editTypes.EDIT_SET_VALUES,
      payload: { componentId, values },
    });
  };

interface FetchParentData {
  type: editTypes.EDIT_FETCH_PARENT_DATA;
  payload: { componentId: string; data: { [key: string]: string } };
}

export const fetchParentData =
  ({ componentId, data }: { componentId: string; data: IRow }) =>
  (dispatch: Dispatch<FetchParentData>) => {
    dispatch({
      type: editTypes.EDIT_FETCH_PARENT_DATA,
      payload: { componentId, data },
    });
  };

interface FetchingForeignData {
  type: editTypes.EDIT_FETCHING_DATA;
  payload: { componentId: string; isLoading: boolean };
}

interface FetchedForeignData {
  type: editTypes.EDIT_FETCHED_DATA;
  payload: { componentId: string; isLoading: boolean };
}

export const fetchForeignData =
  ({
    dataPath,
    componentId,
    foreignKey,
    foreignValue,
  }: {
    dataPath: string;
    componentId: string;
    foreignKey: string;
    foreignValue: string;
  }) =>
  async (
    dispatch: Dispatch<FetchingForeignData | FetchedForeignData>,
    getState: () => ReducersState,
  ) => {
    dispatch({
      type: editTypes.EDIT_FETCHING_DATA,
      payload: { componentId, isLoading: true },
    });
    try {
      const currentValues = getState().edits[componentId].values;
      const callConfig = {};
      const response = await api.getDataCallById({
        dataPath,
        registerId: +foreignValue,
        callConfig,
      });
      Object.keys(currentValues).forEach((val) => {
        if (foreignKey === val.split('.')[0]) {
          delete currentValues[val];
        }
      });
      dispatch({
        type: editTypes.EDIT_FETCHED_DATA,
        payload: { componentId, isLoading: false },
      });
      const newValues = flatten(
        { [foreignKey]: response.data },
        { safe: true },
      );

      const status = {
        action: 'fetch',
        status: response.status,
        data: newValues,
      };
      return status;
    } catch (err) {
      if (!err.response) return { action: 'fetch', status: {} };
      const status = { action: 'fetch', status: err.response.status };
      notification.error({
        message: err.response.data.message,
      });
      return status;
    }
  };

export interface UpdatingEditData {
  type: editTypes.EDIT_SENDING_DATA;
  payload: { componentId: string; isLoading: boolean };
}

export interface UpdatedEditData {
  type: editTypes.EDIT_SENT_DATA;
  payload: { componentId: string; isLoading: boolean };
}

export interface UpdateEditError {
  type: editTypes.EDIT_SENDING_DATA_ERROR;
  payload: { componentId: string; isLoading: boolean };
}

export const updateEditData =
  ({
    dataPath,
    componentId,
    values,
  }: {
    dataPath: string;
    componentId: string;
    values: IRow;
  }) =>
  async (
    dispatch: Dispatch<UpdatingEditData | UpdatedEditData | UpdateEditError>,
    getState: () => ReducersState,
  ) => {
    dispatch({
      type: editTypes.EDIT_SENDING_DATA,
      payload: { componentId, isLoading: true },
    });
    const checkedValues = customBehaviors(values, componentId);
    try {
      const callConfig = {};
      const response = await api.putDataCall({
        dataPath,
        data: unflatten(checkedValues),
        callConfig,
      });
      dispatch({
        type: editTypes.EDIT_SENT_DATA,
        payload: { componentId, isLoading: false },
      });
      const status = {
        action: 'update',
        status: response.status,
        data: response.data,
      };
      return status;
    } catch (err) {
      dispatch({
        type: editTypes.EDIT_SENDING_DATA_ERROR,
        payload: { componentId, isLoading: false },
      });
      if (!err.response) return { action: 'update', status: {} };
      const status = {
        action: 'update',
        status: err.response.status,
        message: err.response.data.message,
      };
      notification.error({
        message: err.response.data.message,
      });
      return status;
    }
  };

const verifiedFormCheckFields = (formFields: IEditField[], values: IRow) => {
  let checkValues: IRow = {};
  formFields.forEach((f: IEditField) => {
    if (
      (f.type === 'checkSelect' && !values[f.key]) ||
      (f.type === 'switch' && !values[f.key])
    ) {
      checkValues[f.key] = false;
    }
  });
  return checkValues;
};

interface CreatingEditData {
  type: editTypes.EDIT_CREATING_DATA;
  payload: { componentId: string; isLoading: boolean };
}

interface CreatedEditData {
  type: editTypes.EDIT_CREATED_DATA;
  payload: { componentId: string; isLoading: boolean };
}

interface CreateEditDataError {
  type: editTypes.EDIT_SENDING_DATA_ERROR;
  payload: { componentId: string; isLoading: boolean };
}

export const createEditData =
  ({
    dataPath,
    componentId,
    values,
  }: {
    dataPath: string;
    componentId: string;
    values: IRow;
  }) =>
  async (
    dispatch: Dispatch<
      CreatingEditData | CreatedEditData | CreateEditDataError
    >,
    getState: any,
  ) => {
    dispatch({
      type: editTypes.EDIT_CREATING_DATA,
      payload: { componentId, isLoading: true },
    });
    try {
      const formFields = getState().edits[componentId].fieldsConfig;
      const valuesVerified: IRow = {
        ...values,
        ...verifiedFormCheckFields(formFields, values),
      };
      const callConfig = {};
      const response = await api.postDataCall({
        dataPath,
        data: unflatten(valuesVerified),
        callConfig,
      });
      dispatch({
        type: editTypes.EDIT_CREATED_DATA,
        payload: { componentId, isLoading: false },
      });

      const status = {
        action: 'create',
        status: response.status,
        data: response.data,
      };
      return status;
    } catch (err) {
      dispatch({
        type: editTypes.EDIT_SENDING_DATA_ERROR,
        payload: { componentId, isLoading: false },
      });

      if (!err.response) return { action: 'create', status: {} };
      const status = {
        action: 'create',
        status: err.response.status,
        message: err.response.data.message,
      };
      notification.error({
        message: err.response.data.message,
      });
      return status;
    }
  };

interface SetSelectedTabEdit {
  type: editTypes.EDIT_CHANGE_TAB;
  payload: { componentId: string; selectedTab: string };
}

export const setSelectedTabEdit =
  ({
    componentId,
    selectedTab,
  }: {
    componentId: string;
    selectedTab: string;
  }) =>
  (dispatch: Dispatch<SetSelectedTabEdit>) => {
    dispatch({
      type: editTypes.EDIT_CHANGE_TAB,
      payload: { componentId, selectedTab },
    });
  };

interface SetFieldsConfig {
  type: editTypes.EDIT_SET_FIELDSCONFIG;
  payload: {
    componentId: string;
    newFieldsConfig: IGroupField[];
    resetFormTrigger: boolean;
  };
}

export const setFieldsConfig =
  (componentId: string, newFieldsConfig: IGroupField[]) =>
  (dispatch: Dispatch<SetFieldsConfig>) => {
    dispatch({
      type: editTypes.EDIT_SET_FIELDSCONFIG,
      payload: { componentId, newFieldsConfig, resetFormTrigger: true },
    });
  };

interface SetResetFormTrigger {
  type: editTypes.EDIT_CLEAN_RESET_FORM_TRIGGER;
  payload: { componentId: string; resetFormTrigger: boolean };
}

export const setResetFormTrigger =
  ({ componentId }: { componentId: string }) =>
  (dispatch: Dispatch<SetResetFormTrigger>) => {
    dispatch({
      type: editTypes.EDIT_CLEAN_RESET_FORM_TRIGGER,
      payload: { componentId, resetFormTrigger: false },
    });
  };

interface ResetEditForm {
  type: editTypes.EDIT_RESET_FORM;
  payload: {
    componentId: string;
    values: IRow;
    fieldsConfig: IGroupField[];
    resetFormTrigger: boolean;
  };
}

export const resetEditForm =
  ({
    componentId,
    fieldsConfig,
    values,
  }: {
    componentId: string;
    fieldsConfig: IGroupField[];
    values: IRow;
  }) =>
  (dispatch: Dispatch<ResetEditForm>) => {
    dispatch({
      type: editTypes.EDIT_RESET_FORM,
      payload: { componentId, values, fieldsConfig, resetFormTrigger: true },
    });
  };

/**
 * This is an asyncronous call that allows you to add a new element inside the database and adding it to redux as well
 *
 * @param {string} dataPath the path of the element we want to add/modify
 * @param {string} componentId the name of the element inside of the components params, with this we just can identify the object
 * @param {Object} values the new values of the object
 *
 * @throws {Error} if the call is unsuccessfull
 * @returns {Object} the result of the asyncronous call to the server
 */
export const createTableData =
  ({
    dataPath,
    componentId,
    values,
  }: {
    dataPath: string;
    componentId: string;
    values: object;
  }) =>
  async (dispatch: any) => {
    dispatch({
      type: editTypes.EDIT_CREATING_DATA,
      payload: { componentId, isLoading: true },
    });
    try {
      const callConfig = {};
      const response = await api.postDataCall({
        dataPath,
        data: unflatten(values),
        callConfig,
      });
      dispatch({
        type: editTypes.EDIT_CREATED_DATA,
        payload: { componentId, isLoading: false },
      });
      const status = {
        action: 'create',
        status: response.status,
        data: response.data,
      };
      return status;
    } catch (err) {
      dispatch({
        type: editTypes.EDIT_SENDING_DATA_ERROR,
        payload: { componentId, isLoading: false },
      });
      if (!err.response) return { action: 'create', status: {} };
      const status = {
        action: 'create',
        status: err.response.status,
        message: err.response.data.message,
      };
      notification.error({
        message: err.response.data.message,
      });
      return status;
    }
  };

export const updateChallengeData =
  ({
    dataPath,
    componentId,
    values,
  }: {
    dataPath: string;
    componentId: string;
    values: IRow;
  }) =>
  async (dispatch: Dispatch<SetChallengeLoading>) => {
    dispatch({
      type: challengeTypes.CHALLENGE_SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const callConfig = {};

      const challengeValues = { ...values };

      challengeValues.coverVideo =
        values.coverVideo && values.coverVideo !== undefined
          ? values.coverVideo
          : '';
      delete challengeValues.resourceList;
      delete challengeValues.centerRestrictionList;
      delete challengeValues.challengeI18nList;
      delete challengeValues.challengeL10nList;

      const response = await api.putDataCall({
        dataPath,
        data: { ...challengeValues },
        callConfig,
      });

      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: false },
      });

      const status = {
        action: 'update',
        status: response.status,
        data: response.data,
      };
      return status;
    } catch (err) {
      dispatch({
        type: challengeTypes.CHALLENGE_SET_LOADING,
        payload: { isLoading: false },
      });
      if (!err.response) return { action: 'update', status: {} };
      const status = {
        action: 'update',
        status: err.response.status,
        message: err.response.data.message,
      };
      notification.error({
        message: err.response.data.message,
      });
      return status;
    }
  };

export type EditActionTypes =
  | FetchParentData
  | SetSelectedTabEdit
  | UpdatingEditData
  | UpdatedEditData
  | UpdateEditError
  | FetchingForeignData
  | FetchedForeignData
  | ResetEditForm
  | SetResetFormTrigger
  | SetEditInitialState
  | SetInitialStateAction
  | ResetEditComponentAction
  | SetEditDataAction
  | SetFieldsConfig
  | CreatingEditData
  | CreatedEditData
  | CreateEditDataError
  | SetChallengeLoading;

/* ----- UTILS ------*/
const customBehaviors = (values: IRow, componentId: string) => {
  let checkedVales = { ...values };

  if (componentId === 'surveyEdit') {
    if (checkedVales.score === null) delete checkedVales['score'];
  }
  return checkedVales;
};
