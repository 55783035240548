import { Button, Checkbox, Col, Collapse, InputNumber, Row } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import React, { FC, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ComboData } from '../../combos/ComboInterfaces';
import { isConPlatform } from '../../utils';
import '../Challenge.css';
import {
  IEditScoreBySpecialty,
  IResource,
  IScore,
} from '../ChallengeInterfaces';
import { ChallengeTypeEnum, ResourceTypeEnum } from '../Enums';
import {
  buildSpecialtyScore,
  getMaxChildScore,
  getResourceChildPoints,
  getResourcesByType,
  setScore,
} from './utils';

const {
  ACTIVATION_BUTTON,
  ANSWER,
  ATTEND,
  CHALLENGE_MATERIALS,
  CHECKOUT,
  CHECKOUT_PRODUCT,
  EVENT,
  FAQ,
  INSCRIPTION,
  MATERIAL,
  NOTIFICATION,
  PARTICIPATION,
  REDEEM_PRODUCT,
  QUESTION,
  QUIZ,
  QUIZ_DERMO,
  QUIZ_TYPEFORM,
  SHOPPING_CART,
  VIDEO,
  CHALLENGE_VISIBILITY,
  SELL_OUT,
  SELL_OUT_POINT_RANGE,
  SELL_IN,
  SELL_IN_POINTS_RANGE,
  SURVEY,
  ARN_SURVEY,
  PEARL,
  LINKED_CHALLENGES,
  CHECKOUT_LOTTERY,
  CHECKOUT_DONATION,
  BEAUTY_GUIDE,
  CHECKOUT_BOOKING_TIMIFY,
  CERTIFIED_COURSE,
} = ResourceTypeEnum;

interface Props extends FormComponentProps {
  resourceList: IResource[];
  idChallengeType: number;
  setResources: React.Dispatch<React.SetStateAction<IResource[] | null>>;
  setPointsHaveChanged: React.Dispatch<React.SetStateAction<boolean>>;
  specialtyCombo: ComboData[];
}

const AdminPointsForm: FC<Props> = ({
  form,
  idChallengeType,
  resourceList,
  setResources,
  setPointsHaveChanged,
  specialtyCombo,
}) => {
  const intl = useIntl();

  const handleEditScore = (idResource: number, score: number | undefined) => {
    const points = score ? score : 0;
    let resources = resourceList.slice();

    setPointsHaveChanged(true);

    resources.forEach((_resource) => {
      if (_resource.idResource === idResource) {
        _resource.score = setScore(_resource, points);
      } else {
        if (_resource.resourceList && _resource.resourceList.length) {
          _resource.resourceList.forEach((_resourceChild) => {
            if (_resourceChild.idResource === idResource) {
              _resourceChild.score = setScore(_resourceChild, points);
            } else {
              if (
                _resourceChild.resourceList &&
                _resourceChild.resourceList.length
              ) {
                _resourceChild.resourceList.forEach((_r) => {
                  if (_r.idResource === idResource)
                    _r.score = setScore(_r, points);
                });
              }
            }
          });
        }
      }
    });

    setResources(resources);
  };

  const handleEditScoreBySpecialty = (params: IEditScoreBySpecialty) => {
    setPointsHaveChanged(true);
    const { idResource, idScore, idSpecialty, points } = params;

    const targetResource = findTargetResource(resourceList, idResource);
    const scoresSpecialty = [...(targetResource?.scoresSpecialty || [])];
    const scoreSpecialty = scoresSpecialty.find(
      (score) => score.idSpecialty?.idSpecialty === idSpecialty,
    );

    if (scoreSpecialty) {
      scoreSpecialty.points = points;
    } else {
      scoresSpecialty.push(
        buildSpecialtyScore(targetResource, idScore, idSpecialty, points),
      );
    }

    targetResource.scoresSpecialty = scoresSpecialty;

    setResources(resourceList);
  };

  const findTargetResource = (
    resources: IResource[],
    idResource: number,
  ): IResource => {
    let targetResource;
    const filterResource = (_r: IResource) => _r.idResource === idResource;

    targetResource = resources.find(filterResource);

    if (!targetResource) {
      resources
        .filter(({ resourceList }) => resourceList?.length)
        .forEach((resouce) => {
          if (!resouce.resourceList?.length) return;

          const _resource = findTargetResource(
            resouce.resourceList,
            idResource,
          );

          if (_resource) targetResource = _resource;
        });
    }

    return targetResource as IResource;
  };

  const renderInput = (resource: IResource, disabled: boolean = false) => {
    const initialValue = resource?.score?.points || 0;

    let min = -100000,
      max = 100000;

    switch (idChallengeType) {
      case ChallengeTypeEnum.CONTEST:
      case ChallengeTypeEnum.FORMATION_CON:
        min = 0;
        break;
      case ChallengeTypeEnum.EXPERIENCE:
        max = 0;
        break;
      default:
        break;
    }

    return form.getFieldDecorator(resource.idResource.toString(), {
      initialValue,
    })(
      <InputNumber
        disabled={disabled}
        max={max}
        min={min}
        onChange={(value: number | undefined) =>
          handleEditScore(resource.idResource, value)
        }
        size="small"
        style={{ width: '100%' }}
      />,
    );
  };

  const renderSpecialty = (resource: IResource, specialty: ComboData) => {
    return (
      <Row
        type="flex"
        className="pointsModal__row--answer"
        key={resource.idResource}>
        <Col xs={14}>{specialty.description}</Col>
        <Col xs={10}>
          {renderInputSpecialty(resource, parseInt(specialty.value))}
        </Col>
      </Row>
    );
  };

  const renderInputAndButtonCollapsableTitle = (resource: IResource) => {
    return (
      <Row
        type="flex"
        className="pointsModal__row--answer"
        key={resource.idResource}
        justify="space-between">
        <Col xs={10} onClick={(e) => e.stopPropagation()}>
          {renderInput(resource)}
        </Col>
        <Col xs={10}>
          <Button type="primary">
            {intl.formatMessage({
              id: 'challenge.button.showSpecialties',
            })}
          </Button>
        </Col>
      </Row>
    );
  };

  const renderInputSpecialty = (resource: IResource, idSpecialty: number) => {
    const specialtyScore =
      resource.scoresSpecialty &&
      resource.scoresSpecialty?.find((score: IScore) => {
        return score.idSpecialty?.idSpecialty === idSpecialty;
      });
    const idScore = specialtyScore?.idScore ? specialtyScore?.idScore : -1;
    const initialValue = specialtyScore?.points ?? 0;

    return form.getFieldDecorator(
      `${resource.idResource}-${idScore}-${idSpecialty}`,
      {
        initialValue,
      },
    )(
      <InputNumber
        max={100000}
        min={-100000}
        onChange={(value: number | undefined) =>
          handleEditScoreBySpecialty({
            idResource: resource.idResource,
            idScore,
            idSpecialty,
            points: value ?? 0,
          })
        }
        size="small"
        style={{ width: '100%' }}
      />,
    );
  };

  /**
   *
   * @param resources
   */
  const renderResourceList = (resources: IResource[]) => {
    return resources
      ?.filter(({ status }) => status)
      .sort(function (a: IResource, b: IResource) {
        return a.order - b.order;
      })
      .map((_resource) => renderResource(_resource));
  };

  const renderResource = (resource: IResource): any => {
    switch (resource.idResourceType.idResourceType) {
      case FAQ:
      case NOTIFICATION:
        return <Fragment key={resource.idResource} />;
      case SELL_OUT:
        if (!resource?.resourceList?.length) return <Fragment />;

        const [rangeResources, mainResources] = getResourcesByType(
          resource.resourceList,
          SELL_OUT_POINT_RANGE,
        );

        const signInPointsTitle: string = intl.formatMessage({
          id: 'points-admin.sign.in',
        });

        return (
          <>
            {isConPlatform() ? (
              <Row className="pointsModal__quiz" key={resource.idResource}>
                <Row className="pointsModal__quiz--title">
                  {/* TODO: use int message to format */}
                  <span style={{ fontWeight: 700 }}>
                    {resource.idResourceType.name}
                  </span>{' '}
                  - {resource.name}
                  {mainResources
                    ?.sort(function (a: IResource, b: IResource) {
                      return a.order - b.order;
                    })
                    .map((_resource) => renderResource(_resource))}
                  <Row justify="space-between">
                    <Col span={16}>
                      <b>{signInPointsTitle}</b>
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                      <Form.Item>{renderInput(resource)}</Form.Item>
                    </Col>
                  </Row>
                  {rangeResources?.length > 0 && (
                    <>
                      <Row className="pointsModal__quiz--questionsTitle">
                        <FormattedMessage id="tree-resource.titles_ranges" />
                      </Row>

                      {rangeResources
                        .sort(function (a: IResource, b: IResource) {
                          return a.order - b.order;
                        })
                        .map((_resource) => renderResource(_resource))}
                    </>
                  )}
                </Row>
              </Row>
            ) : (
              <Row className="pointsModal__quiz" key={resource.idResource}>
                <Row className="pointsModal__quiz--title">
                  {/* TODO: use int message to format */}
                  <span style={{ fontWeight: 700 }}>
                    {resource.idResourceType.name}
                  </span>{' '}
                  - {resource.name}
                  {mainResources
                    ?.sort(function (a: IResource, b: IResource) {
                      return a.order - b.order;
                    })
                    .map((_resource) => renderResource(_resource))}
                  <Collapse
                    expandIconPosition="right"
                    className="pointsModal__collapsible">
                    <Collapse.Panel
                      header={
                        <div className="panelHeader">{signInPointsTitle}</div>
                      }
                      key={resource.idResource}
                      extra={renderInputAndButtonCollapsableTitle(resource)}>
                      <div className="panelChildren">
                        {specialtyCombo &&
                          specialtyCombo.map((specialty) =>
                            renderSpecialty(resource, specialty),
                          )}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                  {rangeResources?.length > 0 && (
                    <>
                      <Row className="pointsModal__quiz--questionsTitle">
                        <FormattedMessage id="tree-resource.titles_ranges" />
                      </Row>

                      {rangeResources
                        .sort(function (a: IResource, b: IResource) {
                          return a.order - b.order;
                        })
                        .map((_resource) => renderResource(_resource))}
                    </>
                  )}
                </Row>
              </Row>
            )}
          </>
        );
      case SELL_IN:
        if (!resource?.resourceList?.length) return <Fragment />;

        const [, imageMainResources] = getResourcesByType(
          resource.resourceList,
          SELL_OUT_POINT_RANGE,
        );
        return (
          <Row className="pointsModal__quiz" key={resource.idResource}>
            <Row className="pointsModal__quiz--title">
              {/* TODO: use int message to format */}
              <span
                style={{ fontWeight: 700 }}
                children={resource.idResourceType.name}
              />
              <span children={` - ${resource.name}`} />

              {imageMainResources?.length > 0 && (
                <>
                  <Row className="pointsModal__quiz--questionsTitle">
                    <FormattedMessage id="tree-resource.sell_in_titles_ranges" />
                  </Row>

                  {imageMainResources
                    .sort(function (a: IResource, b: IResource) {
                      return a.order - b.order;
                    })
                    .map((_resource) => renderResource(_resource))}
                </>
              )}
            </Row>
          </Row>
        );
      case QUIZ:
      case QUIZ_DERMO:
        if (!resource?.resourceList?.length) return <Fragment />;

        const [questions, restResource] = getResourcesByType(
          resource.resourceList,
          QUESTION,
        );

        return (
          <Row className="pointsModal__quiz" key={resource.idResource}>
            <Row className="pointsModal__quiz--title">
              {/* TODO: use int message to format */}
              <span style={{ fontWeight: 700 }}>
                {resource.idResourceType.name}
              </span>{' '}
              - {resource.name}
              <Row className="m-t-10">
                {renderResourceList(restResource)}
                {questions?.length > 0 && (
                  <>
                    <Row className="pointsModal__quiz--questionsTitle">
                      QUESTIONS
                    </Row>
                    {questions
                      .filter(({ status }) => status)
                      .sort(function (a: IResource, b: IResource) {
                        return a.order - b.order;
                      })
                      .map((_resource) => renderResource(_resource))}
                  </>
                )}
              </Row>
            </Row>
          </Row>
        );
      case EVENT:
        return (
          <Row className="pointsModal__talk" key={resource.idResource}>
            <Row className="pointsModal__talk--title">
              {/* TODO: use int message to format */}
              <span style={{ fontWeight: 700 }}>
                {resource.idResourceType.name}
              </span>{' '}
              - {resource.name}
              <Row className="m-t-10">
                {resource.resourceList
                  ? renderResourceList(resource.resourceList)
                  : null}
              </Row>
            </Row>
          </Row>
        );
      case QUESTION:
        return (
          <Row className="pointsModal__question" key={resource.idResource}>
            -{' '}
            <Row className="pointsModal__question--title">{resource.name}</Row>
            {resource.resourceList
              ? renderResourceList(resource.resourceList)
              : null}
          </Row>
        );
      case ANSWER:
        let checkedValue = false;
        if (
          resource.resourceDetailList?.length &&
          resource.resourceDetailList[0].value === 'true'
        ) {
          checkedValue = true;
        }
        return (
          <>
            {isConPlatform() ? (
              <Row
                type="flex"
                className="pointsModal__row--answer"
                key={resource.idResource}>
                <Col xs={2}>
                  <Checkbox disabled defaultChecked={checkedValue} />
                </Col>
                <Col xs={14}>{resource.name}</Col>
                <Col xs={8}>{renderInput(resource)}</Col>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={
                    <div className="panelHeader">
                      {' '}
                      <Checkbox
                        className="m-r-10"
                        disabled
                        defaultChecked={checkedValue}
                      />{' '}
                      {resource.name}
                    </div>
                  }
                  key={resource.idResource}
                  extra={renderInputAndButtonCollapsableTitle(resource)}>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );
      case LINKED_CHALLENGES:
        return (
          <Row className="pointsModal__talk" key={resource.idResource}>
            <Row className="pointsModal__talk--title">
              <Col xs={14}>
                <span style={{ fontWeight: 700 }}>
                  {resource.idResourceType.name}
                </span>
                {`  - ${resource.name}`}
              </Col>
              <Col xs={8}>
                <InputNumber
                  max={100000}
                  min={-100000}
                  disabled
                  value={resource.score?.points ?? 0}
                  size="small"
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </Row>
        );
      case SURVEY:
        return (
          <>
            {isConPlatform() ? (
              <Row className="pointsModal__talk" key={resource.idResource}>
                <Row className="pointsModal__talk--title">
                  <Col xs={14}>
                    <span style={{ fontWeight: 700 }}>
                      {resource.idResourceType.name}
                    </span>{' '}
                    - {resource.name}
                  </Col>
                  <Col xs={8}>
                    <InputNumber
                      max={100000}
                      min={-100000}
                      disabled
                      value={resource.score?.points ?? 0}
                      size="small"
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={<div className="panelHeader">{resource.name}</div>}
                  key={resource.idResource}
                  extra={renderInputAndButtonCollapsableTitle(resource)}>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );

      case CHECKOUT:
      case CHALLENGE_MATERIALS:
        const isCheckout = resource.idResourceType.idResourceType === CHECKOUT;
        const type = isCheckout ? CHECKOUT_PRODUCT : MATERIAL;

        const points = isCheckout
          ? getMaxChildScore(resource)
          : getResourceChildPoints(resource, type);
        return (
          <>
            {isConPlatform() ? (
              <Row className="pointsModal__talk" key={resource.idResource}>
                <Row className="pointsModal__talk--title">
                  <Col xs={16}>
                    <span style={{ fontWeight: 700 }}>
                      {resource.idResourceType.name}
                    </span>
                    {`  - ${resource.name}`}
                  </Col>
                  <Col xs={8}>
                    <InputNumber
                      style={{
                        backgroundColor: 'transparent',
                        fontWeight: 700,
                        width: '98%',
                        color: 'black',
                      }}
                      disabled
                      value={resource !== null ? points : 0}
                      size="small"
                    />
                  </Col>
                </Row>
                <Row>
                  {resource.resourceList
                    ? renderResourceList(resource.resourceList)
                    : null}
                </Row>
              </Row>
            ) : (
              <Row className="pointsModal__quiz" key={resource.idResource}>
                <Row className="pointsModal__quiz--title">
                  {/* TODO: use int message to format */}
                  <span
                    style={{ fontWeight: 700 }}
                    children={resource.idResourceType.name}
                  />
                  <span children={` - ${resource.name}`} />
                  <Row className="m-t-10">
                    <>
                      {resource.resourceList
                        ? renderResourceList(resource.resourceList)
                        : null}
                    </>
                  </Row>
                </Row>
              </Row>
            )}
          </>
        );
      case ATTEND:
      case INSCRIPTION:
      case PARTICIPATION:
      case MATERIAL:
      case CHECKOUT_PRODUCT:
        return (
          <>
            {isConPlatform() ? (
              <Row
                type="flex"
                className="pointsModal__row--answer"
                key={resource.idResource}>
                <Col xs={14}>{resource.name}</Col>
                <Col xs={8}>{renderInput(resource)}</Col>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={<div className="panelHeader">{resource.name}</div>}
                  key={resource.idResource}
                  extra={renderInputAndButtonCollapsableTitle(resource)}>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );
      case REDEEM_PRODUCT:
      case QUIZ_TYPEFORM:
      case SHOPPING_CART:
      case CHALLENGE_VISIBILITY:
      case PEARL:
      case CERTIFIED_COURSE:
      case CHECKOUT_LOTTERY:
      case ARN_SURVEY:
      case BEAUTY_GUIDE:
      case CHECKOUT_DONATION:
        return (
          <>
            {isConPlatform() ? (
              <Row
                type="flex"
                className="pointsModal__row"
                key={resource.idResource}>
                <Col xs={16}>
                  <Form.Item>
                    {/* TODO: use int message to format */}
                    <span style={{ fontWeight: 700 }}>
                      {resource.idResourceType.name}
                    </span>
                    {` - ${resource.name}`}
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item>{renderInput(resource)}</Form.Item>
                </Col>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={<div className="panelHeader">{resource.name}</div>}
                  key={resource.idResource}
                  extra={renderInputAndButtonCollapsableTitle(resource)}>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );
        case CHECKOUT_BOOKING_TIMIFY:
        return (
          <>
            {isConPlatform() ? (
              <Row
                type="flex"
                className="pointsModal__row"
                key={resource.idResource}>
                <Col xs={16}>
                  <Form.Item>
                    {/* TODO: use int message to format */}
                    <span style={{ fontWeight: 700 }}>
                      {resource.idResourceType.name}
                    </span>
                    {` - ${resource.name}`}
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item>{renderInput(resource)}</Form.Item>
                </Col>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={<div className="panelHeader">{resource.name}</div>}
                  key={resource.idResource}
                  extra={renderInputAndButtonCollapsableTitle(resource)}>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );
      case ACTIVATION_BUTTON:
      case VIDEO:
        return (
          <>
            {isConPlatform() ? (
              <Row
                type="flex"
                className="pointsModal__row"
                key={resource.idResource}>
                <Col xs={16}>
                  <Form.Item>
                    {/* TODO: use int message to format */}
                    <span style={{ fontWeight: 700 }}>
                      {resource.idResourceType.name}
                    </span>
                    {` - ${resource.name}`}
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item>{renderInput(resource)}</Form.Item>
                </Col>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={
                    <div className="panelHeader">{`${resource.idResourceType.name} - ${resource.name}`}</div>
                  }
                  key={resource.idResource}
                  extra={
                    <Row
                      type="flex"
                      justify={'space-between'}
                      className="pointsModal__row--answer"
                      key={resource.idResource}>
                      <Col xs={10} onClick={(e) => e.stopPropagation()}>
                        {renderInput(resource)}
                      </Col>
                      <Col xs={10}>
                        <Button type="primary">
                          {intl.formatMessage({
                            id: 'challenge.button.showSpecialties',
                          })}
                        </Button>
                      </Col>
                    </Row>
                  }>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );
      case SELL_OUT_POINT_RANGE:
        let values: number[] = [];
        resource.resourceDetailList?.forEach((element) =>
          values.push(element.value),
        );

        const [minRange, maxRange] = values;

        const labelRanges = `[ ${minRange > maxRange ? maxRange : minRange} -${
          minRange > maxRange ? minRange : maxRange
        } ] `;
        return (
          <>
            {isConPlatform() ? (
              <Row className="pointsModal__question" key={resource.idResource}>
                <Col xs={16}>
                  <Form.Item>
                    {/* TODO: use int message to format */}
                    <span style={{ fontWeight: 700 }}>
                      <FormattedMessage id="tree-resource.sell_out_points_range" />
                    </span>
                    <span style={{ fontWeight: 700 }}>{labelRanges}</span>
                  </Form.Item>
                </Col>

                <Col xs={8}>
                  <Form.Item>{renderInput(resource)}</Form.Item>
                </Col>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={
                    <div className="panelHeader">{`${intl.formatMessage({
                      id: 'tree-resource.sell_out_points_range',
                    })} ${labelRanges}`}</div>
                  }
                  key={resource.idResource}
                  extra={renderInputAndButtonCollapsableTitle(resource)}>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );
      case SELL_IN_POINTS_RANGE:
        let valueRange: string = '';
        resource.resourceDetailList?.forEach((resourceDetail) =>
          resourceDetail.value.match(/^[0-9]+$/)
            ? (valueRange = resourceDetail.value)
            : (valueRange = ''),
        );
        return (
          <>
            {isConPlatform() ? (
              <Row className="pointsModal__question" key={resource.idResource}>
                <Col xs={16}>
                  <Form.Item>
                    {/* TODO: use int message to format */}
                    <span style={{ fontWeight: 700 }}>
                      {resource.name} - Min of products: {valueRange}
                    </span>
                  </Form.Item>
                </Col>

                <Col xs={8}>
                  <Form.Item>{renderInput(resource)}</Form.Item>
                </Col>
              </Row>
            ) : (
              <Collapse
                expandIconPosition="right"
                className="pointsModal__collapsible">
                <Collapse.Panel
                  header={
                    <div className="panelHeader">{`${resource.name} - Min of products: ${valueRange}`}</div>
                  }
                  key={resource.idResource}
                  extra={renderInputAndButtonCollapsableTitle(resource)}>
                  <div className="panelChildren">
                    {specialtyCombo &&
                      specialtyCombo.map((specialty) =>
                        renderSpecialty(resource, specialty),
                      )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <Form className="pointsForm">
      {resourceList
        .sort()
        .filter(({ status }) => status)
        .map((resource) => renderResource(resource))}
    </Form>
  );
};

export default Form.create<Props>({ name: 'challenge_points_form' })(
  AdminPointsForm,
);
