import React from 'react';
import { Row, Button } from 'antd';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { IResource } from '../ChallengeInterfaces';
import { Dispatch, bindActionCreators, AnyAction } from 'redux';
import { setEditSorting, saveSorting } from '../webeatChallengeActions';
import WebeatResourceForm from './WebeatResourceForm';
import { ReducersState } from '../../reducers';
import { connect } from 'react-redux';
import { appComponents } from '../../components';
import { ResourceTypeEnum, ChallengeTypeEnum } from '../Enums';
const { EVENT, QUIZ } = ResourceTypeEnum;

interface IResourcesDragDropProps {
  challengeResources: IResource[];
  primaryKey: string;
  resourceSelector: JSX.Element;
  refreshChallengeData: () => Promise<void>;
  resetChallengeResources: () => void;
  setChallengeResources: (resources: IResource[]) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IResourcesDragDropProps;

function WebeatResourcesDragDrop(props: Props) {
  const {
    availableResources,
    challengeResources,
    editingResource,
    formHasChanged,
    primaryKey,
    refreshChallengeData,
    resetChallengeResources,
    resourceSelector,
    setChallengeResources,
    setEditSorting,
    sortingResources,
    saveSorting,
    selectedRow,
  } = props;

  const isNotDraggable = (resource: IResource) => {
    return (
      resource.idResourceType.idResourceType === EVENT ||
      (resource.idResourceType.idResourceType === QUIZ &&
        selectedRow.idChallengeType.idChallengeType ===
          ChallengeTypeEnum.FORMATION)
    );
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgrey' : 'transparent',
    ...draggableStyle,
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    if (result.destination) {
      const challengeArray = challengeResources.slice();

      challengeArray[result.source.index].order = result.destination.index;

      if (result.destination.index < result.source.index) {
        for (let i = result.destination.index; i < result.source.index; i++) {
          challengeArray[i].order += 1;
        }
        setChallengeResources(challengeArray);
      } else if (result.destination.index > result.source.index) {
        for (let i = result.destination.index; i > result.source.index; i--) {
          challengeArray[i].order -= 1;
        }
        setChallengeResources(challengeArray);
      }

      return;
    }
  };

  const handleSaveSorting = async () => {
    await saveSorting(
      selectedRow.idChallenge,
      primaryKey,
      'challengechallengeList',
      challengeResources,
    );

    setEditSorting(false);
  };

  const handleCancelSorting = () => {
    resetChallengeResources();
    setEditSorting(false);
  };

  if (challengeResources) {
    challengeResources.sort(function (a: IResource, b: IResource) {
      return a.idResource - b.idResource;
    });

    challengeResources.sort(function (a: IResource, b: IResource) {
      return a.order - b.order;
    });

    let sortedArr: IResource[] = [...challengeResources];

    return (
      <Row className="ResourcesRender">
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          className="collapseFrame">
          <div className="ResourcesRender__title">COMPONENTS</div>
          <div className="ResourcesRender__titleButtons">
            {sortingResources ? (
              <>
                <Button
                  className="ResourcesRender__titleButtons--order buttonNoFrame"
                  type="primary"
                  size="small"
                  onClick={async () => handleSaveSorting()}>
                  Save
                </Button>
                <Button
                  className="ResourcesRender__titleButtons--order buttonNoFrame"
                  type="primary"
                  size="small"
                  onClick={() => handleCancelSorting()}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={editingResource || formHasChanged}
                  size="small"
                  type="primary"
                  className="ResourcesRender__titleButtons--order buttonNoFrame"
                  onClick={() => setEditSorting(true)}>
                  {' Order '}
                </Button>
                {resourceSelector}
              </>
            )}
          </div>
        </Row>
        {sortingResources ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Row className="ResourcesRender__resource">
                    {sortedArr.map(
                      (resource: IResource, index: number) =>
                        resource.status &&
                        (isNotDraggable(resource) ? (
                          <WebeatResourceForm
                            key={index}
                            {...{
                              challengeResources,
                              resource,
                              index,
                              primaryKey,
                              refreshChallengeData,
                              collapse: true,
                              availableResources,
                            }}
                          />
                        ) : (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}>
                            {(
                              provided: DraggableProvided,
                              snapshot: DraggableStateSnapshot,
                            ) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}>
                                <WebeatResourceForm
                                  key={index}
                                  {...{
                                    challengeResources,
                                    resource,
                                    index,
                                    primaryKey,
                                    refreshChallengeData,
                                    collapse: true,
                                    availableResources,
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        )),
                    )}
                    {provided.placeholder}
                  </Row>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Row className="ResourcesRender__resource">
            {sortedArr.map(
              (resource: IResource, index: number) =>
                resource.status && (
                  <WebeatResourceForm
                    key={index}
                    {...{
                      challengeResources,
                      resource,
                      index,
                      primaryKey,
                      refreshChallengeData,
                      collapse: true,
                      availableResources,
                    }}
                  />
                ),
            )}
          </Row>
        )}
      </Row>
    );
  } else return null;
}

const mapStateToProps = (state: ReducersState) => {
  return {
    availableResources: state.challenge.availableResources,
    editingResource: state.challenge.editingResource,
    formHasChanged:
      state.tables[appComponents['challengechallengeEdit'].targetId]
        .formHasChanged,
    selectedRow:
      state.tables[appComponents['challengechallengeEdit'].targetId]
        .selectedRow,
    sortingResources: state.challenge.sortingResources,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setEditSorting,
      saveSorting,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebeatResourcesDragDrop);
