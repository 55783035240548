import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as api from '../../../api';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import {
  editRecursiveResource,
  handleDeleteDetail,
  handleRemoveChildResource,
} from '../../challengeActions';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import { ChallengeTypeEnum, ResourceTypeDetailEnum } from '../../Enums';
import RenderDetail from '../TreeResource/TreeDetails';

const { LINKED_CHALLENGE_TYPE } = ResourceTypeDetailEnum;
const { ANNUAL_CAMPAIGN, CAMPAIGN, FORMATION, TALK, PEARLS, CONGRESS } =
  ChallengeTypeEnum;
interface Props extends IResourceTree {
  idChallengeType: ChallengeTypeEnum;
}

type OwnProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Props;

const LinkedChallenges: FC<OwnProps> = ({
  editRecursiveResource,
  editResource,
  form,
  idChallengeType,
  resource,
  combos,
  handleDeleteDetail,
}): JSX.Element => {
  const [selectedChallengeType, setSelectedChallengeType] = useState<any>();
  const [challengeByTypeCombo, setChallengeByTypeCombo] = useState<ComboData[]>(
    [],
  );

  const details: IndexedDetail[] = [];
  const challengeType: ComboData[] = combos.challengechallengeEdit[
    'idChallengeType.idChallengeType'
  ].challenge_type.data.filter(({ value }) => {
    switch (idChallengeType) {
      case ANNUAL_CAMPAIGN:
        return [CAMPAIGN].includes(parseInt(value));
      case CAMPAIGN:
        return [TALK, FORMATION, PEARLS, CONGRESS].includes(parseInt(value));
      case CONGRESS:
      case TALK: 
      return [CAMPAIGN, FORMATION, PEARLS].includes(parseInt(value));
      default:
        return false;
    }
  });

  const handleSelectedType = (type: number) => {
    setSelectedChallengeType(type);
  };

  const getChallengeTypeCombo = async (param: number) => {
    try {
      const response = await api.getCombo({
        id: 'challengeByType',
        param,
      });

      setChallengeByTypeCombo(response.data);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  useEffect(() => {
    if (!selectedChallengeType) return;
    (async () => await getChallengeTypeCombo(selectedChallengeType))();
  }, [selectedChallengeType]);

  useEffect(() => {
    const setDefaultChallengeType = async () => {
      const challengeType = resource?.resourceDetailList?.find((_detail) => {
        return (
          _detail.idResourceTypeD.idResourceTypeD === LINKED_CHALLENGE_TYPE &&
          _detail.value
        );
      });
      if (!challengeType) return;
      setSelectedChallengeType(challengeType.value);
      await getChallengeTypeCombo(challengeType.value);
    };
    (async () => await setDefaultChallengeType())();
  }, [resource]);

  resource?.resourceDetailList?.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });
  if (!resource.resourceDetailList?.length) return <Fragment />;

  // Add here logic of Resource display items

  // Add here custom validations

  return (
    <>
      {details
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) =>
          RenderDetail({
            handleDeleteDetail,
            handleSelectedType,
            comboChallengeByType: challengeByTypeCombo,
            combo: challengeType,
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            resource,
            mainIdResource: resource.idResource,
          }),
        )}
      {}
    </>
  );
};

const mapStateToProps = (
  state: ReducersState,
): {
  combos: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          isLoading: boolean;
          data: ComboData[];
        };
      };
    };
  };
  idChallengeType: ChallengeTypeEnum;
} => {
  return {
    combos: state.combos.combos,
    idChallengeType:
      state.tables.challengechallengeList?.selectedRow.idChallengeType
        .idChallengeType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { editRecursiveResource, handleRemoveChildResource, handleDeleteDetail },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LinkedChallenges);
