import { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment';
import { ComboData } from '../../combos/ComboInterfaces';
import {
  IResource,
  IResourceDetail,
  IShoppingCartValidationErrors,
} from '../ChallengeInterfaces';
import {
  DetailsTypesEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
} from '../Enums';
const { TIME } = DetailsTypesEnum;

const {
  ONLINE,
  MAPS_URL,
  SHOPPING_CART_GROUP_MAX_ITEMS,
  SHOPPING_CART_GROUP_MIN_ITEMS,
  SHOPPING_CART_MAX_ITEMS,
  SHOPPING_CART_MIN_ITEMS,
  SHOPPING_CART_PRODUCT_DEFAULT_ITEMS,
  SHOPPING_CART_PRODUCT_MAX_ITEMS,
  SHOPPING_CART_PRODUCT_MIN_ITEMS,
  SURVEY_END_DATE,
  SURVEY_START_DATE,
  ARN_SURVEY_END_DATE,
  ARN_SURVEY_START_DATE,
  SELL_OUT_POINTS_RANGE_MAX,
  SELL_OUT_POINTS_RANGE_MIN,
  SELL_IN_PRODUCT_ID,
  SELL_IN_PRODUCT_MAX_ITEMS,
  SELL_IN_PRODUCT_MIN_ITEMS,
  SELL_IN_PRODUCT_DEFAULT_ITEMS,
  SELL_IN_IMAGE_RANGES_MIN_QUANTITY,
  SELL_IN_DESCRIPTION,
  TIME_TO_SHOW_LINK,
} = ResourceTypeDetailEnum;

export const getBaseValue = (value: any, combo?: ComboData[]) => {
  if (!value) return '-';

  if (combo?.length) {
    const comboValue = combo.find(
      ({ value: v }) => v.toString() === value.toString(),
    );
    if (comboValue) return comboValue.description;
  }

  return value;
};

export const getTimeValue = (resource: IResource) => {
  let time;
  resource.resourceDetailList?.forEach((detail) => {
    if (detail.idResourceTypeD.type === TIME.toString()) time = detail.value;
  });

  return time;
};

export const getNumberValue = (value: any, positive = true) => {
  if (
    value === '' ||
    value === null ||
    value === undefined ||
    isNaN(parseInt(value))
  )
    return null;
  if (positive) return +value;
  return Number(value);
};

export const getBooleanValue = (value: any) => {
  return value === 'true' || value === true ? true : false;
};

export const getDetailByType = (
  expectedType: ResourceTypeDetailEnum,
  resource: IResource,
) =>
  resource.resourceDetailList
    ?.filter(
      ({ status, idResourceTypeD: { idResourceTypeD } }) =>
        status && idResourceTypeD === expectedType,
    )
    .pop();

export const getDetailIndexByType = (
  expectedType: ResourceTypeDetailEnum,
  resource: IResource,
): number | null => {
  let expectedIndex: number | null = null;
  resource.resourceDetailList?.forEach(
    ({ status, idResourceTypeD: { idResourceTypeD } }, index) => {
      if (status && idResourceTypeD === expectedType) expectedIndex = index;
    },
  );
  return expectedIndex;
};

export const isOnlineEvent = (resourceDetailList?: IResourceDetail[]) =>
  resourceDetailList?.filter(
    ({ status, idResourceTypeD: { idResourceTypeD } }) =>
      status && idResourceTypeD === ONLINE,
  );

export const isEventUrlRequired = (resource: IResource) => {
  const { idResourceType, resourceDetailList } = resource;
  const isEvent = idResourceType.idResourceType === ResourceTypeEnum.EVENT;
  if (!isEvent) return false;

  const onlineDetail = isOnlineEvent(resourceDetailList);

  if (!onlineDetail) return false;
  return onlineDetail[0].value === true || onlineDetail[0].value === 'true';
};

export const isTextUrlRequired = (resource: IResource) => {
  const { idResourceType } = resource;
  return idResourceType.idResourceType === ResourceTypeEnum.TEXT_LINK_ITEM;
};

export const isUnnecessaryMapsUrl = (
  resource: IResource,
  idResourceTypeD: number,
) => {
  const { idResourceType, resourceDetailList } = resource;
  const isEvent = idResourceType.idResourceType === ResourceTypeEnum.EVENT;
  const isMapsUsl = idResourceTypeD === MAPS_URL;
  if (!isMapsUsl || !isEvent) return false;

  const onlineDetail = isOnlineEvent(resourceDetailList);

  if (!onlineDetail) return false;
  return onlineDetail[0].value === true || onlineDetail[0].value === 'true';
};

export const isUnnecessaryTimeToShow = (
  resource: IResource,
  idResourceTypeD: number,
) => {
  const { idResourceType, resourceDetailList } = resource;
  const isEvent = idResourceType.idResourceType === ResourceTypeEnum.EVENT;
  const isTimeToShowLink = idResourceTypeD === TIME_TO_SHOW_LINK;
  if (!isTimeToShowLink || !isEvent) return false;

  const onlineDetail = isOnlineEvent(resourceDetailList);

  if (!onlineDetail) return false;
  return onlineDetail[0].value === false || onlineDetail[0].value === 'false';
};

export const getStartEventDate = (
  form: WrappedFormUtils<any>,
  idResource: number,
) => {
  return form.getFieldValue(`${idResource}START DATE`);
};

export const getEndEventDate = (
  form: WrappedFormUtils<any>,
  idResource: number,
) => {
  return form.getFieldValue(`${idResource}END DATE`);
};

export const validateEventDates = ({
  isStart,
  endChallenge,
  eventEnd,
  eventStart,
  startChallenge,
}: {
  isStart: boolean;
  endChallenge: string;
  eventEnd?: string | moment.Moment;
  eventStart?: string | moment.Moment;
  startChallenge: string;
}) => {
  // CHALLENGE UTC HOUR
  let eventStartDate = getDateFromInput(eventStart);
  let eventEndDate = getDateFromInput(eventEnd);

  // CHALLENGE UTC DATES
  const startDateChallenge = moment(startChallenge)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
  const endDateChallenge = moment(endChallenge)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');

  // case all fields
  const noChallengeFullEvent =
    !startDateChallenge && eventStartDate && !endDateChallenge && eventEndDate;
  const startChallengeStartEvent =
    startDateChallenge && eventStartDate && !endDateChallenge && !eventEndDate;
  const startChallengeEndEvent =
    startDateChallenge && !eventStartDate && !endDateChallenge && eventEndDate;
  const fullChallengeStartEvent =
    startDateChallenge && !eventStartDate && endDateChallenge && eventEndDate;
  const fullChallengeEndEvent =
    startDateChallenge && !eventStartDate && endDateChallenge && eventEndDate;

  const checkEventStart = () => {
    return (
      moment(eventStartDate).isSameOrAfter(startDateChallenge) &&
      moment(eventStartDate).isSameOrBefore(endDateChallenge)
    );
  };

  const checkEventEnd = () => {
    return (
      moment(eventEndDate).isSameOrAfter(startDateChallenge) &&
      moment(eventEndDate).isSameOrBefore(endDateChallenge)
    );
  };

  switch (true) {
    case noChallengeFullEvent:
      return moment(eventStartDate).isSameOrBefore(eventEndDate);
    case startChallengeStartEvent:
      return moment(startDateChallenge).isSameOrBefore(eventStartDate);
    case startChallengeEndEvent:
      return moment(startDateChallenge).isSameOrBefore(eventEndDate);
    case fullChallengeStartEvent:
      return checkEventStart();
    case fullChallengeEndEvent:
      return checkEventEnd();
    default:
      // Partial check for current detail
      const isCorrectStart = checkEventStart();
      const isCorrectEnd = checkEventEnd();

      if ((isStart && !isCorrectStart) || (!isStart && !isCorrectEnd)) {
        return false;
      }
      if (
        eventStartDate !== 'Invalid date' &&
        eventEndDate !== 'Invalid date'
      ) {
        return moment(eventStartDate).isSameOrBefore(eventEndDate);
      }
  }
};

export const validateStartSurvey = ({
  resource,
  start,
  value,
  type,
}: {
  resource: IResource;
  start: string;
  value: any;
  type: 'typeorm' | 'arn';
}) => {
  const startChallengeDate = moment.utc(start).format('YYYY-MM-DD HH:mm:ss');
  const surveyStartDate = getDateFromInput(value);

  let endSurvey;

  switch (type) {
    case 'typeorm':
      endSurvey = getDetailByType(SURVEY_END_DATE, resource);
      break;
    case 'arn':
      endSurvey = getDetailByType(ARN_SURVEY_END_DATE, resource);
      break;
    default:
      break;
  }

  if (!endSurvey?.value)
    return moment(startChallengeDate).isSameOrBefore(surveyStartDate);

  const endSurveyDate = getDateFromInput(endSurvey?.value);
  return moment(surveyStartDate).isBetween(startChallengeDate, endSurveyDate);
};

export const validateEndSurvey = ({
  resource,
  start,
  value,
  type,
}: {
  resource: IResource;
  start: string;
  value: any;
  type: 'typeorm' | 'arn';
}) => {
  const startChallengeDate = moment.utc(start).format('YYYY-MM-DD HH:mm:ss');
  const surveyEndDate = getDateFromInput(value);

  let startSurvey;

  switch (type) {
    case 'typeorm':
      startSurvey = getDetailByType(SURVEY_START_DATE, resource);
      break;
    case 'arn':
      startSurvey = getDetailByType(ARN_SURVEY_START_DATE, resource);
      break;
    default:
      break;
  }

  startSurvey = getDetailByType(SURVEY_START_DATE, resource);

  if (!startSurvey?.value)
    return moment(startChallengeDate).isSameOrBefore(surveyEndDate);

  const startSurveyDate = getDateFromInput(startSurvey?.value);
  // check first start date to avoid duplicate messages
  if (moment(startChallengeDate).isAfter(startSurveyDate)) return true;

  return moment(startSurveyDate).isBetween(startChallengeDate, surveyEndDate);
};

export const getDetailFieldKey = (
  mainIdResource: number,
  detailArrayPosition?: number,
  parentArrayPosition?: number,
  childArrayPosition?: number,
): string => {
  let fieldKey = `mainResource${mainIdResource}`;
  if (detailArrayPosition || detailArrayPosition === 0)
    fieldKey += `detail${detailArrayPosition}`;
  if (parentArrayPosition || parentArrayPosition === 0)
    fieldKey += `parent${parentArrayPosition}`;
  if (childArrayPosition || childArrayPosition === 0)
    fieldKey += `child${childArrayPosition}`;
  return fieldKey;
};

export const validateSellOutRanges = (
  idResourceType: number,
  resourceList: IResource[] = [],
  resourceDetailList: IResourceDetail[] = [],
) => {
  if (idResourceType !== ResourceTypeEnum.SELL_OUT || !resourceList?.length)
    return true;

  let rangeCorrect = true;
  let previous_max: number | null = null;
  resourceList.forEach((resource) => {
    let minRange = null;
    let maxRange = null;
    resource?.resourceDetailList?.forEach(
      ({ idResourceTypeD: { idResourceTypeD }, value }) => {
        switch (idResourceTypeD) {
          case SELL_OUT_POINTS_RANGE_MIN:
            minRange = getNumberValue(value);
            break;
          case SELL_OUT_POINTS_RANGE_MAX:
            maxRange = getNumberValue(value);
            break;
          default:
            break;
        }
      },
    );
    if (minRange != null && previous_max != null && minRange < previous_max)
      rangeCorrect = false;

    if (minRange == null || maxRange == null || minRange >= maxRange)
      rangeCorrect = false;
    previous_max = maxRange;
  });

  return rangeCorrect;
};
export const validateShoppingCart = (
  idResourceType: number,
  resourceList: IResource[] = [],
  resourceDetailList: IResourceDetail[] = [],
  showMessage: (errorMessageKey: string) => void,
): IShoppingCartValidationErrors => {
  let maxCartItems: number = 0;
  let minCartItems: number = 0;
  let haveAnError = false;

  if (idResourceType !== ResourceTypeEnum.SHOPPING_CART)
    return {
      haveError: false,
      errorMessageKey: '',
    };

  //1. get cart info
  resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case SHOPPING_CART_MAX_ITEMS:
          maxCartItems = getNumberValue(value) || 0;
          break;
        case SHOPPING_CART_MIN_ITEMS:
          minCartItems = getNumberValue(value) || 0;
          break;
        default:
          break;
      }
    });

  const isGreatherThan = (target: number, max: number) => target > max;

  if (isGreatherThan(minCartItems, maxCartItems)) {
    haveAnError = true;
    showMessage(
      'shopping-cart.error.min-cart-items-should-be-lower-than-max-cart-items',
    );
  }

  //2. get group info
  let maxGroupItems = 0;
  let minGroupItems = 0;

  resourceList
    .filter(({ status }) => status)
    .forEach((group) => {
      const groupInfo = validateGroup(group, showMessage);
      haveAnError = groupInfo.haveAnError;

      maxGroupItems += groupInfo.maxGroupItems;
      minGroupItems += groupInfo.minGroupItems;

      if (isGreatherThan(groupInfo.minGroupItems, groupInfo.maxGroupItems)) {
        haveAnError = true;
        showMessage(
          'shopping-cart.error.min-group-items-should-be-lower-than-max-group-items',
        );
      }

      if (
        isGreatherThan(groupInfo.minProductItems, groupInfo.maxProductItems)
      ) {
        haveAnError = true;
        showMessage(
          'shopping-cart.error.min-product-items-should-be-lower-than-max-product-items',
        );
      }

      if (isGreatherThan(groupInfo.minProductItems, groupInfo.maxGroupItems)) {
        haveAnError = true;
        showMessage(
          'shopping-cart.error.min-product-items-should-be-lower-than-max-group-items',
        );
      }

      if (isGreatherThan(groupInfo.maxGroupItems, maxCartItems)) {
        haveAnError = true;
        showMessage(
          'shopping-cart.error.max-group-items-should-be-lower-than-max-cart-items',
        );
      }
    });

  if (isGreatherThan(minGroupItems, maxCartItems)) {
    haveAnError = true;
    showMessage(
      'shopping-cart.error.acc-min-group-items-should-be-lower-than-max-cart-items',
    );
  }

  if (isGreatherThan(minCartItems, maxGroupItems)) {
    haveAnError = true;
    showMessage(
      'shopping-cart.error.acc-max-group-items-should-be-greather-than-min-cart-items',
    );
  }

  return {
    haveError: haveAnError,
    errorMessageKey: '',
  };
};

export const validateSellIn = (
  idResourceType: number,
  resourceList: IResource[] = [],
  resourceDetailList: IResourceDetail[] = [],
) => {
  if (idResourceType !== ResourceTypeEnum.SELL_IN || !resourceList?.length)
    return true;

  let productCorrect = true;
  let rangeCorrect = true;
  let defaultCorrect = true;
  let minQuantity = true;
  let descriptionCorrect = true;

  resourceList
    .filter(({ status }) => status)
    .forEach((resource) => {
      let productId = null;
      let maxRange = null;
      let minRange = null;
      let defaultRange = null;
      let rangesMinQuantity = null;

      resource.resourceDetailList?.forEach(
        ({ idResourceTypeD: { idResourceTypeD }, value }) => {
          switch (idResourceTypeD) {
            case SELL_IN_PRODUCT_ID:
              productId = value;
              break;
            case SELL_IN_PRODUCT_MAX_ITEMS:
              maxRange = getNumberValue(value);
              break;
            case SELL_IN_PRODUCT_MIN_ITEMS:
              minRange = getNumberValue(value);
              break;
            case SELL_IN_PRODUCT_DEFAULT_ITEMS:
              defaultRange = getNumberValue(value);
              break;
            case SELL_IN_IMAGE_RANGES_MIN_QUANTITY:
              rangesMinQuantity = value;
              break;
          }
        },
      );

      resourceDetailList
        .filter(({ status }) => status)
        .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
          switch (idResourceTypeD) {
            case SELL_IN_DESCRIPTION:
              descriptionCorrect = !!value && value.length > 0;
              break;
          }
        });

      if (productId === '') productCorrect = false;
      if (defaultRange === '') defaultCorrect = false;
      if (rangesMinQuantity === '') minQuantity = false;
      if (minRange !== null && maxRange !== null && minRange > maxRange)
        rangeCorrect = false;
    });

  return (
    productCorrect &&
    rangeCorrect &&
    defaultCorrect &&
    minQuantity &&
    descriptionCorrect
  );
};

export const validateEvent = (
  idResourceType: number,
  resourceList: IResource[] = [],
  err: any,
) => {
  if (
    idResourceType !== ResourceTypeEnum.EVENT ||
    !resourceList?.length ||
    !err
  )
    return true;

  let keyErrorNames: Array<string> = Object.keys(err);

  //Obtains all notification resources that contains errors
  const notificationIdResource: IResource[] = resourceList
    .filter((resource: IResource) => {
      return (
        resource.idResourceType?.idResourceType ===
        ResourceTypeEnum.NOTIFICATION
      );
    })
    .filter((resource) => {
      return keyErrorNames.some((key) => {
        return key.includes(resource.idResource.toString());
      });
    });

  if (!notificationIdResource.length) return false;

  //Check if there are more errors than the notifications ones
  notificationIdResource.forEach((resource: IResource) => {
    keyErrorNames = keyErrorNames.filter((key) => {
      return !key.includes(resource.idResource.toString());
    });
  });

  return !keyErrorNames.length;
};

export const validateEventNotification = (
  idResourceType: number,
  resourceList: IResource[] = [],
  err: any,
) => {
  if (
    idResourceType !== ResourceTypeEnum.EVENT ||
    !resourceList?.length ||
    !err
  )
    return true;

  let keyErrorNames: Array<string> = Object.keys(err);

  let hasErrorNotification = resourceList.some((resource) => {
    if (
      resource.idResourceType?.idResourceType === ResourceTypeEnum.NOTIFICATION
    ) {
      return keyErrorNames.some((name) => {
        return name.includes(resource.idResource.toString());
      });
    }
    return false;
  });

  return !hasErrorNotification;
};

const validateGroup = (
  { resourceDetailList = [], resourceList = [] }: IResource,
  showMessage: (errorMessageKey: string) => void,
) => {
  let isCorrectGroup = true;
  let maxGroupItems: any = null;
  let minGroupItems = null;

  //1. get group info
  resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case SHOPPING_CART_GROUP_MAX_ITEMS:
          maxGroupItems = getNumberValue(value);
          break;
        case SHOPPING_CART_GROUP_MIN_ITEMS:
          minGroupItems = getNumberValue(value);
          break;
        default:
          break;
      }
    });

  //2. get products info
  let maxProductItems = 0;
  let minProductItems = 0;
  let haveAnError = false;

  resourceList
    .filter(({ status }) => status)
    .forEach((product) => {
      if (!isCorrectGroup) return;

      const productInfo = validateProduct(product);
      if (!productInfo) {
        isCorrectGroup = false;
        return;
      }

      maxProductItems += productInfo.maxItems;
      minProductItems += productInfo.minItems;

      if (Number(maxGroupItems) < productInfo.maxItems) {
        haveAnError = true;
        showMessage(
          'shopping-cart.error.max-product-items-should-be-lower-than-max-group-items',
        );
      }
    });

  switch (true) {
    case maxGroupItems !== null &&
      minGroupItems !== null &&
      minGroupItems > maxGroupItems:
    case minGroupItems !== null &&
      maxProductItems !== 0 &&
      maxProductItems < minGroupItems:
    case maxGroupItems !== null &&
      maxProductItems !== 0 &&
      minProductItems > maxGroupItems:
      isCorrectGroup = false;
      break;
    default:
      break;
  }

  return {
    minGroupItems: minGroupItems || 0,
    maxGroupItems: maxGroupItems || 0,
    minProductItems,
    maxProductItems,
    haveAnError,
  };
};

const validateProduct = ({
  resourceDetailList = [],
}: IResource): {
  maxItems: number;
  minItems: number;
  defaultItems: number;
} | null => {
  let maxItems = null;
  let minItems = null;
  let defaultItems = null;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case SHOPPING_CART_PRODUCT_MAX_ITEMS:
          maxItems = getNumberValue(value);
          break;
        case SHOPPING_CART_PRODUCT_MIN_ITEMS:
          minItems = getNumberValue(value);
          break;
        case SHOPPING_CART_PRODUCT_DEFAULT_ITEMS:
          defaultItems = getNumberValue(value);
          break;
        default:
          break;
      }
    });

  return {
    minItems: minItems || 0,
    maxItems: maxItems || 0,
    defaultItems: defaultItems || 0,
  };
};

export const isAddComponentDisabled = (
  triggerModule: boolean,
  resourceList?: IResource[],
) => {
  const MAX_MATERIAL_ON_TRIGGER_MODULE = 2;

  if (!resourceList?.length) return false;

  const currentSpeakers = resourceList?.filter(
    ({ status, idResourceType: { idResourceType } }) =>
      status && idResourceType === ResourceTypeEnum.SPEAKER,
  );

  const currentMaterials = resourceList?.filter(
    ({ status, idResourceType: { idResourceType } }) =>
      status && idResourceType === ResourceTypeEnum.MATERIAL,
  );

  if (
    triggerModule &&
    currentMaterials.length >= MAX_MATERIAL_ON_TRIGGER_MODULE
  )
    return true;

  if (!currentSpeakers?.length) return false;

  return currentSpeakers.length >= 6;
};

const getDateFromInput = (value?: string | moment.Moment) => {
  if (!value) return null;

  return moment.isMoment(value)
    ? value.clone().utc().format('YYYY-MM-DD HH:mm:ss')
    : value;
};
