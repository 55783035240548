import moment from 'moment-timezone';
import { IRow } from '../../app/AppInterfaces';
import { loadTimezoneName } from '../../utils';
import { IResource, IResourceTypeDetail } from '../ChallengeInterfaces';
import {
  DetailsTypesEnum,
  isBooleanDetail,
  isUrlDetail,
  NotificationStatusString,
  ResourceTypeDetailEnum,
} from '../Enums';
import {
  getBooleanValue,
  getDetailByType,
  isEventUrlRequired,
  isTextUrlRequired,
  validateEndSurvey,
  validateEventDates,
  validateStartSurvey,
} from './Resource.utils';

const {
  CHALLENGE_VISIBILITY_ALLOWED_EXTENSIONS,
  CHALLENGE_VISIBILITY_BUTTON_TITLE,
  CHALLENGE_VISIBILITY_DESCRIPTION,
  CHALLENGE_VISIBILITY_END_DATE,
  CHALLENGE_VISIBILITY_LINK_STATIC_PAGES,
  EVENT_END,
  EVENT_START,
  EXPERIENCE_ACTIVATION_BUTTON,
  EXPERIENCE_ACTIVATION_DESCRIPTION,
  EXPERIENCE_ACTIVATION_LINK_TITLE,
  EXPERIENCE_ACTIVATION_LINK_URL,
  EXPERIENCE_ACTIVATION_MODAL_BUTTON,
  EXPERIENCE_ACTIVATION_MODAL_DESCRIPTION,
  EXPERIENCE_PROGRESS_FULL,
  EXPERIENCE_PROGRESS_HALF,
  EXPERIENCE_PROGRESS_NONE,
  FORMATION_CERTIFICATE_TEMPLATE,
  FORMATION_CERTIFICATE_REQUIRED_POINTS,
  HIDDEN_TITLE,
  HIDDEN_ICON,
  HIDDEN_TEXT,
  HIDDEN_CTA_TEXT,
  HIDDEN_CTA_LINK,
  IMAGE_DESCRIPTION,
  IMAGE_URL,
  NOTIFICATION_RECIPIENT,
  ONLINE,
  PEARL_CONDITIONS,
  QUIZ_TYPEFORM_BUTTON_TEXT,
  QUIZ_TYPEFORM_DESCRIPTION,
  QUIZ_TYPEFORM_IMAGE,
  QUIZ_TYPEFORM_TITLE,
  QUIZ_TYPEFORM_TYPEFORM_ID,
  QUIZ_TYPEFORM_VIDEO,
  REDEEM_PRODUCT_BUTTON,
  REDEEM_PRODUCT_DESCRIPTION,
  REDEEM_PRODUCT_TERMS_LINK,
  REDEEM_PRODUCT_TERMS_TEXT,
  RESOURCE_GUIDE_DESCRIPTION,
  SELL_OUT_BODY_DESCRIPTION,
  SELL_OUT_BODY_END_DATE,
  SELL_OUT_BODY_MAIN_IMAGE,
  SELL_OUT_BODY_START_DATE,
  SELL_OUT_BODY_TITLE,
  SELL_OUT_DESCRIPTION_LARGE,
  SELL_OUT_DESCRIPTION,
  SELL_OUT_REPORT_FILE_TYPE,
  SELL_OUT_REPORT_QUANTITY,
  STEPS_CARD_CONTENT,
  STEPS_CARD_IMAGE_DESKTOP,
  STEPS_CARD_IMAGE,
  STEPS_CARD_LINK,
  STEPS_CARD_NAME,
  STEPS_CARD_SHOW_NUMBERS,
  STEPS_CARD_TEXT_LINK,
  STEPS_DESCRIPTION,
  SURVEY_BUTTON_TEXT,
  SURVEY_DESCRIPTION,
  SURVEY_END_DATE,
  SURVEY_END_TEXT,
  SURVEY_ID,
  SURVEY_START_DATE,
  TEXT_BODY,
  TEXT_DESKTOP_IMAGE,
  TEXT_DURATION_CONTENT,
  TEXT_DURATION_ICON,
  TEXT_LINK_LINK,
  TEXT_MOBILE_IMAGE,
  TIME_TO_SHOW_LINK,
  URL: URL_FIELD,
  VIDEO_SECTION_DESCRIPTION,
  VIDEO_SECTION_DESKTOP_IMAGE,
  VIDEO_SECTION_ID,
  VIDEO_SECTION_MOBILE_IMAGE,
  VIDEO_SECTION_TYPE,
  WATCH_AGAIN_YOUTUBE_ID,
  WYSIWYG_DETAIL,
  WYSWYG_CONTENT,
  WYSWYG_DESCRIPTION,
  CHECKOUT_DONATION_NAME,
  CHECKOUT_DONATION_IMAGE,
  CHECKOUT_DONATION_BUTTON_LEVEL,
  CHECKOUT_DONATION_QUESTION_SELECT,
  CHECKOUT_DONATION_PRODUCT,
  CHECKOUT_DONATION_CARD_DESCRIPTION,
  CHECKOUT_DONATION_TERMS_LABEL,
  CHECKOUT_DONATION_TERMS_DOCUMENT,
  CHECKOUT_LOTTERY_BUTTON_NAME,
  CHECKOUT_LOTTERY_ORDER_DESCRIPTION,
  CHECKOUT_LOTTERY_ORDER_TITLE,
  CHECKOUT_LOTTERY_STICKY_TEXT,
  CHECKOUT_LOTTERY_IMAGE,
  CHECKOUT_LOTTERY_TERMS_DOCUMENT,
  CHECKOUT_LOTTERY_TERMS_LABEL,
  CHECKOUT_LOTTERY_TEXT,
  CHECKOUT_LOTTERY_SUCCESS_WYSWYG,
  CHECKOUT_LOTTERY_SHOW_ORDER,
  CHECKOUT_BOOKING_TIMIFY_TEXT,
  CHECKOUT_BOOKING_TIMIFY_BUTTON_TEXT,
  CHECKOUT_BOOKING_TIMIFY_ID,
  ARN_SURVEY_BUTTON_TEXT,
  ARN_SURVEY_DESCRIPTION,
  ARN_SURVEY_END_DATE,
  ARN_SURVEY_END_TEXT,
  ARN_SURVEY_ID,
  ARN_SURVEY_START_DATE,
  BEAUTY_GUIDE_BUTTON_LABEL,
  BEAUTY_GUIDE_DESCRIPTION,
  BEAUTY_GUIDE_IMAGE,
} = ResourceTypeDetailEnum;

const {
  BRAND,
  BOOLEAN,
  DATE,
  MAIL,
  NUMBER,
  PRODUCT,
  SURVEY,
  ARN_SURVEY,
  TIME,
  CHECKOUT_ADDRESS_TYPE,
} = DetailsTypesEnum;

export const getDetailValidations = (
  { idResourceTypeD, type, mandatory }: IResourceTypeDetail,
  values: IRow,
  resource: IResource,
) => {
  const baseRules: any = [];
  let required;
  switch (true) {
    case isBooleanDetail(type):
      required = false;
      break;

    case idResourceTypeD === TEXT_LINK_LINK:
      required = isTextUrlRequired(resource);
      break;
    case idResourceTypeD === URL_FIELD:
      required = isEventUrlRequired(resource);
      break;
    case idResourceTypeD === SURVEY_START_DATE:
      const surveyEnd = getDetailByType(SURVEY_END_DATE, resource);
      required = surveyEnd?.value ? true : false;
      break;
    case idResourceTypeD === ARN_SURVEY_START_DATE:
      const arnSurveyEnd = getDetailByType(ARN_SURVEY_END_DATE, resource);
      required = arnSurveyEnd?.value ? true : false;
      break;
    case [EVENT_END, EVENT_START].includes(idResourceTypeD) &&
      (!values?.startDate || !values?.endDate):
      required = false;
      break;
    case idResourceTypeD === CHECKOUT_LOTTERY_ORDER_DESCRIPTION:
    case idResourceTypeD === CHECKOUT_LOTTERY_ORDER_TITLE:
    case idResourceTypeD === CHECKOUT_LOTTERY_TERMS_DOCUMENT:
    case idResourceTypeD === CHECKOUT_LOTTERY_TERMS_LABEL:
      required = getBooleanDetailValue(resource, CHECKOUT_LOTTERY_SHOW_ORDER)
      break;
    default:
      required = mandatory;
  }

  baseRules.push({
    required,
    message: 'Required',
  });

  if (isUrlDetail(type)) {
    baseRules.push({
      validator: (_: any, value: string) => {
        if (value === '') return true;
        if (!value) return false;
        if (!value.includes('http://') && !value.includes('https://'))
          return false;
        return true;
      },
      message: 'Not valid url format.',
    });
  }

  const currentTimezone = loadTimezoneName();

  switch (idResourceTypeD) {
    case TEXT_LINK_LINK:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value) return false;
          return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
        },
        message: 'URL format needed',
      });
      break;
    case EVENT_START:
      const eventEnd = getDetailByType(EVENT_END, resource);

      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate || !values?.endDate) return true;
          const endDate = moment(eventEnd?.value);
          const startDate = moment(value);
          return validateEventDates({
            endChallenge: values.endDate,
            eventEnd: endDate || '',
            eventStart: startDate,
            isStart: true,
            startChallenge: values.startDate,
          });
        },
        message: 'After challenge start and before End event/challenge date',
      });
      break;
    case EVENT_END:
      const eventStart = getDetailByType(EVENT_START, resource);
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate || !values?.endDate) return true;
          const endDate = moment(value);
          const startDate = moment(eventStart?.value);
          return validateEventDates({
            endChallenge: values.endDate,
            eventEnd: endDate,
            eventStart: startDate || '',
            isStart: false,
            startChallenge: values.startDate,
          });
        },
        message: 'After Start challenge/event date and before challenge end',
      });
      break;
    case WATCH_AGAIN_YOUTUBE_ID:
      const _endDateDetail = getDetailByType(EVENT_END, resource);

      const detailEndDate = moment(_endDateDetail?.value)
        .tz(currentTimezone)
        .utc();
      const nowDate = moment().tz(currentTimezone).utc();

      const isEventEnded = moment(detailEndDate).isBefore(nowDate);

      baseRules.push({
        validator: (_: any, value: string) => {
          if (isEventEnded) return true;
          if (Boolean(value?.trim())) return false;

          return true;
        },
        message: 'Should not be informed when the event is not ended',
      });
      break;
    case SURVEY_START_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateStartSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'typeorm',
          });
        },
        message: 'Survey start has to be after challenge start date',
      });
      break;
    case ARN_SURVEY_START_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateStartSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'arn',
          });
        },
        message: 'ARN Survey start has to be after challenge start date',
      });
      break;
    case SURVEY_END_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateEndSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'typeorm',
          });
        },
        message:
          'Survey end has to be after challenge start date and survey start',
      });
      break;
    case ARN_SURVEY_END_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateEndSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'arn',
          });
        },
        message:
          'ARN Survey end has to be after challenge start date and survey start',
      });
      break;
  }
  return baseRules;
};

const getBooleanDetailValue = (resource: IResource, idResourceTypeD: ResourceTypeDetailEnum) => {
  const value = resource.resourceDetailList?.find(detail => detail.idResourceTypeD.idResourceTypeD === idResourceTypeD)?.value;
  return getBooleanValue(value)
}

export const getDetailInitialValue = (
  { idResourceTypeD, type: detailType }: IResourceTypeDetail,
  value: any,
) => {
  let initialValue = value;

  const currentTimezone = loadTimezoneName();

  switch (detailType) {
    case TIME:
      switch (true) {
        case value === '':
        case moment.isMoment(value):
          initialValue = value;
          break;
        case moment.isMoment(moment(value, 'HH:mm:ss')):
          initialValue = moment(value, 'HH:mm:ss');
          break;
        default:
          initialValue = '';
          break;
      }
      break;
    case DATE:
      initialValue = value ? moment.utc(value).tz(currentTimezone) : undefined;
      break;
    case NUMBER:
      initialValue = isNaN(parseInt(value)) ? 0 : parseInt(value);
      if (idResourceTypeD === ResourceTypeDetailEnum.NOTIFICATION_STATUS)
        initialValue = NotificationStatusString[parseInt(value)];
      break;
    case BOOLEAN:
      const isOnlineDetail = idResourceTypeD === ONLINE;
      initialValue = isOnlineDetail ? true : getBooleanValue(value);
      break;
    case MAIL:
    case BRAND:
    case SURVEY:
    case ARN_SURVEY:
    case PRODUCT:
    case CHECKOUT_ADDRESS_TYPE:
      initialValue = value?.toString();
      break;
    default:
      break;
  }

  return initialValue;
};

export const getDetailLabel = ({
  isBoolean,
  idResourceTypeD,
  detailTypeValue,
  formatMessage,
}: {
  isBoolean: boolean;
  idResourceTypeD: number;
  detailTypeValue: string;
  formatMessage: any;
}) => {
  if (isBoolean) return null;
  if (
    [
      CHALLENGE_VISIBILITY_ALLOWED_EXTENSIONS,
      CHALLENGE_VISIBILITY_BUTTON_TITLE,
      CHALLENGE_VISIBILITY_DESCRIPTION,
      CHALLENGE_VISIBILITY_END_DATE,
      CHALLENGE_VISIBILITY_LINK_STATIC_PAGES,
      EXPERIENCE_ACTIVATION_BUTTON,
      EXPERIENCE_ACTIVATION_DESCRIPTION,
      EXPERIENCE_ACTIVATION_LINK_TITLE,
      EXPERIENCE_ACTIVATION_LINK_URL,
      EXPERIENCE_ACTIVATION_MODAL_BUTTON,
      EXPERIENCE_ACTIVATION_MODAL_DESCRIPTION,
      EXPERIENCE_PROGRESS_FULL,
      EXPERIENCE_PROGRESS_HALF,
      EXPERIENCE_PROGRESS_NONE,
      FORMATION_CERTIFICATE_TEMPLATE,
      FORMATION_CERTIFICATE_REQUIRED_POINTS,
      HIDDEN_TITLE,
      HIDDEN_ICON,
      HIDDEN_TEXT,
      HIDDEN_CTA_TEXT,
      HIDDEN_CTA_LINK,
      IMAGE_DESCRIPTION,
      IMAGE_URL,
      NOTIFICATION_RECIPIENT,
      PEARL_CONDITIONS,
      QUIZ_TYPEFORM_BUTTON_TEXT,
      QUIZ_TYPEFORM_DESCRIPTION,
      QUIZ_TYPEFORM_IMAGE,
      QUIZ_TYPEFORM_TITLE,
      QUIZ_TYPEFORM_TYPEFORM_ID,
      QUIZ_TYPEFORM_VIDEO,
      REDEEM_PRODUCT_BUTTON,
      REDEEM_PRODUCT_DESCRIPTION,
      REDEEM_PRODUCT_TERMS_LINK,
      REDEEM_PRODUCT_TERMS_TEXT,
      RESOURCE_GUIDE_DESCRIPTION,
      SELL_OUT_BODY_DESCRIPTION,
      SELL_OUT_BODY_END_DATE,
      SELL_OUT_BODY_MAIN_IMAGE,
      SELL_OUT_BODY_START_DATE,
      SELL_OUT_BODY_TITLE,
      SELL_OUT_DESCRIPTION_LARGE,
      SELL_OUT_DESCRIPTION,
      SELL_OUT_REPORT_FILE_TYPE,
      SELL_OUT_REPORT_QUANTITY,
      STEPS_CARD_CONTENT,
      STEPS_CARD_IMAGE_DESKTOP,
      STEPS_CARD_IMAGE,
      STEPS_CARD_LINK,
      STEPS_CARD_NAME,
      STEPS_CARD_SHOW_NUMBERS,
      STEPS_CARD_TEXT_LINK,
      STEPS_DESCRIPTION,
      SURVEY_BUTTON_TEXT,
      SURVEY_DESCRIPTION,
      SURVEY_END_DATE,
      SURVEY_END_TEXT,
      SURVEY_ID,
      SURVEY_START_DATE,
      TEXT_BODY,
      TEXT_DESKTOP_IMAGE,
      TEXT_DURATION_CONTENT,
      TEXT_DURATION_ICON,
      TEXT_MOBILE_IMAGE,
      TIME_TO_SHOW_LINK,
      VIDEO_SECTION_DESCRIPTION,
      VIDEO_SECTION_DESKTOP_IMAGE,
      VIDEO_SECTION_ID,
      VIDEO_SECTION_MOBILE_IMAGE,
      VIDEO_SECTION_TYPE,
      WYSIWYG_DETAIL,
      WYSWYG_CONTENT,
      WYSWYG_DESCRIPTION,
      PEARL_CONDITIONS,
      CHECKOUT_DONATION_NAME,
      CHECKOUT_DONATION_IMAGE,
      CHECKOUT_DONATION_BUTTON_LEVEL,
      CHECKOUT_DONATION_QUESTION_SELECT,
      CHECKOUT_DONATION_PRODUCT,
      CHECKOUT_DONATION_CARD_DESCRIPTION,
      CHECKOUT_DONATION_TERMS_LABEL,
      CHECKOUT_DONATION_TERMS_DOCUMENT,
      CHECKOUT_LOTTERY_BUTTON_NAME,
      CHECKOUT_LOTTERY_ORDER_DESCRIPTION,
      CHECKOUT_LOTTERY_ORDER_TITLE,
      CHECKOUT_LOTTERY_STICKY_TEXT,
      CHECKOUT_LOTTERY_IMAGE,
      CHECKOUT_LOTTERY_TERMS_DOCUMENT,
      CHECKOUT_LOTTERY_TERMS_LABEL,
      CHECKOUT_LOTTERY_TEXT,
      CHECKOUT_LOTTERY_SUCCESS_WYSWYG,
      ARN_SURVEY_BUTTON_TEXT,
      ARN_SURVEY_DESCRIPTION,
      ARN_SURVEY_END_DATE,
      ARN_SURVEY_END_TEXT,
      ARN_SURVEY_ID,
      ARN_SURVEY_START_DATE,
      BEAUTY_GUIDE_BUTTON_LABEL,
      BEAUTY_GUIDE_DESCRIPTION,
      BEAUTY_GUIDE_IMAGE,
      CHECKOUT_BOOKING_TIMIFY_TEXT,
      CHECKOUT_BOOKING_TIMIFY_BUTTON_TEXT,
      CHECKOUT_BOOKING_TIMIFY_ID,
    ].includes(idResourceTypeD)
  )
    return formatMessage({
      id: `tree-resource.${detailTypeValue.toLowerCase()}`,
    });

  return detailTypeValue;
};

export const shouldRenderComboDetail = (
  resource: IResource,
  detailType: DetailsTypesEnum,
) => {
  if (detailType !== DetailsTypesEnum.IMAGE_POSITION) return true;

  const desktopImage = getDetailByType(
    ResourceTypeDetailEnum.TEXT_DESKTOP_IMAGE,
    resource,
  );
  const mobileImage = getDetailByType(
    ResourceTypeDetailEnum.TEXT_MOBILE_IMAGE,
    resource,
  );

  if (!desktopImage?.value && !mobileImage?.value) return false;

  return true;
};
