export enum MaterialsEnum {
  // FILES = 1,
  PRODUCT_IMAGE = 2,
  VIDEO_YOUTUBE = 3,
  VIDEO_UPLOAD = 4,
  // LEARN_AND_EARN = 5,
  PRODUCT_INFORMATION = 6,
  OFFICE_BRANDING = 7,
  PATIENT_OUTREACH = 8,
}
