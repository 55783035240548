import { IResource, IScore, IWebeatResource } from '../ChallengeInterfaces';
import { ResourceTypeEnum } from '../Enums';

const { ANSWER } = ResourceTypeEnum;

export const setScore = (resource: IResource, points: number): IScore => {
  let score = resource.score;
  if (score !== null && score !== undefined) return { ...score, points };

  return {
    idScore: -1,
    points: points,
    status: true,
    idResource: resource.idResource
  };
};

export const buildSpecialtyScore = (
  resource: IResource,
  idScore: number,
  idSpecialty: number,
  points: number,
): IScore => {
  const score = resource.scoresSpecialty?.find(
    (score) => score.idSpecialty?.idSpecialty === idSpecialty,
  );

  if (score) return { ...score, points };

  return {
    idScore,
    points,
    status: true,
    idResource: resource.idResource,
    idSpecialty: { idSpecialty },
  };
};

export const getValidScore = (resource: IResource) => {
  if (
    resource.status &&
    resource.score &&
    resource.score.points &&
    resource.score.points > 0
  )
    return resource.score.points;
  else return 0;
};

export const getWebeatValidScore = (resource: IWebeatResource) => {
  if (
    resource.status &&
    resource.score
  )
    return resource.score;
  else return 0;
};

export const getAccumulateScore = (
  _resource: IResource,
  targetResource: ResourceTypeEnum,
): number => {
  let accScore = 0;
  if (!_resource.status) return accScore;
  _resource.resourceList?.forEach((resourceDetail) => {
    if (resourceDetail.idResourceType.idResourceType === targetResource)
      accScore += getResourceChildScore(resourceDetail);
    else accScore += getValidScore(resourceDetail);
  });
  return accScore;
};

export const getWebeatAccumulateScore = (
    _resource: IWebeatResource,
    targetResource: ResourceTypeEnum,
): number => {
  let accScore = 0;
  if (!_resource.status) return accScore;
  _resource.resourceList?.forEach((resourceDetail) => {
    if (resourceDetail.idResourceType.idResourceType === targetResource)
      accScore += getResourceChildScore(resourceDetail);
    else accScore += getWebeatValidScore(resourceDetail as IWebeatResource);
  });
  return accScore;
};

export const getWebeatResourceChildScore = (question: IWebeatResource): number => {
  let questionScore = 0;

  question.resourceList?.forEach((resource) => {
    const { idResourceType } = resource.idResourceType;
    if (idResourceType === ANSWER) {
      const answerScore = getWebeatValidScore(resource as IWebeatResource);
      if (Math.abs(answerScore) > Math.abs(questionScore))
        questionScore = answerScore;
    }
  });

  return questionScore;
};

export const getResourceChildScore = (question: IResource): number => {
  let questionScore = 0;

  question.resourceList?.forEach((resource: IResource) => {
    const { idResourceType } = resource.idResourceType;
    if (idResourceType === ANSWER) {
      const answerScore = getValidScore(resource);
      if (Math.abs(answerScore) > Math.abs(questionScore))
        questionScore = answerScore;
    }
  });

  return questionScore;
};

export const getMaxChildScore = (resource: IResource) => {
  let maxScore = 0;

  resource?.resourceList?.forEach((child: IResource) => {
    const childScore = getValidScore(child);
    if (childScore && Math.abs(childScore) > Math.abs(maxScore))
      maxScore = childScore;
  });

  return maxScore;
};

export const getWebeatMaxChildScore = (resource: IWebeatResource) => {
  let maxScore = 0;

  resource?.resourceList?.forEach((child) => {
    const childScore = getWebeatValidScore(child as IWebeatResource);
    if (childScore && Math.abs(childScore) > Math.abs(maxScore))
      maxScore = childScore;
  });

  return maxScore;
};

export const getResourceChildPoints = (
  resource: IResource,
  type: ResourceTypeEnum,
) => {
  let scoreChallengeMaterial = 0;
  resource.resourceList?.forEach((materialResource: IResource) => {
    if (materialResource.idResourceType.idResourceType === type)
      if (materialResource.score)
        scoreChallengeMaterial += materialResource?.score?.points;
  });
  return scoreChallengeMaterial;
};

export const getResourcesByType = (
  resourceList: IResource[],
  targetType: ResourceTypeEnum,
) => {
  const resources: IResource[] = [];
  const restResource: IResource[] = [];

  resourceList.forEach((_resource) => {
    if (_resource.idResourceType.idResourceType === targetType) {
      resources.push(_resource);
    } else restResource.push(_resource);
  });

  return [resources, restResource];
};

export const getScoreDefaultInit = (resource: IResource) => {
  const scoreDefaultInit: IScore = {
    idResource: resource.idResource,
    idSpecialty: resource.score?.idSpecialty,
    points: resource.score?.points ?? 0,
    status: resource.score?.status ?? true,
    idScore: resource.score?.idScore ?? -1,
  };

  return scoreDefaultInit;
};
