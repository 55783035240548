import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const FilterDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, LARGE } = config.COMPONENT.COLUMN;
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'filterSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idFilterType.idFilterType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'filter.id' }),
          key: 'idFilter',
          type: 'number',
          operand: 'equal',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'filter.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'filter.description' }),
          key: 'description',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'filter.type' }),
          key: 'idFilterType.idFilterType',
          type: 'combo',
          comboId: 'filterType',
          operand: 'equal',
        },
        {
          title: formatMessage({ id: 'filter.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'filterList',
        rowKey: 'idFilter',
        panelHeader: 'Filter List',
        navigationParams: props.query.params.q || '',
        prettierKey: 'name',
        isMainTable: true,
        tableType: 0,
      },
      fields: [
        {
          title: formatMessage({ id: 'filter.id' }),
          key: 'idFilter',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: false,
          width: SMALL,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'filter.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'filter.description' }),
          key: 'description',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
        },
        {
          title: formatMessage({ id: 'filter.type' }),
          key: 'idFilterType.idFilterType',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: LARGE,
          render: 'combo',
          comboId: 'filterType',
        },
        {
          title: formatMessage({ id: 'filter.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'filterEdit',
        panelHeader: 'Filter Edit',
        primaryKey: 'idFilter',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'description',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'idFilterType.idFilterType',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'idMagento',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'textMachine',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'filter.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'filter.textMachine' }),
          key: 'textMachine',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'filter.description' }),
          key: 'description',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'filter.type' }),
          key: 'idFilterType.idFilterType',
          type: 'combo',
          comboId: 'filterType',
          initialValue: '1',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'filter.idPathology' }),
          key: 'idMagento',
          type: 'text',
          initialMandatory: false,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idFilterType.idFilterType',
                operand: 'like',
                type: 'disabled',
                value: '2',
              },
            ],
            [
              {
                key: 'idFilterType.idFilterType',
                operand: 'like',
                type: 'mandatory',
                value: '2',
              },
            ],
          ],
          initialValue: null,
        },
        {
          title: formatMessage({ id: 'filter.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          mandatory: true,
          initialDisabled: true,
          initialValue: 'true',
          mustRender: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'FilterDashboard';

  return (
    <DashboardInitializer
      customDashboard={componentId}
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default FilterDashboard;
