import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ReducersState } from '../../../reducers';
import {
  editRecursiveResource,
  handleRemoveChildResource,
} from '../../challengeActions';
import { IndexedDetail, IResource } from '../../ChallengeInterfaces';
import '../Resource.css';
import RenderDetail from '../TreeResource/TreeDetails';

interface ISellOutPointsRange {
  _resource: IResource;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  groupIndex: number;
  mainIdResource: number;
  values: IRow;
}

type IGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ISellOutPointsRange;

const SellOutPointsRange: FC<IGroupProps> = ({
  _resource,
  combos,
  editRecursiveResource,
  editResource,
  form,
  handleRemoveChildResource,
  groupIndex,
  mainIdResource,
  values,
}) => {
  const { resourceDetailList } = _resource;

  const groupDetails: IndexedDetail[] = [];

  resourceDetailList?.forEach((detail, index) => {
    if (detail.status) groupDetails.push({ index, detail });
  });

  return (
    <>
      {groupDetails
        ?.sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderB - orderA,
        )
        .map(({ detail, index }) =>
          RenderDetail({
            detail,
            detailArrayPosition: index,
            editResource,
            editRecursiveResource,
            form,
            mainIdResource,
            resource: _resource,
            parentArrayPosition: groupIndex,
            values,
          }),
        )}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    combos: state.combos.combos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { editRecursiveResource, handleRemoveChildResource },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SellOutPointsRange);
