export const booleanFields = [
    { key: 'active' },
    { key: 'featured' },
    { key: 'showList' },
    { key: 'showPoints' },
    { key: 'showHeader' },
  ];

export const challengeDates = [
    { key: 'startVisDate' },
    { key: 'startDate' },
    { key: 'endDate' },
    { key: 'endVisDate' },
  ];