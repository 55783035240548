import React from 'react';
import {
  Checkbox,
  Row,
  Input,
  Form,
  InputNumber,
  Select,
  Button,
  Icon,
  DatePicker,
  Col,
  Tooltip,
} from 'antd';
import {
  typesOfSelectEnum,
  UPLOAD_ACTION_FILE,
  UPLOAD_ACTION_IMAGE,
  UPLOAD_ACTION_VIDEO,
  UPLOAD_ACTION_YOUTUBE_URL,
} from '../../../shared';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IResourceDetail } from '../../ChallengeInterfaces';
import { Dispatch } from 'redux';
import { SetQuiz } from '../../challengeActions';
import { ReducersState } from '../../../reducers';
import { useIntl } from 'react-intl';
import WYSWYGComponent from '../../../contentPage/components/WYSWYGComponent';
import UploadComponent from '../UploadComponent';
import { DetailsTypesEnum, ResourceTypeDetailEnum } from '../../Enums';
import { startCase } from 'lodash';
import { ComboData } from '../../../combos/ComboInterfaces';
import { getBooleanValue } from '../Resource.utils';
import { isConPlatform, isPINPlatform, loadTimezoneName } from '../../../utils';
import moment from 'moment';

interface IFieldBaseProps {
  childArrayPosition?: number;
  detailArrayPosition?: number;
  editRecursiveResource: ({
    value,
    parentArrayPosition,
    childArrayPosition,
    detailArrayPosition,
  }: {
    value: any;
    parentArrayPosition?: number;
    childArrayPosition?: number;
    detailArrayPosition?: number;
  }) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  editResource: boolean;
  idResourceD: number;
  parentArrayPosition?: number;
  maxLength?: number;
}
interface IInputProps extends IFieldBaseProps {
  limit?: number;
}

interface IExtendedFieldProps extends IFieldBaseProps {
  title?: string;
  value: any;
}
interface ISelectProps extends IFieldBaseProps {
  handleSelectedType?: (type: number) => void;
  combo: ComboData[];
  detail: IResourceDetail;
  value: any;
}
interface IDateProps extends IFieldBaseProps {
  value: any;
}
interface IUploadProps extends IFieldBaseProps {
  accessToken: any;
  detailType: string;
  idResourceTypeD: number;
  handleDeleteImages: ((image: string, idResourceD: number) => void) | null;
  materialType?: typesOfSelectEnum;
  setValue: () => void;
  title: string;
  value: any;
  idChallenge?: any;
}

interface IWatchAgainFieldProps extends IFieldBaseProps {
  checkArrayPosition: number | null;
  checkData: IResourceDetail | undefined;
  detailTypeValue: string;
  isEnabled: boolean;
  textData: IResourceDetail | undefined;
}

export const TreeCheckbox = ({
  editRecursiveResource,
  editResource,
  idResourceD,
  parentArrayPosition,
  childArrayPosition,
  detailArrayPosition,
  title,
  value,
}: IExtendedFieldProps): JSX.Element => {
  // const [checkValue, setCheckValue] = useState(getBooleanValue(value));

  // useEffect(() => {
  //   setCheckValue(getBooleanValue(value));
  // }, [value]);

  return (
    <Row className="BasicResource__checkbox">
      <Checkbox
        className="BasicResource__checkbox--input"
        checked={getBooleanValue(value)}
        disabled={!editResource}
        key={idResourceD}
        onChange={(e: CheckboxChangeEvent) =>
          editRecursiveResource({
            parentArrayPosition,
            detailArrayPosition,
            childArrayPosition,
            value: e.target.checked,
          })
        }
      />
      {title && (
        <div
          onClick={() => {
            editRecursiveResource({
              parentArrayPosition,
              detailArrayPosition,
              childArrayPosition,
              value: !getBooleanValue(value),
            });
          }}
          className="BasicResource__checkboxTitle">
          {title}
        </div>
      )}
    </Row>
  );
};

export const TreeTextarea = ({
  editRecursiveResource,
  idResourceD,
  parentArrayPosition,
  childArrayPosition,
  detailArrayPosition,
  editResource,
  maxLength,
}: IFieldBaseProps) => {
  return (
    <Input.TextArea
      className="QuestionInput"
      disabled={!editResource}
      key={idResourceD}
      maxLength={isConPlatform() ? maxLength : 250}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        editRecursiveResource({
          value: e.target.value,
          parentArrayPosition,
          childArrayPosition,
          detailArrayPosition,
        });
      }}
    />
  );
};

export const TreeInput = ({
  childArrayPosition,
  detailArrayPosition,
  editRecursiveResource,
  editResource,
  idResourceD,
  limit,
  parentArrayPosition,
}: IInputProps) => {
  return (
    <Input
      className="QuestionInput"
      key={idResourceD}
      disabled={!editResource}
      maxLength={limit}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        editRecursiveResource({
          value: e.target.value,
          parentArrayPosition,
          childArrayPosition,
          detailArrayPosition,
        })
      }
    />
  );
};

export const TreeNumber = ({
  childArrayPosition,
  detailArrayPosition,
  editRecursiveResource,
  editResource,
  idResourceD,
  parentArrayPosition,
}: IFieldBaseProps) => {
  return (
    <InputNumber
      className="QuestionInput"
      disabled={!editResource}
      precision={0}
      min={0}
      key={idResourceD}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
        editRecursiveResource({
          value: e.target.value,
          parentArrayPosition,
          childArrayPosition,
          detailArrayPosition,
        })
      }
    />
  );
};

export const TreeDate = ({
  childArrayPosition,
  detailArrayPosition,
  editRecursiveResource,
  editResource,
  idResourceD,
  parentArrayPosition,
  value,
}: IDateProps) => {
  // The initial value of the DatePicker is set in ResourceDetails.utils (see 'case Date: ')
  // Attribute 'value' is useless and can be removed due the initial value of the DatePicker is..
  // ..set through the 'GetFieldDecorator' (see Antd docs for more info)

  // Need to set DatePicker timezone as well to avoid the component to use local machine timezone
  const currentTimezone = loadTimezoneName();
  moment.tz.setDefault(currentTimezone);

  return (
    <DatePicker
      key={idResourceD}
      value={value}
      disabled={!editResource}
      showTime={{ format: 'HH:mm' }}
      showToday={false}
      format="DD-MM-YYYY HH:mm"
      onChange={(date: any | null, dateString: string) => {
        let newDate = '';

        if (date) {
          newDate = date.clone().format('YYYY-MM-DD HH:mm:ss');

          const formattedDate = new Date(date);
          formattedDate.setUTCSeconds(0);

          newDate = formattedDate.toUTCString();
        }

        editRecursiveResource({
          value: newDate,
          parentArrayPosition,
          childArrayPosition,
          detailArrayPosition,
        });
      }}
    />
  );
};

export const TreeWYSWYG = ({
  childArrayPosition,
  detailArrayPosition,
  editRecursiveResource,
  editResource,
  idResourceD,
  parentArrayPosition,
  title,
  value,
}: IExtendedFieldProps) => {
  return (
    <WYSWYGComponent
      key={idResourceD}
      handleEdit={(value) =>
        editRecursiveResource({
          value: value,
          parentArrayPosition,
          childArrayPosition,
          detailArrayPosition,
        })
      }
      height={300}
      readonly={!editResource}
      title={startCase(title!.toLowerCase())}
      value={value}
    />
  );
};

export const TreeSelector = ({
  handleSelectedType,
  childArrayPosition,
  combo,
  detail,
  detailArrayPosition,
  editRecursiveResource,
  idResourceD,
  parentArrayPosition,
  value,
  editResource,
}: ISelectProps) => {
  const selectCombo = combo ?? [];
  const showSku =
    detail?.idResourceTypeD?.idResourceTypeD ===
    ResourceTypeDetailEnum.SHOPPING_CART_PRODUCT_ID;

  const showName =
    detail?.idResourceTypeD?.idResourceTypeD ===
    ResourceTypeDetailEnum.SELL_IN_PRODUCT_ID;

  const maxWidth300 =
    detail?.idResourceTypeD?.idResourceTypeD ===
    ResourceTypeDetailEnum.QUESTION_TYPE;

  let selectOptions: {
    onChange: any;
    showSearch?: boolean;
    value?: string;
  } = {
    onChange: (value: string) => {
      handleSelectedType && handleSelectedType(parseInt(value));
      editRecursiveResource({
        value,
        parentArrayPosition,
        childArrayPosition,
        detailArrayPosition,
      });
    },
  };

  if (showSku) selectOptions = { ...selectOptions, showSearch: true };

  const getSelectDescription = (option: ComboData): string | undefined => {
    switch (true) {
      case isConPlatform():
        return option.description ?? option.label;
      case isPINPlatform() && showSku:
        return `SKU: ${option.value} - ${option.name ?? option.description}`;
      case isPINPlatform():
        return option.description;
      default:
        return option.description;
    }
  };

  return (
    <Select
      className={`${maxWidth300 ? 'select--maxWidth-300' : ''}`}
      key={idResourceD}
      {...selectOptions}
      disabled={!editResource}>
      {selectCombo?.map((option: ComboData) => {
        return (
          <Select.Option
            key={option.value.toString()}
            value={option.value.toString()}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  (showName ? option.name : getSelectDescription(option)) || '',
              }}
              style={{ marginBottom: 0 }}
            />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export const TreeUpload = ({
  accessToken,
  detailType,
  editResource,
  handleDeleteImages,
  idResourceD,
  idResourceTypeD,
  materialType,
  setValue,
  title,
  value,
  idChallenge
}: IUploadProps) => {
  const { formatMessage } = useIntl();
  let fileDimensions;
  let handleChangeField;
  let format: 'image' | 'pdf' | 'video' | undefined = undefined;
  let initialActions = [
    UPLOAD_ACTION_VIDEO,
    UPLOAD_ACTION_YOUTUBE_URL,
    UPLOAD_ACTION_IMAGE,
    UPLOAD_ACTION_FILE,
  ];
  let uploadActions: any[] = [];

  // TODO check this with JOAN

  const isQuizMainImage = ResourceTypeDetailEnum.QUIZ_IMAGE;
  const isSpeakerImage =
    idResourceTypeD === ResourceTypeDetailEnum.SPEAKER_IMAGE;

  const isMaterialImage =
    idResourceTypeD === ResourceTypeDetailEnum.MATERIAL_IMAGE;

  const isMaterialContent =
    idResourceTypeD === ResourceTypeDetailEnum.MATERIAL_CONTENT;
  const isIMAGE = idResourceTypeD === ResourceTypeDetailEnum.IMAGE_URL;

  const allowOnlyImages =
    isSpeakerImage || isMaterialImage || isQuizMainImage || isIMAGE;

  switch (true) {
    case isMaterialContent:
      break;
    case detailType === DetailsTypesEnum.IMAGE:
    case isMaterialImage:
    case isIMAGE:
      format = 'image';
      break;
    default:
      format = 'pdf';
  }

  if (allowOnlyImages) uploadActions = [UPLOAD_ACTION_IMAGE];

  if (isMaterialContent)
    uploadActions = initialActions.filter(
      (action) => action !== UPLOAD_ACTION_YOUTUBE_URL,
    );

  // if (isMaterialImage)
  //   fileDimensions = {
  //     width: config.MARKETING_MATERIALS.COVER_IMAGE_WIDTH,
  //     height: config.MARKETING_MATERIALS.COVER_IMAGE_HEIGHT,
  //   };

  if (handleDeleteImages)
    handleChangeField = ({ deleteImage }: { deleteImage: string }) =>
      handleDeleteImages(deleteImage, idResourceD);

  //END TODO

  return UploadComponent({
    fileDimensions,
    format,
    materialType,
    token: accessToken,
    disabled: !editResource,
    value,
    uploadActions,
    setValue,
    handleChangeField,
    children: (
      <Button
        className="uploadButton uploadButton__margin-top"
        disabled={!editResource}>
        <Icon type="upload" />
        {formatMessage({
          id: 'upload.buttonTitle',
        })}
      </Button>
    ),
    primaryEntityId: idChallenge,
    module: 'challenge'
  });
};

export const WatchAgainField = ({
  checkArrayPosition,
  checkData,
  childArrayPosition,
  detailArrayPosition,
  detailTypeValue,
  editRecursiveResource,
  editResource,
  idResourceD,
  isEnabled,
  parentArrayPosition,
  textData,
}: IWatchAgainFieldProps) => {
  const { formatMessage } = useIntl();

  const tooltipTitle = formatMessage({
    id: `challenge.watch again only attended users`,
  });

  return (
    <Row
      className="BasicResource__watchAgain"
      justify="space-between"
      align="middle">
      <Col span={20}>
        <Form.Item
          label={formatMessage({
            id: `tree-resource.${detailTypeValue?.toLowerCase()}`,
          })}>
          <Input
            value={textData?.value}
            disabled={!isEnabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              editRecursiveResource({
                value: e.target.value,
                parentArrayPosition,
                childArrayPosition,
                detailArrayPosition,
              })
            }
          />
        </Form.Item>
      </Col>
      {checkArrayPosition && (
        <div className="check-attended">
          <Col span={8}>
            {TreeCheckbox({
              editRecursiveResource,
              editResource,
              idResourceD,
              parentArrayPosition,
              childArrayPosition,
              detailArrayPosition: checkArrayPosition,
              value: checkData?.value,
            })}
          </Col>
          <Col span={8}>
            <Tooltip className="fieldTooltip" title={tooltipTitle}>
              <Icon type="question-circle-o" />
            </Tooltip>
          </Col>
        </div>
      )}
    </Row>
  );
};
