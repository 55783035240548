import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const CenterDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'centercenterSearch'
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'centerType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: 'Id Center',
          key: 'idCenter',
          type: 'number',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: formatMessage({ id: 'center.type' }),
          key: 'centerType',
          type: 'combo',
          comboId: 'centerType',
          operand: 'like',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false
        },
        {
          title: 'Name',
          key: 'name',
          type: 'text',
          operand: 'like',
          globalSearch: true
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'like',
          inItialValue: 'true'
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN
      }
    },
    {
      params: {
        type: 'table',
        componentId: 'centercenterList',
        rowKey: 'idCenter',
        panelHeader: 'Center List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name'
      },
      fields: [
        {
          title: formatMessage({ id: 'center.id' }),
          key: 'idCenter',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL
        },
        {
          title: formatMessage({ id: 'center.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true
        },
        {
          title: formatMessage({ id: 'center.type' }),
          key: 'centerType',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          render: 'combo',
          comboId: 'centerType',
          width: MID
        },
        {
          title: formatMessage({ id: 'genertic.status' }),
          key: 'status',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL
        }
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true }
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE
      }
    },
    {
      params: {
        type: 'edit',
        componentId: 'centercenterEdit',
        panelHeader: 'Center Edit',
        primaryKey: 'idCenter',
        tabIndex: 0
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12
                },
                {
                  key: 'centerType',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 6
                },
                {
                  key: 'status',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 6
                }
              ]
            }
          ]
        }
      ],
      fields: [
        {
          title: formatMessage({ id: 'center.name' }),
          key: 'name',
          type: 'text',
          length: 50,
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'center.type' }),
          key: 'centerType',
          type: 'combo',
          comboId: 'centerType',
          mandatory: true,
          disabled: false
        },
        {
          title: formatMessage({ id: 'generic.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          mandatory: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'idCountry',
                operand: 'notEmpty',
                type: 'disabled'
              }
            ]
          ],
          initialValue: 'true'
        }
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true
      }
    }
  ];

  const componentId = 'CenterDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default CenterDashboard;
