export const combosCustom: combosCustomType = {
  status: [
    {
      description: 'Active',
      value: 'true',
    },
    {
      description: 'Deleted',
      value: 'false',
    },
  ],
  optional: [
    {
      description: 'Yes',
      value: 'true',
    },
    {
      description: 'No',
      value: 'false',
    },
  ],
  position: [
    {
      description: 'Left',
      value: 'left',
    },
    {
      description: 'Right',
      value: 'right',
    },
  ],
  productStatus: [
    {
      description: 'Active',
      value: 'Active',
    },
    {
      description: 'Inactive',
      value: 'Inactive',
    },
  ],
};

export interface combosCustomType {
  [key: string]: { value: string; description: string }[];
}

export const behaviorCombo: behaviorComboType[] = [
  { value: '1', description: 'Disabled' },
  { value: '2', description: 'Not Visible' },
];

export interface behaviorComboType {
  value: string;
  description: string;
}
