import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { isConPlatform } from '../utils';

const WebeatChallengeDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID, LARGE } = config.COMPONENT.COLUMN;

  const platformComboFields = isConPlatform()
    ? [
        {
          title: 'Checkout Address Type',
          key: 'checkoutAddressType',
          type: 'selector',
          selectorPath: '/checkout-address-types',
          comboId: 'checkoutAddressType',
        },
        {
          title: 'Checkout Product Type',
          key: 'checkoutProductType',
          type: 'selector',
          selectorPath: '/checkout-product-types',
          comboId: 'checkoutProductType',
        },
        {
          title: 'Icon Type',
          key: 'iconType',
          type: 'selector',
          selectorPath: '/icon-types',
          comboId: 'iconType',
        },
        {
          title: 'Material Type',
          key: 'materialType',
          type: 'selector',
          selectorPath: '/material-types',
          comboId: 'materialType',
        },
        {
          title: 'Video Section Type',
          key: 'videoSectionType',
          type: 'selector',
          selectorPath: '/video-section-types',
          comboId: 'videoSectionType',
        },
        {
          title: 'Checkout Mail Template',
          key: 'checkoutMails',
          type: 'selector',
          selectorPath: '/checkout-mail-templates',
          comboId: 'checkoutMails',
        },
      ]
    : [];

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'challengechallengeSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idChallengeType.idChallengeType',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'featured',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'status',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'challenge.id' }),
          key: 'idChallenge',
          type: 'number',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType.idChallengeType',
          type: 'selector',
          selectorPath: '/challenge/challenge-types',
          operand: 'equal',
          comboId: 'challenge_type',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'challenge.featured' }),
          key: 'featured',
          type: 'comboCustom',
          comboId: 'optional',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          type: 'comboCustom',
          comboId: 'status',
          operand: 'equal',
          min: 0,
          length: 0,
          mandatory: false,
          disabled: false,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'challengechallengeList',
        rowKey: 'idChallenge',
        panelHeader: 'Challenge List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tableType: 0,
        prettierKey: 'name',
      },
      fields: [
        {
          title: formatMessage({ id: 'challenge.id' }),
          key: 'idChallenge',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'challenge.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
          defaultSortOrder: 'ascend',
        },
        {
          title: formatMessage({ id: 'challenge.featured' }),
          key: 'featured',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'comboCustom',
          comboId: 'optional',
        },
        {
          title: formatMessage({ id: 'challenge.order' }),
          key: 'order',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
          render: 'number',
          precision: 0,
        },
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType.name',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'challenge.start_date' }),
          key: 'startDate',
          position: 6,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.end_date' }),
          key: 'endDate',
          position: 7,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.start_vis_date' }),
          key: 'startVisDate',
          position: 8,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.end_vis_date' }),
          key: 'endVisDate',
          position: 9,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: MID,
          render: 'datetime',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          render: 'comboCustom',
          comboId: 'status',
          position: 11,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'challengechallengeEdit',
        panelHeader: 'Challenge Edit',
        primaryKey: 'idChallenge',
        tabIndex: 0,
        isWebeatChallenge: true,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'idChallengeType.idChallengeType',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'name',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'startDate',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'endDate',
                  yPosition: 3,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'startVisDate',
                  yPosition: 3,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'endVisDate',
                  yPosition: 3,
                  xPosition: 3,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'brand',
                  yPosition: 4,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        ...platformComboFields,
        {
          title: formatMessage({ id: 'challengeType.id' }),
          key: 'idChallengeType.idChallengeType',
          type: 'selector',
          selectorPath: '/challenge/challenge-types',
          unique: false,
          min: 0,
          comboId: 'challenge_type',
          path: 'challenge_type',
          length: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.name' }),
          key: 'name',
          type: 'text',
          unique: false,
          min: 0,
          validationType: 'text',
          length: 250,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.description' }),
          key: 'description',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.descriptionLong' }),
          key: 'descriptionLong',
          type: 'text',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.code' }),
          key: 'code',
          type: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.idCampaign' }),
          key: 'idCampaign',
          type: 'text',
          mandatory: false,
          disabled: true,
        },
        {
          title: formatMessage({ id: 'challenge.start_date' }),
          key: 'startDate',
          validationType: 'text',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.end_date' }),
          key: 'endDate',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.start_vis_date' }),
          key: 'startVisDate',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.end_vis_date' }),
          key: 'endVisDate',
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'status',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'active',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'showList',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
        {
          title: formatMessage({ id: 'challenge.status' }),
          key: 'showPoints',
          type: 'check',
          mandatory: false,
          disabled: true,
          initialValue: 'true',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatChallengeDashboard';

  return (
    <DashboardInitializer
      customDashboard={componentId}
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatChallengeDashboard;
