export enum m2mTypes {
  M2M_SET_INITIAL_STATE = '[M2M] Set initial state',
  M2M_FETCHING_RECORDS = '[M2M] Fething data',
  M2M_FETCHED_RECORDS = '[M2M] Data fetched',
  M2M_CHANGE_SELECTED_RECORDS = '[M2M] Record selection change',
  M2M_SET_SEARCH_VALUES = '[M2M] Products selected',
  M2M_ADD_SELECTED_RECORDS = '[M2M] Records added',
  M2M_DELETE_SELECTED_RECORDS = '[M2M] Records deleted',
  M2M_RESET = '[M2M] Reset m2m component',
  M2M_SET_MODAL_VISIBILITY = '[M2M] Set modal visibility',

  M2M_ADDING_RECORD = '[M2M] Record adding',
  M2M_ADDED_RECORD = '[M2M] Record added',
  M2M_REMOVING_RECORD = '[M2M] Record removing',
  M2M_REMOVED_RECORD = '[M2M] Record removed',
  M2M_SET_ACTIVE_DASHBOARD = '[M2M] Set M2M active Dashboard',
  M2M_GET_SELECTED_ROWS = '[M2M] Set getSelected m2m rows',
  M2M_SET_TABLE_PARAMS = '[M2M] set table sort params'
}
